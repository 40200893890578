import { __assign, __rest } from "tslib";
import $i18n from 'panda-i18n';
import React, { useEffect } from 'react';
import { getRequestService, loadWebOfficeSDK, CnUploadContext, defaultCnUploadRequestConfig, } from '@cainiaofe/cn-ui-common';
import { getErrorMsg } from '@cainiaofe/cn-ui-utils';
import { CnMessage } from "../cn-message";
import { OSSUploadNormal } from './oss-upload-normal';
import { OSSUploadDragger } from './oss-upload-dragger';
import { OSSUploadMini } from './oss-upload-mini';
/**
 * 兼容 uploadProps 属性，未来会被废弃，直接使用props
 * @deprecated
 */
var doMergeProps = function (props) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    var _a = props.uploadProps, uploadProps = _a === void 0 ? {} : _a, restProps = __rest(props, ["uploadProps"]);
    return __assign(__assign({}, uploadProps), restProps);
};
export var OSSUpload = React.forwardRef(function (props, ref) {
    var _a = doMergeProps(props), shape = _a.shape, service = _a.service, requestConfig = _a.requestConfig, onErrorProps = _a.onError, description = _a.description, buttonProps = _a.buttonProps, buttonText = _a.buttonText, showUploadList = _a.showUploadList, webOfficeEnable = _a.webOfficeEnable, webOfficeEditRequestConfig = _a.webOfficeEditRequestConfig, webOfficePreviewRequestConfig = _a.webOfficePreviewRequestConfig, restProps = __rest(_a, ["shape", "service", "requestConfig", "onError", "description", "buttonProps", "buttonText", "showUploadList", "webOfficeEnable", "webOfficeEditRequestConfig", "webOfficePreviewRequestConfig"]);
    useEffect(function () {
        if (webOfficeEnable)
            loadWebOfficeSDK();
    }, [webOfficeEnable]);
    var fetchOSSToken = function (params) {
        var getRequestConfig = function () {
            // 标准逻辑
            if (requestConfig)
                return requestConfig;
            // start 兼容逻辑
            var _service = service;
            if (_service) {
                // 自定义函数
                if (typeof _service === 'function') {
                    return { service: _service };
                }
                // requestConfig 配置
                return _service;
            }
            // end 兼容逻辑
            return defaultCnUploadRequestConfig;
        };
        return getRequestService(getRequestConfig())(params);
    };
    /**
     * 组件内部主动通过 CnMessage.error 暴露错误内容
     */
    var onError = function (file) {
        var error = file.error;
        if (!error)
            return;
        var result = onErrorProps === null || onErrorProps === void 0 ? void 0 : onErrorProps(error, file);
        if (result === false)
            return;
        var msg = getErrorMsg(error, $i18n.get({
            id: 'OSSCredentialAcquisitionFailed',
            dm: 'OSS凭证获取失败',
            ns: 'CnOssUpload',
        }));
        CnMessage.error({ content: msg });
    };
    return (React.createElement(CnUploadContext, __assign({}, restProps, { onError: onError, fetchOSSToken: fetchOSSToken }),
        shape === 'dragger' ? (React.createElement(OSSUploadDragger, { ref: ref, description: description, showUploadList: showUploadList, webOfficeEnable: webOfficeEnable, webOfficeEditRequestConfig: webOfficeEditRequestConfig, webOfficePreviewRequestConfig: webOfficePreviewRequestConfig })) : null,
        shape === 'normal' || shape === 'img' ? (React.createElement(OSSUploadNormal, { ref: ref, description: description, showUploadList: showUploadList, webOfficeEnable: webOfficeEnable, webOfficeEditRequestConfig: webOfficeEditRequestConfig, webOfficePreviewRequestConfig: webOfficePreviewRequestConfig, buttonProps: buttonProps, buttonText: buttonText })) : null,
        shape === 'mini' ? (React.createElement(OSSUploadMini, { ref: ref, description: description, showUploadList: showUploadList, webOfficeEnable: webOfficeEnable, webOfficeEditRequestConfig: webOfficeEditRequestConfig, webOfficePreviewRequestConfig: webOfficePreviewRequestConfig, buttonProps: buttonProps, buttonText: buttonText })) : null));
});
OSSUpload.defaultProps = {
    headers: {},
    shape: 'normal',
    multiple: false,
    readOnly: false,
    useDetailValue: true,
    showPreview: true,
    showRemove: true,
    showDownload: true,
    showUploadList: true,
    openFileDialogOnClick: true,
    webOfficeEnable: false,
    webOfficeEditRequestConfig: {
        url: '/api/tars2/file/upload/generateEditUrl',
    },
    webOfficePreviewRequestConfig: {
        url: '/api/tars2/file/upload/generatePreviewUrl',
    },
};
OSSUpload.displayName = 'CnOSSUpload';
