import { __assign, __rest } from "tslib";
import $i18n from 'panda-i18n';
import './array-table.scss';
import * as React from 'react';
import { CnDialogFullpage, CnFormArrayCard } from '@cainiaofe/cn-ui-m';
import { formilyReact } from '@/form/formily';
import { CnBox } from '@/components/cn-box';
import { CnIcon } from '@/components/cn-icon';
var useField = formilyReact.useField;
export var CnArrayTable = React.forwardRef(function (props, ref) {
    var _a;
    var mobileProps = props.mobileProps, others = __rest(props, ["mobileProps"]);
    var field = useField();
    var title = field === null || field === void 0 ? void 0 : field.title;
    var _b = React.useState(false), visible = _b[0], setVisible = _b[1];
    if (((_a = props.mobileProps) === null || _a === void 0 ? void 0 : _a.type) === 'expand') {
        return (React.createElement(CnFormArrayCard, __assign({ ref: ref, hasDeleteButton: false }, others, mobileProps)));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(CnBox, { className: "cn-ui-m-array-table-entry", direction: "row", justify: "space-between", onClick: function () { return setVisible(true); } },
            React.createElement("div", null, $i18n.get({ id: 'TERM.Details', dm: '详情' })),
            React.createElement(CnIcon, { type: "arrow-right" })),
        React.createElement(CnDialogFullpage, { title: title, visible: visible, footer: false, onClose: function () { return setVisible(false); }, noCard: true },
            React.createElement(CnFormArrayCard, __assign({ ref: ref, hasDeleteButton: false }, others, mobileProps)))));
});
CnArrayTable.displayName = 'CnArrayTable';
