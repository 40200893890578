import { __assign } from "tslib";
import { componentMap } from '@cainiaofe/cn-ui-m';
import { withMobileProps } from '@/utils/with-mobile-props';
var ICnCard = componentMap.CnCard;
/**
 * 表单内卡片，转换到移动端映射规则如下
 * 1、卡片-默认转换为移动端primary卡片
 * 2、子卡片-默认转换为移动端simple子卡片
 * 3、三级卡片-同子卡片
 */
export var CnCard = withMobileProps(function (props) {
    return (React.createElement(ICnCard, __assign({ shape: "primary", contentGap: true, noTitleBorder: (props === null || props === void 0 ? void 0 : props.shape) === 'simple' }, props)));
});
export var CnCardSubCard = withMobileProps(function (props) {
    return (React.createElement(ICnCard, __assign({ shape: "simple", noTitleBorder: true, noPadding: true }, props)));
});
export var CnCardSubAreaCard = withMobileProps(function (props) {
    return (React.createElement(ICnCard, __assign({ shape: "simple", noTitleBorder: true, noPadding: true }, props)));
});
