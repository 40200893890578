import { __assign } from "tslib";
import * as React from 'react';
import { Animate as NextAnimate } from "../../../_fusion/lib";
export var CnAnimate = function (props) {
    return React.createElement(NextAnimate, __assign({ "data-name": "CnAnimate" }, props));
};
CnAnimate.displayName = 'CnAnimate';
/**
 * @deprecated  请使用 CnAnimate 替换
 */
export var Animate = NextAnimate;
