import React from 'react';
import { CnBox, CnEllipsis, CnIcon } from '@cainiaofe/cn-ui-m';
export var ShellHeader = function (props) {
    var isHidden = props.isHidden, setSideVisible = props.setSideVisible, title = props.title, searchSlot = props.searchSlot;
    if (isHidden)
        return null;
    return (React.createElement(CnBox, { direction: "row", justify: "space-between", align: "center", className: "cn-ui-m-shell-header" },
        React.createElement("div", { onClick: function () { return setSideVisible(true); }, className: "cn-ui-m-shell-menu-icon" },
            React.createElement(CnIcon, { size: "large", type: "icon-collapse-right" })),
        React.createElement(CnEllipsis, { className: "cn-ui-m-shell-title" }, title),
        searchSlot ? (React.createElement("div", { className: "cn-ui-m-shell-search-slot" }, searchSlot)) : null));
};
