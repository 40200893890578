import { __assign } from "tslib";
import * as React from 'react';
import $i18n from 'panda-i18n';
import { CnRadio } from "../../../../cn-radio";
import { safeGetRowKey, always, isFunction } from '../../utils';
import { rowSelectionSymbol } from '../../global';
function getFirstDefinedValue() {
    var values = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        values[_i] = arguments[_i];
    }
    for (var i = 0; i < values.length; i++) {
        var v = values[i];
        if (v !== undefined) {
            return v;
        }
    }
}
export function singleSelectPipeline(opts) {
    if (opts === void 0) { opts = {}; }
    return function singleSelectStep(pipeline) {
        var _a, _b;
        if (CnRadio == null) {
            throw new Error($i18n.get({
                id: 'BeforeUsingSingleSelectComponentsRadioNeedTo_2051382980',
                dm: '使用 singleSelect 之前需要通过 pipeline context 设置 components.Radio',
                ns: 'CnBaseTable',
            }));
        }
        var stateKey = 'singleSelect';
        var clickArea = (_a = opts.clickArea) !== null && _a !== void 0 ? _a : 'radio';
        var isDisabled = isFunction(opts.isDisabled)
            ? opts.isDisabled
            : always(false);
        var primaryKey = pipeline.ensurePrimaryKey('singleSelect');
        var value = getFirstDefinedValue(Array.isArray(opts.value) ? opts.value[0] : opts.value, pipeline.getStateAtKey(stateKey), Array.isArray(opts.defaultValue) ? opts.defaultValue[0] : opts.defaultValue);
        var onChange = function (rowKey, records) {
            var _a;
            (_a = opts.onChange) === null || _a === void 0 ? void 0 : _a.call(opts, rowKey, records);
            pipeline.setStateAtKey(stateKey, rowKey);
        };
        var radioColumn = __assign(__assign({ name: '', width: 50, align: 'center', lock: true, attach: false, sizeFixed: true, symbol: rowSelectionSymbol }, opts.columnProps), { getCellProps: function (cellValue, row, rowIndex) {
                if (clickArea === 'cell') {
                    var rowKey_1 = safeGetRowKey(primaryKey, row, rowIndex);
                    var disabled = isDisabled(row, rowIndex);
                    var cellProps = {
                        style: { cursor: disabled ? 'not-allowed' : 'pointer' },
                    };
                    if (!disabled) {
                        cellProps.onClick = function (e) {
                            if (opts.stopClickEventPropagation) {
                                e.stopPropagation();
                            }
                            onChange(rowKey_1, row);
                        };
                    }
                    return cellProps;
                }
                return {};
            }, render: function (_, row, rowIndex) {
                var rowKey = safeGetRowKey(primaryKey, row, rowIndex);
                return (React.createElement(CnRadio, { checked: value === rowKey, disabled: isDisabled(row, rowIndex), onChange: clickArea === 'radio'
                        ? function (arg1, arg2) {
                            var _a;
                            var nativeEvent = 
                            // @ts-ignore
                            (_a = arg2 === null || arg2 === void 0 ? void 0 : arg2.nativeEvent) !== null && _a !== void 0 ? _a : arg1 === null || arg1 === void 0 ? void 0 : arg1.nativeEvent;
                            if (nativeEvent && opts.stopClickEventPropagation) {
                                nativeEvent.stopPropagation();
                            }
                            onChange(rowKey, row);
                        }
                        : undefined }));
            } });
        var nextColumns = pipeline.getColumns().slice();
        var placement = (_b = opts.placement) !== null && _b !== void 0 ? _b : 'start';
        if (placement === 'start') {
            nextColumns.unshift(radioColumn);
        }
        else {
            nextColumns.push(radioColumn);
        }
        pipeline.columns(nextColumns);
        pipeline.appendRowPropsGetter(function (row, rowIndex) {
            var rowKey = safeGetRowKey(primaryKey, row, rowIndex);
            var style = {};
            var className = '';
            var onClick;
            if (opts.highlightRowWhenSelected) {
                if (value === rowKey) {
                    className = 'highlight';
                }
            }
            if (clickArea === 'row' && !isDisabled(row, rowIndex)) {
                style.cursor = 'pointer';
                onClick = function (e) {
                    if (opts.stopClickEventPropagation) {
                        e.stopPropagation();
                    }
                    onChange(rowKey);
                };
            }
            return { className: className, style: style, onClick: onClick };
        });
        return pipeline;
    };
}
