import { __assign } from "tslib";
import React from 'react';
import { CnTable as PCCnTable } from '@cainiaofe/cn-ui/es/components/cn-table';
import { CardItems } from './card-items';
export var CnTable = React.forwardRef(function (props, ref) {
    var _a, _b, _c, _d;
    var mobileProps = props.mobileProps;
    if (((_a = props.mobileProps) === null || _a === void 0 ? void 0 : _a.type) === 'CnTable') {
        return React.createElement(PCCnTable, __assign({}, props, mobileProps));
    }
    return (React.createElement(CardItems, __assign({ ref: ref, entryTitle: (_b = props.mobileProps) === null || _b === void 0 ? void 0 : _b.entryTitle, labelAlign: (_c = props.mobileProps) === null || _c === void 0 ? void 0 : _c.labelAlign, detailLabelAlign: (_d = props.mobileProps) === null || _d === void 0 ? void 0 : _d.detailLabelAlign }, props)));
});
CnTable.displayName = 'CnTable';
// @ts-ignore .
CnTable.useRemote = CardItems.useRemote;
// @ts-ignore .
CnTable.createRemoteInstance = CardItems.createRemoteInstance;
