import { __assign } from "tslib";
import React, { Fragment, useContext } from 'react';
import { ShellStateContext } from './context';
import cx from 'classnames';
import { Side } from './side';
import { ShellHeader } from './header';
export var Shell = function () {
    var state = useContext(ShellStateContext);
    var shellChildren = state.shellChildren, isHidden = state.isHidden, className = state.className, style = state.style;
    if (isHidden) {
        return React.createElement(Fragment, null, shellChildren);
    }
    // @ts-ignore 组件内部使用
    window.__CNUI_shellHeader = React.createElement(ShellHeader, __assign({}, state));
    return (React.createElement("div", { className: cx('cn-ui-m-shell', className), style: style },
        React.createElement("div", null, shellChildren),
        React.createElement(Side, null)));
};
