import { __assign, __extends, __rest, __spreadArray } from "tslib";
import React from 'react';
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';
import { polyfill } from 'react-lifecycles-compat';
import classnames from 'classnames';
import Icon from "../../../../_fusion/lib/icon";
import Menu from "../../../../_fusion/lib/menu";
import Overlay from "../../../../_fusion/lib/overlay";
import ConfigProvider from "../../../../_fusion/lib/config-provider";
import { obj, func } from "../../../../_fusion/lib/util";
import { CnButton } from "../../cn-button";
import $i18n from 'panda-i18n';
var Popup = Overlay.Popup;
/**
 * MenuButton
 */
var MenuButton = /** @class */ (function (_super) {
    __extends(MenuButton, _super);
    function MenuButton(props, context) {
        var _this = _super.call(this, props, context) || this;
        _this.clickMenuItem = function (key) {
            var _a;
            var others = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                others[_i - 1] = arguments[_i];
            }
            var selectMode = _this.props.selectMode;
            (_a = _this.props).onItemClick.apply(_a, __spreadArray([key], others, false));
            if (selectMode === 'multiple') {
                return;
            }
            _this.onPopupVisibleChange(false, 'menuSelect');
        };
        _this.selectMenu = function (keys) {
            var _a;
            var others = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                others[_i - 1] = arguments[_i];
            }
            if (!('selectedKeys' in _this.props)) {
                _this.setState({
                    selectedKeys: keys,
                });
            }
            (_a = _this.props).onSelect.apply(_a, __spreadArray([keys], others, false));
        };
        _this.onPopupOpen = function () {
            // eslint-disable-next-line react/no-find-dom-node
            var button = findDOMNode(_this);
            if (button && _this.menu) {
                if (_this.menu.offsetWidth < button.offsetWidth) {
                    _this.menu.style.width = "".concat(button.offsetWidth, "px");
                }
            }
        };
        _this.onPopupVisibleChange = function (visible, type) {
            if (!('visible' in _this.props)) {
                _this.setState({
                    visible: visible,
                });
            }
            _this.props.onVisibleChange(visible, type);
        };
        _this._menuRefHandler = function (ref) {
            // eslint-disable-next-line react/no-find-dom-node
            _this.menu = findDOMNode(ref);
            var refFn = _this.props.menuProps.ref;
            if (typeof refFn === 'function') {
                refFn(ref);
            }
        };
        _this.state = {
            selectedKeys: props.defaultSelectedKeys,
            visible: props.defaultVisible,
        };
        return _this;
    }
    MenuButton.getDerivedStateFromProps = function (props) {
        var st = {};
        if ('visible' in props) {
            st.visible = props.visible;
        }
        if ('selectedKeys' in props) {
            st.selectedKeys = props.selectedKeys;
        }
        return st;
    };
    MenuButton.prototype.render = function () {
        var _a, _b;
        var _c;
        var _d = this.props, prefix = _d.prefix, style = _d.style, className = _d.className, label = _d.label, popupTriggerType = _d.popupTriggerType, popupContainer = _d.popupContainer, popupStyle = _d.popupStyle, popupClassName = _d.popupClassName, popupProps = _d.popupProps, followTrigger = _d.followTrigger, selectMode = _d.selectMode, menuProps = _d.menuProps, children = _d.children, others = __rest(_d, ["prefix", "style", "className", "label", "popupTriggerType", "popupContainer", "popupStyle", "popupClassName", "popupProps", "followTrigger", "selectMode", "menuProps", "children"]);
        var state = this.state;
        var classNames = classnames((_a = {},
            _a["".concat(prefix, "menu-btn")] = true,
            _a["".concat(prefix, "expand")] = state.visible,
            _a.opened = state.visible,
            _a), className);
        var popupClassNames = classnames((_b = {},
            _b["".concat(prefix, "menu-btn-popup")] = true,
            _b), popupClassName);
        var trigger = (React.createElement(CnButton, __assign({ style: style, className: classNames }, obj.pickOthers(MenuButton.propTypes, others)),
            label,
            " ",
            React.createElement(Icon, { type: "arrow-down", className: "".concat(prefix, "menu-btn-arrow") })));
        return (React.createElement(Popup, __assign({}, popupProps, { followTrigger: followTrigger, visible: state.visible, onVisibleChange: this.onPopupVisibleChange, trigger: trigger, triggerType: popupTriggerType, container: popupContainer, onOpen: this.onPopupOpen, style: popupStyle, className: popupClassNames, rtl: (_c = $i18n.isRTL) === null || _c === void 0 ? void 0 : _c.call($i18n) }),
            React.createElement("div", { className: "".concat(prefix, "menu-btn-spacing-tb") },
                React.createElement(Menu, __assign({}, menuProps, { ref: this._menuRefHandler, selectedKeys: state.selectedKeys, selectMode: selectMode, onSelect: this.selectMenu, onItemClick: this.clickMenuItem }), children))));
    };
    MenuButton.propTypes = {
        prefix: PropTypes.string,
        /**
         * 按钮上的文本内容
         */
        label: PropTypes.node,
        /**
         * 弹层是否与按钮宽度相同
         */
        autoWidth: PropTypes.bool,
        /**
         * 弹层触发方式
         */
        popupTriggerType: PropTypes.oneOf(['click', 'hover']),
        /**
         * 弹层容器
         */
        popupContainer: PropTypes.any,
        /**
         * 弹层展开状态
         */
        visible: PropTypes.bool,
        /**
         * 弹层默认是否展开
         */
        defaultVisible: PropTypes.bool,
        /**
         * 弹层在显示和隐藏触发的事件
         */
        onVisibleChange: PropTypes.func,
        /**
         * 弹层自定义样式
         */
        popupStyle: PropTypes.object,
        /**
         * 弹层自定义样式类
         */
        popupClassName: PropTypes.string,
        /**
         * 弹层属性透传
         */
        popupProps: PropTypes.object,
        /**
         * 是否跟随滚动
         */
        followTrigger: PropTypes.bool,
        /**
         * 默认激活的菜单项（用法同 Menu 非受控）
         */
        defaultSelectedKeys: PropTypes.array,
        /**
         * 激活的菜单项（用法同 Menu 受控）
         */
        selectedKeys: PropTypes.array,
        /**
         * 菜单的选择模式，同 Menu
         */
        selectMode: PropTypes.oneOf(['single', 'multiple']),
        /**
         * 点击菜单项后的回调，同 Menu
         */
        onItemClick: PropTypes.func,
        /**
         * 选择菜单后的回调，同 Menu
         */
        onSelect: PropTypes.func,
        /**
         * 菜单属性透传
         */
        menuProps: PropTypes.object,
        style: PropTypes.object,
        className: PropTypes.string,
        children: PropTypes.any,
    };
    MenuButton.defaultProps = {
        prefix: 'cn-next-',
        autoWidth: true,
        popupTriggerType: 'click',
        onVisibleChange: func.noop,
        onItemClick: func.noop,
        onSelect: func.noop,
        defaultSelectedKeys: [],
        menuProps: {},
    };
    return MenuButton;
}(React.Component));
MenuButton.Item = Menu.Item;
MenuButton.Group = Menu.Group;
MenuButton.Divider = Menu.Divider;
export default ConfigProvider.config(polyfill(MenuButton), {
    componentName: 'MenuButton',
});
