import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import useUpdateEffect from 'ahooks/lib/useUpdateEffect';
import { UploadList } from '../upload-list';
import { useOnRemove } from '../upload-core/hooks/use-on-remove';
import { UploadCore } from '../upload-core';
import { UploadButton } from '../upload-button';
import { CnBox } from "../../../cn-box";
export var CnUploadView = React.forwardRef(function (props, ref) {
    var style = props.style, className = props.className, onPreview = props.onPreview, onDownload = props.onDownload, _onRemove = props.onRemove, showRemove = props.showRemove, showPreview = props.showPreview, showDownload = props.showDownload, formatter = props.formatter, directory = props.directory, method = props.method, headers = props.headers, beforeUpload = props.beforeUpload, customRequest = props.customRequest, withCredentials = props.withCredentials, openFileDialogOnClick = props.openFileDialogOnClick, buttonText = props.buttonText, readOnly = props.readOnly, onError = props.onError, description = props.description, buttonProps = props.buttonProps, name = props.name, _a = props.multiple, multiple = _a === void 0 ? false : _a, action = props.action, data = props.data, accept = props.accept, showUploadList = props.showUploadList, disabled = props.disabled, limit = props.limit, originValue = props.value, defaultValue = props.defaultValue, onChange = props.onChange, webOfficeEnable = props.webOfficeEnable, webOfficeEditRequestConfig = props.webOfficeEditRequestConfig, webOfficePreviewRequestConfig = props.webOfficePreviewRequestConfig;
    var uploadRef = useRef();
    var onChangeRef = useRef(onChange);
    var onRemoveRef = useRef(_onRemove);
    var _b = useState(function () {
        return originValue || defaultValue || [];
    }), value = _b[0], setValue = _b[1];
    useUpdateEffect(function () {
        onChangeRef.current = onChange;
        onRemoveRef.current = _onRemove;
    }, [onChange, _onRemove]);
    useUpdateEffect(function () {
        setValue(originValue || []);
    }, [originValue]);
    var onRemove = useOnRemove({
        setValue: setValue,
        uploadRef: uploadRef,
        onChangeRef: onChangeRef,
        onRemoveRef: onRemoveRef,
    });
    var isDisabledUpload = disabled || value.length >= limit;
    return (React.createElement("div", { "data-name": "CnUpload", style: style, className: classNames(className, 'cn-ui-upload', {
            'cn-ui-upload-readonly': readOnly,
            'cn-ui-upload-disabled': isDisabledUpload,
        }) },
        React.createElement(CnBox, { direction: "row", align: "center", className: "cn-ui-upload-core" },
            !readOnly && (React.createElement(UploadCore, { ref: ref, formatter: formatter, directory: directory, method: method, headers: headers, beforeUpload: beforeUpload, customRequest: customRequest, withCredentials: withCredentials, openFileDialogOnClick: openFileDialogOnClick, onError: onError, name: name, multiple: multiple, action: action, data: data, accept: accept, disabled: isDisabledUpload, onChange: onChange, setValue: setValue },
                React.createElement(UploadButton, { buttonProps: buttonProps, disabled: isDisabledUpload, buttonText: buttonText }))),
            React.createElement("div", { className: "cn-ui-upload-description" }, description)),
        showUploadList ? (React.createElement(UploadList, { readOnly: readOnly, value: value, limit: limit, onPreview: onPreview, onDownload: onDownload, onRemove: onRemove, showRemove: !disabled && showRemove, showPreview: showPreview, showDownload: showDownload, webOfficeEnable: webOfficeEnable, webOfficeEditRequestConfig: webOfficeEditRequestConfig, webOfficePreviewRequestConfig: webOfficePreviewRequestConfig })) : null));
});
CnUploadView.defaultProps = {
    limit: 999,
    readOnly: false,
    showRemove: true,
    showPreview: true,
    showDownload: true,
    showUploadList: true,
};
CnUploadView.displayName = 'CnUploadView';
