import { __assign, __rest } from "tslib";
import * as React from 'react';
import { CnGrid as PCCnGrid } from '@cainiaofe/cn-ui/es/components/cn-grid';
export var CnRow = function (props) {
    var mobileProps = props.mobileProps, restProps = __rest(props, ["mobileProps"]);
    if (mobileProps === null || mobileProps === void 0 ? void 0 : mobileProps.pcMode)
        return React.createElement(PCCnGrid.CnRow, __assign({}, restProps));
    return React.createElement("div", null, restProps.children);
};
export var CnCol = function (props) {
    var mobileProps = props.mobileProps, restProps = __rest(props, ["mobileProps"]);
    if (mobileProps === null || mobileProps === void 0 ? void 0 : mobileProps.pcMode)
        return React.createElement(PCCnGrid.CnCol, __assign({}, restProps));
    return React.createElement("div", { className: restProps === null || restProps === void 0 ? void 0 : restProps.className }, restProps.children);
};
export var CnGrid = {
    CnRow: CnRow,
    CnCol: CnCol,
};
export var Grid = {
    Row: CnRow,
    Col: CnCol,
};
