import { __assign } from "tslib";
import * as React from 'react';
import isEmpty from 'lodash/isEmpty';
import isPlainObject from 'lodash/isPlainObject';
import { safeCallFunction } from "../../../../cn-utils";
import { InlineFlexCell } from '../../dep';
import { FilterUI } from './filter-ui';
var selectKeysName = 'filterSelectKeys';
export function columnsFilterPipeline(opt) {
    return function (pipeline) {
        if (!opt) {
            return pipeline;
        }
        var _a = isPlainObject(opt)
            ? opt
            : { labelKey: undefined, popContainerStyle: {} }, labelKey = _a.labelKey, popContainerStyle = _a.popContainerStyle, _b = _a.iconSize, iconSize = _b === void 0 ? 16 : _b, onFilter = _a.onFilter;
        var columns = pipeline.getColumns();
        var dataSource = pipeline.getDataSource();
        /** 检测逻辑 start  */
        // 检测是否存在 primaryKey
        var primaryKey = pipeline.ensurePrimaryKey('filter');
        if (!primaryKey) {
            return pipeline;
        }
        /** 检测逻辑 end  */
        /** 执行逻辑 start */
        var keysMap = pipeline.getStateAtKey(selectKeysName);
        function getFilterDataSource(filterDataSource, filterKeysMap) {
            if (Object.keys(filterKeysMap).length === 0) {
                return filterDataSource;
            }
            // keysMap;
            return Object.keys(filterKeysMap).reduce(function (pre, cur) {
                var _a;
                if (((_a = filterKeysMap[cur]) === null || _a === void 0 ? void 0 : _a.length) === 0)
                    return pre;
                return pre.filter(function (item) {
                    var _a, _b, _c;
                    var ItemValue = isPlainObject(item[cur])
                        ? JSON.stringify(item[cur])
                        : item[cur];
                    var keysMapHasEmpty = ((_b = (_a = filterKeysMap[cur]) === null || _a === void 0 ? void 0 : _a.filter(function (mapItem) { return !mapItem; })) === null || _b === void 0 ? void 0 : _b.length) !== 0;
                    if (isEmpty(item[cur]) && keysMapHasEmpty) {
                        return true;
                    }
                    return (_c = filterKeysMap[cur]) === null || _c === void 0 ? void 0 : _c.includes(ItemValue);
                });
            }, filterDataSource);
        }
        var filterDataSource = getFilterDataSource(dataSource, keysMap || {});
        var setColKeys = function (code, val) {
            var ret = keysMap !== null && keysMap !== void 0 ? keysMap : {};
            ret[code] = val !== null && val !== void 0 ? val : [];
            pipeline.setStateAtKey(selectKeysName, __assign({}, ret));
        };
        columns.map(function (col) {
            var _a, _b, _c, _d, _e;
            // 通过在列头设置 filter 开启筛选
            // 暂不支持多层表头的 filter，不考虑嵌套的filter功能， 嵌套filter场景应用较少，后期有业务线需要使用再考虑
            // 如需要支持多层表头的 Filter，后期建议单独 pipeline 进行开发
            if ((col === null || col === void 0 ? void 0 : col.filter) && !('children' in col)) {
                var sourceTitle = (col === null || col === void 0 ? void 0 : col.title) || (col === null || col === void 0 ? void 0 : col.name);
                col.title = (React.createElement(InlineFlexCell, null,
                    sourceTitle,
                    React.createElement(FilterUI, { dataSource: dataSource, iconSize: iconSize, code: col.code, labelKey: (_c = (_b = (_a = col.filter) === null || _a === void 0 ? void 0 : _a.labelKey) !== null && _b !== void 0 ? _b : labelKey) !== null && _c !== void 0 ? _c : col.code, columns: col, popContainerStyle: (_e = (_d = col.filter) === null || _d === void 0 ? void 0 : _d.popContainerStyle) !== null && _e !== void 0 ? _e : popContainerStyle, keysMap: keysMap, valueKey: col.code, 
                        // valueKey={primaryKey}
                        clear: function () {
                            setColKeys(col.code, []);
                        }, onChange: function (keys) {
                            setColKeys(col.code, keys);
                        } })));
                if (col.width > 0) {
                    col.width += iconSize !== null && iconSize !== void 0 ? iconSize : 16;
                }
            }
            return col;
        });
        safeCallFunction(onFilter, keysMap, filterDataSource);
        /** 执行逻辑 end */
        // 修改 pipeline 中的 columns
        pipeline.columns(columns);
        // 修改 pipeline 中的 dataSource
        pipeline.dataSource(filterDataSource);
        return pipeline;
    };
}
