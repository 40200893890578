// 第三方依赖
import * as React from 'react';
import isFunction from 'lodash/isFunction';
// 其他组件
import { Dropdown } from "../../../cn-base-table/view/dep/dropdown";
import { copyText } from "../../../cn-base-table/view/pipelines/col-attach-pipeline";
export function expandCell(props) {
    var _a = props || {}, cellOverflowEllipsis = _a.cellOverflowEllipsis, cellProps = _a.cellProps, cellCopyEnable = _a.cellCopyEnable;
    if (cellOverflowEllipsis || cellProps) {
        return function (rowIndex, colIndex, key, rowData) {
            var sourceCellProps = isFunction(cellProps)
                ? // @ts-ignore
                    cellProps(rowIndex, colIndex, key, rowData)
                : {};
            var tipContent = isFunction(cellOverflowEllipsis)
                ? cellOverflowEllipsis(rowIndex, colIndex, key, rowData)
                : // @ts-ignore
                    rowData === null || rowData === void 0 ? void 0 : rowData[key];
            var cellOverflowEllipsisProps = cellOverflowEllipsis
                ? {
                    onMouseEnter: function (event) {
                        var _a, _b;
                        // @ts-ignore
                        isFunction(sourceCellProps === null || sourceCellProps === void 0 ? void 0 : sourceCellProps.onMouseEnter) &&
                            (
                            // @ts-ignore
                            sourceCellProps === null || sourceCellProps === void 0 ? void 0 : sourceCellProps.onMouseEnter(event));
                        // @ts-ignore
                        var width = (isFunction((_a = event === null || event === void 0 ? void 0 : event.target) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect)
                            ? // @ts-ignore
                                (_b = event === null || event === void 0 ? void 0 : event.target) === null || _b === void 0 ? void 0 : _b.getBoundingClientRect()
                            : {}).width;
                        tipContent &&
                            Dropdown.show(event, React.createElement("div", null, tipContent), {
                                hasMask: false,
                                offset: [0, (width !== null && width !== void 0 ? width : 0) / 2],
                                zIndex: 2000,
                                transform: function (_a) {
                                    var _b = _a.isTopHalf, isTopHalf = _b === void 0 ? true : _b;
                                    return "translate(-50%, ".concat(isTopHalf ? 0 : '-100', "%");
                                },
                            });
                    },
                    onMouseLeave: function (event) {
                        // @ts-ignore
                        isFunction(sourceCellProps === null || sourceCellProps === void 0 ? void 0 : sourceCellProps.onMouseLeave) &&
                            (
                            // @ts-ignore
                            sourceCellProps === null || sourceCellProps === void 0 ? void 0 : sourceCellProps.onMouseLeave(event));
                        Dropdown.hide();
                    },
                    className: 'cn-table-cell-ellipsis',
                }
                : {};
            var copyProps = cellCopyEnable
                ? {
                    onDoubleClick: function (event) {
                        var _a;
                        // @ts-ignore
                        isFunction(sourceCellProps === null || sourceCellProps === void 0 ? void 0 : sourceCellProps.onDoubleClick) &&
                            (
                            // @ts-ignore
                            sourceCellProps === null || sourceCellProps === void 0 ? void 0 : sourceCellProps.onDoubleClick(event));
                        // @ts-ignore
                        copyText((_a = event === null || event === void 0 ? void 0 : event.target) === null || _a === void 0 ? void 0 : _a.innerText, undefined, undefined);
                    },
                }
                : {};
            return Object.assign({}, cellOverflowEllipsisProps, sourceCellProps, copyProps);
        };
    }
}
