import { __assign } from "tslib";
import { useCnRequest } from "../../cn-utils/old-request";
var formatDataSource = function (list, disabled) {
    if (disabled === void 0) { disabled = false; }
    if (!(list === null || list === void 0 ? void 0 : list.length))
        return [];
    return list.map(function (item) {
        if (typeof item === 'object') {
            return __assign(__assign({}, item), { disabled: disabled || item.disabled });
        }
        return { label: item, value: item, disabled: disabled };
    });
};
export var useDataSource = function (props) {
    var _a = props.requestConfig, requestConfig = _a === void 0 ? {} : _a, disabled = props.disabled;
    // 判断是否通过接口获取 dataSource
    var isRemoteDataSource = !props.dataSource && (!!(requestConfig.url || requestConfig.service));
    if (!requestConfig.resultFormatter && !requestConfig.formatResult) {
        requestConfig.formatResult = function (res) {
            var _a;
            if (Array.isArray(res)) {
                return res;
            }
            if (Array.isArray(res === null || res === void 0 ? void 0 : res.data)) {
                return res.data;
            }
            // 存量逻辑，无法预知风险，不要贸然删除(是否有存量，也有待确认)
            if (Array.isArray((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.dataSource)) {
                return res.data.dataSource;
            }
            return [];
        };
    }
    var _b = useCnRequest(__assign({ ready: isRemoteDataSource }, requestConfig)).data, data = _b === void 0 ? [] : _b;
    if (props.dataSource) {
        return formatDataSource(props.dataSource, disabled);
    }
    if (isRemoteDataSource) {
        return formatDataSource(data, disabled);
    }
    return [];
};
