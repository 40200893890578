import { withI18n } from 'panda-i18n';
import locale from "../../locales";
import { CnCheckbox as CnCheckboxView } from './view/cn-checkbox';
import { CnCheckboxGroup as CnCheckboxGroupView } from './view/cn-checkbox-group';
export var CnCheckbox = withI18n(CnCheckboxView, {
    locale: locale,
    componentName: 'CnCheckbox',
    noMemo: true,
});
export var CnCheckboxGroup = withI18n(CnCheckboxGroupView, {
    locale: locale,
    componentName: 'CnCheckbox',
    forwardRef: false,
});
