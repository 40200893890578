import { __assign, __rest } from "tslib";
import React from 'react';
/**
 * 最终会将 mobileProps 提升为组件 Props 并覆盖辅端组件的 Props
 * @example
 *  <CnButton type='normal' mobileProps={{ type: 'primary' }} />
 *  PC 渲染为 <CnButton type='normal' />
 *  H5 渲染为 <CnButton type='primary' />
 * @param Component 是实际的 CNUI 组件， 如 CnButton
 */
export function withMobileProps(Component) {
    var NewComponent = React.forwardRef(function (props, ref) {
        var mobileProps = props.mobileProps, others = __rest(props, ["mobileProps"]);
        return React.createElement(Component, __assign({ ref: ref }, others, mobileProps));
    });
    NewComponent.displayName = Component.displayName;
    return NewComponent;
}
