import { __assign } from "tslib";
import * as React from 'react';
import NextAffix from "../../../_fusion/lib/affix";
export var CnAffix = function (props) {
    return React.createElement(NextAffix, __assign({ "data-name": "CnAffix" }, props));
};
CnAffix.displayName = 'CnAffix';
/**
 * @deprecated 请使用 CnAffix 替换
 */
export var Affix = NextAffix;
