import * as React from 'react';
import { formilyReact } from "../../../form/formily";
import isFunction from 'lodash/isFunction';
// eslint-disable-next-line import/no-cycle
import getDefaultFormat from "../../cn-table/view/config/cn-table-cell-format";
import { safeCallFunction } from "../../cn-utils";
import { CnFormat } from "../../cn-format";
import { useLocalization } from '@cainiaofe/cn-ui-common';
export default function TableCellRender(tableCellProps) {
    var _a, _b;
    var _c = tableCellProps || {}, _d = _c.format, format = _d === void 0 ? 'text' : _d, tableCellItem = _c.tableCellItem, column = _c.column;
    var localeContext = useLocalization();
    var CnTableCellFormat = getDefaultFormat(localeContext);
    if (CnTableCellFormat[format]) {
        var cellFormat = CnTableCellFormat[format];
        if (isFunction(cellFormat)) {
            cellFormat = cellFormat(column);
        }
        if (!(cellFormat === null || cellFormat === void 0 ? void 0 : cellFormat.cell) || !tableCellItem)
            return null;
        if (typeof tableCellItem !== 'string' && tableCellItem.link) {
            tableCellItem.link =
                (_a = safeCallFunction(tableCellItem.link, formilyReact.useField())) !== null && _a !== void 0 ? _a : tableCellItem.link;
        }
        return (React.createElement("div", { style: {
                textAlign: (_b = column === null || column === void 0 ? void 0 : column.align) !== null && _b !== void 0 ? _b : cellFormat === null || cellFormat === void 0 ? void 0 : cellFormat.align,
            } }, cellFormat.cell(tableCellItem)));
    }
    var isCnFormatSupport = CnFormat.getAllSupportFormat().includes(format.toLowerCase());
    if (isCnFormatSupport) {
        return (React.createElement("div", { style: {
                textAlign: column === null || column === void 0 ? void 0 : column.align,
            } },
            React.createElement(CnFormat, { format: format, value: tableCellItem })));
    }
    return null;
}
