import { __assign, __spreadArray } from "tslib";
import $i18n from 'panda-i18n'; /* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as React from 'react';
import { CnIcon } from "../../../../cn-icon";
import { rowDetailSymbol, isSysFixedCol } from '../../global';
import { safeCallFunction } from "../../../../cn-utils/helpers";
import { isFunction, always, flatMap, collectNodes, safeRender, isDef, } from '../../utils';
import { InlineFlexCell } from '../../dep';
var getRowDetailsCols = function (columns, colOpAttr) {
    if (columns.length === 0) {
        return columns;
    }
    var getSpanRect = colOpAttr.getSpanRect, judgeRender = colOpAttr.judgeRender, getCellProps = colOpAttr.getCellProps, multiFirstRender = colOpAttr.multiFirstRender;
    var fixedCols = [];
    var othersCols = [];
    columns.forEach(function (col) {
        (isSysFixedCol(col.symbol) ? fixedCols : othersCols).push(col);
    });
    var controlCol = {
        name: '',
        code: null,
        symbol: rowDetailSymbol,
        width: 45,
        sizeFixed: true,
    };
    if (fixedCols.length === 0 && othersCols.length) {
        return __spreadArray([
            __assign(__assign({}, controlCol), { getSpanRect: getSpanRect, getCellProps: getCellProps, render: function (value, row, rowIndex) {
                    return judgeRender(controlCol, row, rowIndex);
                } })
        ], othersCols, true);
    }
    var firstCol = fixedCols[0], otherFixedCols = fixedCols.slice(1);
    return __spreadArray(__spreadArray(__spreadArray([
        __assign(__assign({}, (firstCol || {})), { getSpanRect: getSpanRect, getCellProps: getCellProps, render: function (value, row, rowIndex) {
                return multiFirstRender(firstCol, row, rowIndex);
            } })
    ], otherFixedCols, true), [
        __assign(__assign({}, controlCol), { render: function (value, row, rowIndex) {
                return judgeRender(controlCol, row, rowIndex);
            } })
    ], false), othersCols, true);
};
// @ts-ignore
var openStatus;
(function (openStatus) {
    openStatus["expand"] = "expand";
    openStatus["collapse"] = "collapse";
})(openStatus || (openStatus = {}));
var storePrimaryKeySymbol = Symbol('storePrimaryKeySymbol');
var sourceRowIndex = Symbol('sorceRowIndex');
export function rowDetailPipeline(opts) {
    return function rowDetailStep(pipeline) {
        var _a, _b, _c;
        var stateKey = 'rowDetail';
        var primaryKey = pipeline.ensurePrimaryKey('rowDetail');
        if (typeof primaryKey !== 'string') {
            throw new Error($i18n.get({
                id: 'RowDetailOnlySupportsStringsAsPrimaryKey_1306206224',
                dm: 'rowDetail 仅支持字符串作为 primaryKey',
                ns: 'CnBaseTable',
            }));
        }
        var rowDetailMetaKey = (_a = opts.rowDetailMetaKey) !== null && _a !== void 0 ? _a : rowDetailSymbol;
        var indents = pipeline.ctx.indents;
        var textOffset = indents.iconIndent + indents.iconWidth + indents.iconGap;
        var getDetailKey = (_b = opts.getDetailKey) !== null && _b !== void 0 ? _b : (function (row) { return "".concat(row[primaryKey], "_detail"); });
        var renderDetail = function (row) {
            var _a;
            var sourceRenderDetails = (_a = opts.renderDetail) !== null && _a !== void 0 ? _a : fallbackRenderDetail;
            var renderRow = Object.assign({}, row);
            // @ts-ignore
            renderRow[primaryKey] = renderRow[storePrimaryKeySymbol];
            // @ts-ignore
            delete renderRow[storePrimaryKeySymbol];
            return sourceRenderDetails(renderRow, row[sourceRowIndex]);
        };
        var hasDetail = (_c = opts.hasDetail) !== null && _c !== void 0 ? _c : always(true); // 判断
        var openKeys = opts.openKeys;
        if (!openKeys) {
            openKeys = pipeline.getStateAtKey(stateKey);
        }
        if (!openKeys) {
            if (opts.defaultOpenAll) {
                openKeys = pipeline
                    .getDataSource()
                    .filter(hasDetail)
                    .map(function (row) { return row[primaryKey]; });
            }
            else {
                openKeys = opts.defaultOpenKeys;
            }
        }
        if (!openKeys) {
            openKeys = [];
        }
        var onChangeOpenKeys = function (nextKeys, key, status, row) {
            var optsOnChangeOpenKeys = opts.onChangeOpenKeys;
            isFunction(optsOnChangeOpenKeys) &&
                optsOnChangeOpenKeys(nextKeys, key, status, row);
            pipeline.setStateAtKey(stateKey, nextKeys, { key: key, status: status });
        };
        var openKeySet = new Set(openKeys);
        var toggle = function (rowKey, row) {
            var expanded = openKeySet.has(rowKey);
            if (expanded) {
                onChangeOpenKeys(openKeys.filter(function (key) { return key !== rowKey; }), rowKey, openStatus.collapse, row);
            }
            else {
                onChangeOpenKeys(__spreadArray(__spreadArray([], openKeys, true), [rowKey], false), rowKey, openStatus.expand, row);
            }
        };
        return (pipeline
            .dataSource(
        // 核心添加展开详情的逻辑
        flatMap(pipeline.getDataSource(), function (row, rowIndex) {
            var _a, _b;
            if (isDef(row) && openKeySet.has(row[primaryKey])) {
                return [
                    row,
                    __assign(__assign((_a = {}, _a[rowDetailMetaKey] = true, _a[sourceRowIndex] = rowIndex + 1, _a), row), (_b = {}, _b[storePrimaryKeySymbol] = row[primaryKey], _b[primaryKey] = getDetailKey(row, rowIndex), _b)),
                ];
            }
            return [row];
        }))
            // @ts-ignore
            .columns(processColumns(pipeline.getColumns()))
            .appendRowPropsGetter(function (row) {
            if (row[rowDetailMetaKey]) {
                return { className: 'no-hover' };
            }
            return { className: '' };
        }));
        function processColumns(columns) {
            if (columns.length === 0) {
                return columns;
            }
            var columnFlatCount = collectNodes(columns, 'leaf-only').length;
            var firstCol = columns[0];
            var judgeRender = function (value, row, rowIndex) {
                var _a;
                // 行详情
                if (row[rowDetailMetaKey]) {
                    return renderDetail(row, rowIndex);
                }
                var content = safeRender(firstCol, row, rowIndex);
                var rowKey = row[primaryKey];
                var expanded = openKeySet.has(rowKey);
                var expandCls = expanded ? 'expanded' : 'collapsed';
                var isHasDetail = !hasDetail(row, rowIndex);
                var onClick = function (event) {
                    if (opts.stopClickEventPropagation) {
                        event.stopPropagation();
                    }
                    toggle(rowKey, row);
                };
                //  不包含详情 减少过度设计，这里先注释
                if (isHasDetail) {
                    return (React.createElement(InlineFlexCell, { style: { marginLeft: textOffset } }, (_a = safeCallFunction(opts === null || opts === void 0 ? void 0 : opts.controlCustomRender, content, expandCls, isHasDetail)) !== null && _a !== void 0 ? _a : React.createElement("div", null)));
                }
                // 用户自定义展开列
                if (typeof opts.controlCustomRender === 'function') {
                    return (React.createElement("div", { style: { cursor: 'pointer', textAlign: 'center' }, onClick: onClick }, opts.controlCustomRender(content, expandCls, isHasDetail)));
                }
                // 默认内容
                return (React.createElement("div", { style: { cursor: 'pointer', textAlign: 'center' }, onClick: onClick },
                    React.createElement(CnIcon, { size: opts.defaultIconSize || 'small', type: expanded ? 'minus' : 'add', style: opts.defaultIconStyle })));
            };
            var getCellProps = function (value, row, rowIndex) {
                var _a;
                if (row[rowDetailMetaKey]) {
                    return {
                        style: __assign({ '--cell-padding': '0', '--cn-table__cell_padding': '0', overflow: 'hidden' }, opts.detailCellStyle),
                    };
                }
                var prevProps = (_a = firstCol.getCellProps) === null || _a === void 0 ? void 0 : _a.call(firstCol, value, row, rowIndex);
                return prevProps;
            };
            // 真正展示成行详情的逻辑
            return getRowDetailsCols(columns, 
            // newColProps
            {
                // 合并列，用于第一列
                getSpanRect: function (value, row, rowIndex) {
                    if (row[rowDetailMetaKey]) {
                        // detail 总是成一行， newCol 要多一列， 所以 columnFlatCount + 1
                        return {
                            top: rowIndex,
                            bottom: rowIndex + 1,
                            left: 0,
                            right: columnFlatCount + 1,
                        };
                    }
                    return {};
                },
                judgeRender: judgeRender,
                // @ts-ignore
                getCellProps: getCellProps,
                multiFirstRender: function (col, row, rowIndex) {
                    return row[rowDetailMetaKey]
                        ? renderDetail(row, rowIndex)
                        : safeRender(col, row, rowIndex);
                },
            });
        }
    };
}
function fallbackRenderDetail() {
    return (React.createElement("div", { style: { margin: '8px 24px' } },
        React.createElement("b", { style: { color: 'indianred' } },
            $i18n.get({ id: 'PleaseSet', dm: '请通过设置', ns: 'CnBaseTable' }),
            React.createElement("code", null, "rowDetail.renderDetail"),
            $i18n.get({
                id: 'CustomizeDetailedContent',
                dm: '来自定义详情内容',
                ns: 'CnBaseTable',
            }))));
}
