import { __assign, __rest } from "tslib";
import React from 'react';
import { CnPopover } from '@cainiaofe/cn-ui-m';
export { CnTooltipProps } from '@cainiaofe/cn-ui/es/components/cn-tooltip';
var alignMap = {
    l: 'left',
    t: 'top',
    r: 'right',
    b: 'bottom',
    lb: 'left-end',
    lt: 'left-start',
    tr: 'top-end',
    tl: 'top-start',
    rb: 'right-end',
    rt: 'right-start',
    br: 'bottom-end',
    bl: 'bottom-start',
};
var CnTooltip = function (props) {
    var trigger = props.trigger, children = props.children, className = props.className, style = props.style, align = props.align, rest = __rest(props, ["trigger", "children", "className", "style", "align"]);
    return (React.createElement(CnPopover, __assign({ mode: "dark", trigger: "click", content: children, className: className, style: style, placement: alignMap[align] }, rest), React.isValidElement(trigger) ? trigger : React.createElement("span", null, trigger)));
};
export { CnTooltip };
