import { __assign, __extends, __rest } from "tslib";
import './cn-ellipsis.scss';
import * as React from 'react';
import classNames from 'classnames';
import { CnTooltip } from "../../cn-tooltip";
import ResizeObserver from 'resize-observer-polyfill';
var CnEllipsis = /** @class */ (function (_super) {
    __extends(CnEllipsis, _super);
    function CnEllipsis(props) {
        var _this = _super.call(this, props) || this;
        _this.saveTextRef = function (ref) {
            _this.textRef = ref;
        };
        _this.saveEllipseRef = function (ref) {
            _this.ellipseRef = ref;
        };
        _this.state = {
            isOverflow: false,
        };
        return _this;
    }
    CnEllipsis.prototype.componentDidMount = function () {
        var _this = this;
        this.resizeObserver = new ResizeObserver(function () {
            _this.updateIsOverflow();
        });
        this.resizeObserver.observe(this.textRef);
    };
    CnEllipsis.prototype.componentWillUnmount = function () {
        this.resizeObserver.disconnect();
    };
    CnEllipsis.prototype.updateIsOverflow = function () {
        if (this.textRef && this.textRef.scrollWidth > this.textRef.offsetWidth) {
            !this.state.isOverflow &&
                this.setState({
                    isOverflow: true,
                });
        }
        else {
            this.state.isOverflow &&
                this.setState({
                    isOverflow: false,
                });
        }
    };
    CnEllipsis.prototype.render = function () {
        var isOverflow = this.state.isOverflow;
        var _a = this.props, children = _a.children, className = _a.className, ellipsisPosition = _a.ellipsisPosition, endCharCount = _a.endCharCount, hasTooltip = _a.hasTooltip, cnTooltipProps = _a.cnTooltipProps, otherProps = __rest(_a, ["children", "className", "ellipsisPosition", "endCharCount", "hasTooltip", "cnTooltipProps"]);
        // 默认
        var content = (React.createElement("div", { ref: this.saveTextRef, className: "cn-ui-ellipsis-header" }, children));
        // 省略号出现在中间
        if (ellipsisPosition === 'middle') {
            var childrenStr = "".concat(children);
            var startChildren = childrenStr.substr(0, childrenStr.length - endCharCount);
            var endChildren = childrenStr.substr(-endCharCount);
            content = (React.createElement(React.Fragment, null,
                React.createElement("div", { ref: this.saveTextRef, className: "cn-ui-ellipsis-header", style: { flex: 'unset' } }, startChildren),
                React.createElement("div", { className: "cn-ui-ellipsis-footer" }, endChildren)));
        }
        var trigger = (React.createElement("div", __assign({ ref: this.saveEllipseRef, className: classNames(CN_UI_HASH_CLASS_NAME, 'cn-ui-ellipsis', className), "data-name": otherProps['data-name'] || 'CnEllipsis' }, otherProps), content));
        // 出现 Balloon
        if (isOverflow && hasTooltip) {
            return (React.createElement("div", { ref: this.saveEllipseRef, "data-name": otherProps['data-name'] || 'CnEllipsis' },
                React.createElement(CnTooltip, __assign({ v2: true, trigger: trigger }, cnTooltipProps, { className: classNames('cn-ui-text-ellipsis-tooltip', cnTooltipProps === null || cnTooltipProps === void 0 ? void 0 : cnTooltipProps.className) }), children)));
        }
        return trigger;
    };
    CnEllipsis.displayName = 'CnEllipsis';
    CnEllipsis.defaultProps = {
        ellipsisPosition: 'end',
        endCharCount: 8,
        hasTooltip: true,
    };
    return CnEllipsis;
}(React.Component));
export { CnEllipsis };
