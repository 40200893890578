import React from 'react';
import { CnTooltip } from "../../../../cn-tooltip";
import { CnIcon } from "../../../../cn-icon";
export function TooltipTitle(_a) {
    var title = _a.title, tip = _a.tip, align = _a.align;
    var textDom = (React.createElement(CnIcon, { style: { marginLeft: '2px' }, type: "help-color", size: "medium" }));
    var justifyContentProp;
    if (align === 'center') {
        justifyContentProp = 'center';
    }
    else if (align === 'right') {
        justifyContentProp = 'flex-end';
    }
    else {
        justifyContentProp = 'flex-start';
    }
    return (React.createElement("div", { "data-testid": "title-has-tip", style: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: justifyContentProp,
        } },
        title,
        React.createElement(CnTooltip, { trigger: textDom, triggerType: "hover" }, tip)));
}
