import { __assign, __rest } from "tslib";
import * as React from 'react';
import { Checkbox as NextCheckbox } from './checkbox';
import $i18n, { withI18n } from 'panda-i18n';
import { CnReadOnly } from "../cn-read-only";
import locale from "../../locales";
import { useCnRequest } from "../cn-utils/old-request";
var renderPreview = function (value, readOnlyProps) {
    var showValue = value;
    if (typeof value === 'boolean') {
        showValue = value
            ? $i18n.get({ id: 'Yes', dm: '是', ns: 'CnCheckbox' })
            : $i18n.get({ id: '31255237809541120.CNTM', dm: '否', ns: 'CnCheckbox' });
    }
    return (React.createElement(CnReadOnly, __assign({ value: showValue, valueSeparator: ", ", type: "enum" }, readOnlyProps)));
};
export var CnComplexCheckboxGroup = withI18n(React.forwardRef(function (props, ref) {
    var readOnly = props.readOnly, readOnlyProps = props.readOnlyProps, _a = props.requestConfig, requestConfig = _a === void 0 ? {} : _a, otherProps = __rest(props, ["readOnly", "readOnlyProps", "requestConfig"]);
    var insertProps = {};
    var isRemoteDataSource = React.useMemo(function () {
        return !!((requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.url) || (requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.service));
    }, [requestConfig]);
    if (!requestConfig.resultFormatter && !requestConfig.formatResult) {
        requestConfig.formatResult = function (res) {
            var _a;
            if (Array.isArray(res)) {
                return res;
            }
            else if (Array.isArray(res === null || res === void 0 ? void 0 : res.data)) {
                return res.data;
            }
            else if (Array.isArray((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.dataSource)) {
                return res.data.dataSource;
            }
            return [];
        };
    }
    var _b = useCnRequest(__assign({ ready: isRemoteDataSource }, requestConfig)).data, data = _b === void 0 ? [] : _b;
    if (isRemoteDataSource) {
        insertProps.dataSource = data;
    }
    return (React.createElement(NextCheckbox.Group, __assign({ "data-name": "CnCheckbox", ref: ref, isPreview: readOnly, renderPreview: function (values) { return renderPreview(values, readOnlyProps); } }, insertProps, otherProps)));
}), {
    locale: locale,
    componentName: 'CnCheckbox',
});
CnComplexCheckboxGroup.displayName = 'CnComplexCheckboxGroup';
CnComplexCheckboxGroup.defaultProps = {
    readOnlyProps: {
        type: 'enum',
    },
};
