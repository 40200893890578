import { __assign } from "tslib";
import React, { forwardRef, useCallback, useContext, useState, useRef, useEffect, } from 'react';
import { Tag } from "../fusion";
import moment from 'moment';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { sendLog } from "../cn-utils";
import { CnTooltip } from "../cn-tooltip";
import { isEmpty, childrenToDataSource, valueToDataSourceItem, defaultDateFormat, getNormalizedDisplayName, } from './helper';
import FilterContext from './filter-context';
dayjs.extend(isoWeek);
dayjs.extend(advancedFormat);
export var useItemCollection = function (itemProps) {
    var filterContext = useContext(FilterContext);
    var defaultChildRef = useRef(null);
    var fusionChildRef = useRef(null);
    var itemPropsRef = useRef(null);
    var setValueRender = function (valueRender) {
        if (typeof valueRender === 'function') {
            itemPropsRef.current = {
                renderSelected: function (_key, val, label) {
                    return valueRender(val, label);
                },
            };
        }
        var update = (filterContext || {}).update;
        update && update();
    };
    var setRef = function (target, ref) {
        if (target && !target._observeProps) {
            target._observeProps = true;
            target._props = target.props;
            Object.defineProperty(target, 'props', {
                get: function () {
                    return target._props;
                },
                set: function (val) {
                    var update = (filterContext || {}).update;
                    if (target._props !== val) {
                        update && update();
                    }
                    target._props = val;
                },
            });
        }
        ref.current = target;
    };
    var getCollectionInjectPorps = function (displayName) {
        var inject = {
            ref: function (target) { return setRef(target, defaultChildRef); },
        };
        if (!formatMap.has(displayName) && !uninjectSet.has(displayName)) {
            inject.filterFusionRef = function (target) { return setRef(target, fusionChildRef); };
            inject.setFilterValueRender = setValueRender;
            inject.setFilterRenderValue = setValueRender;
            inject.itemPropsRef = itemPropsRef;
        }
        return inject;
    };
    var setCollection = function (name, displayName) {
        filterContext.itemCollection[name] = {
            itemProps: itemProps,
            defaultChildRef: defaultChildRef,
            fusionChildRef: fusionChildRef,
            itemPropsRef: itemPropsRef,
            displayName: displayName,
        };
    };
    return {
        getCollectionInjectPorps: getCollectionInjectPorps,
        setCollection: setCollection,
    };
};
var formatWithDataSource = function (key, value, dataSource, cache) {
    var itemCache = cache.get(key);
    if (!itemCache) {
        itemCache = new Map();
        cache.set(key, itemCache);
    }
    var items = valueToDataSourceItem(value, dataSource);
    var valueItems = items.map(function (item) {
        if (!item || !item.value) {
            // 在dataSource中未匹配的，尝试在cache中匹配
            var label = itemCache.get(item);
            if (label) {
                return {
                    label: label,
                    value: item,
                };
            }
        }
        return item;
    });
    // 更新缓存中label
    itemCache.clear();
    valueItems.forEach(function (item) {
        if (item === null || item === void 0 ? void 0 : item.label) {
            itemCache.set(item.value, item.label);
        }
    });
    return valueItems
        .map(function (item) { return item.label || item.value; })
        .filter(function (val) { return !isEmpty(val); })
        .join(', ');
};
var defaultFormat = function (key, value, child, cache) {
    if (Array.isArray(value)) {
        return value
            .filter(function (val) { return !isEmpty(val); })
            .map(function (val) { return defaultFormat(key, val, child, cache); })
            .join(',');
    }
    return value;
};
var uninjectSet = new Set([
    'Base',
    'NumberPicker',
    'DatePicker2',
    'RangePicker2',
    'WeekPicker2',
    'MonthPicker2',
    'QuarterPicker2',
    'YearPicker2',
    'Range',
    'Rating',
    'Switch',
    'Input',
    'Radio',
    'Checkbox',
    'CnNumberPicker',
    'CnRangeNumberPicker',
    'CnDatePickerPro',
    'CnRangeDatePickerPro',
    'CnWeekPickerPro',
    'CnMonthPickerPro',
    'CnQuarterPickerPro',
    'CnYearPickerPro',
    'CnTimePickerPro',
    'CnRangeTimePickerPro',
    'CnRange',
    'CnRating',
    'CnSwitch',
    'CnInput',
    'CnAsyncSelect',
    'CnCascaderSelect',
    'CnTreeSelect',
    'CnRadio',
    'CnCheckbox',
]);
var formatMap = new Map([
    [
        'CheckboxGroup',
        function (key, value, child, cache) {
            var _a = (child === null || child === void 0 ? void 0 : child.props) || {}, dataSource = _a.dataSource, children = _a.children;
            var normalizedDataSource = dataSource;
            if (children) {
                normalizedDataSource = childrenToDataSource(children, 'Checkbox', function (element) {
                    var _a, _b;
                    return {
                        value: (_a = element === null || element === void 0 ? void 0 : element.props) === null || _a === void 0 ? void 0 : _a.value,
                        label: (_b = element === null || element === void 0 ? void 0 : element.props) === null || _b === void 0 ? void 0 : _b.children,
                    };
                }, true);
            }
            return formatWithDataSource(key, value, normalizedDataSource, cache);
        },
    ],
    [
        'RadioGroup',
        function (key, value, child, cache) {
            var _a = (child === null || child === void 0 ? void 0 : child.props) || {}, dataSource = _a.dataSource, children = _a.children;
            var normalizedDataSource = dataSource;
            if (children) {
                normalizedDataSource = childrenToDataSource(children, 'Radio', function (element) {
                    var _a, _b;
                    return {
                        value: (_a = element === null || element === void 0 ? void 0 : element.props) === null || _a === void 0 ? void 0 : _a.value,
                        label: (_b = element === null || element === void 0 ? void 0 : element.props) === null || _b === void 0 ? void 0 : _b.children,
                    };
                }, true);
            }
            return formatWithDataSource(key, value, normalizedDataSource, cache);
        },
    ],
    [
        'CascaderSelect',
        function (key, value, child, cache) {
            var dataSource = ((child === null || child === void 0 ? void 0 : child.props) || {}).dataSource;
            if (!(value === null || value === void 0 ? void 0 : value.length))
                return '';
            return formatWithDataSource(key, value, dataSource, cache);
        },
    ],
    [
        'DatePicker',
        function (_, value, child) {
            var _a;
            if (!value)
                return '';
            var props = (child === null || child === void 0 ? void 0 : child.props) || {};
            var format = props.format || defaultDateFormat.date;
            if (props.showTime) {
                format = "".concat(format, " ").concat(((_a = props.showTime) === null || _a === void 0 ? void 0 : _a.format) || defaultDateFormat.time);
            }
            return moment(value).format(format);
        },
    ],
    [
        'RangePicker',
        function (_, value, child) {
            var _a;
            if (!(value === null || value === void 0 ? void 0 : value.length))
                return '';
            var props = (child === null || child === void 0 ? void 0 : child.props) || {};
            var format = props.format || defaultDateFormat.date;
            if (props.showTime) {
                format = "".concat(format, " ").concat(((_a = props.showTime) === null || _a === void 0 ? void 0 : _a.format) || defaultDateFormat.time);
            }
            if (typeof value === 'string')
                return value;
            return value
                .map(function (val) { return (val ? moment(val).format(format) : ''); })
                .join(' ~ ');
        },
    ],
    [
        'WeekPicker',
        function (_, value, child) {
            if (!value)
                return '';
            var props = (child === null || child === void 0 ? void 0 : child.props) || {};
            var format = props.format || defaultDateFormat.week;
            return moment(value).format(format);
        },
    ],
    [
        'MonthPicker',
        function (_, value, child) {
            if (!value)
                return '';
            var props = (child === null || child === void 0 ? void 0 : child.props) || {};
            var format = props.format || defaultDateFormat.month;
            return moment(value).format(format);
        },
    ],
    [
        'YearPicker',
        function (_, value, child) {
            if (!value)
                return '';
            var props = (child === null || child === void 0 ? void 0 : child.props) || {};
            var format = props.format || defaultDateFormat.year;
            return moment(value).format(format);
        },
    ],
    [
        'Picker',
        function (_, value, child) {
            var _a, _b;
            if (!value)
                return '';
            var props = (child === null || child === void 0 ? void 0 : child.props) || {};
            var type = props.type;
            var mode = (props === null || props === void 0 ? void 0 : props.mode) || 'date';
            if (type === 'range') {
                if (!(value === null || value === void 0 ? void 0 : value.length))
                    return '';
                var format_1 = props.format ||
                    defaultDateFormat[mode] ||
                    defaultDateFormat.date;
                if (props.mode === 'date' && props.showTime) {
                    format_1 = "".concat(format_1, " ").concat(((_a = props.showTime) === null || _a === void 0 ? void 0 : _a.format) ||
                        defaultDateFormat.time);
                }
                if (typeof value === 'string')
                    return value;
                return value
                    .map(function (val) { return (val ? dayjs(val).format(format_1) : ''); })
                    .join(' ~ ');
            }
            var format = props.format ||
                defaultDateFormat[mode] ||
                defaultDateFormat.date;
            if (mode === 'date' && props.showTime) {
                format = "".concat(format, " ").concat(((_b = props.showTime) === null || _b === void 0 ? void 0 : _b.format) || defaultDateFormat.time);
            }
            return dayjs(value).format(format);
        },
    ],
    [
        'Select',
        function (key, value, child, cache) {
            var _a = (child === null || child === void 0 ? void 0 : child.props) || {}, dataSource = _a.dataSource, children = _a.children;
            var normalizedDataSource = dataSource;
            if (children) {
                normalizedDataSource = childrenToDataSource(children, 'Option', function (element) {
                    var _a, _b;
                    return {
                        value: (_a = element === null || element === void 0 ? void 0 : element.props) === null || _a === void 0 ? void 0 : _a.value,
                        label: (_b = element === null || element === void 0 ? void 0 : element.props) === null || _b === void 0 ? void 0 : _b.children,
                    };
                }, true);
            }
            return formatWithDataSource(key, value, normalizedDataSource, cache);
        },
    ],
    [
        'TimePicker',
        function (_, value, child) {
            if (!value)
                return '';
            var props = (child === null || child === void 0 ? void 0 : child.props) || {};
            var format = props.format || defaultDateFormat.time;
            return moment(value).format(format);
        },
    ],
    [
        'TimePicker2',
        function (_, value, child) {
            if (!value)
                return '';
            var props = (child === null || child === void 0 ? void 0 : child.props) || {};
            var format = props.format || defaultDateFormat.time;
            return dayjs(value).format(format);
        },
    ],
    [
        'TreeSelect',
        function (key, value, child, cache) {
            var _a = (child === null || child === void 0 ? void 0 : child.props) || {}, dataSource = _a.dataSource, children = _a.children;
            var normalizedDataSource = dataSource;
            if (children) {
                normalizedDataSource = childrenToDataSource(children, 'TreeNode', function (element) {
                    var _a, _b;
                    return {
                        value: (_a = element === null || element === void 0 ? void 0 : element.props) === null || _a === void 0 ? void 0 : _a.value,
                        label: (_b = element === null || element === void 0 ? void 0 : element.props) === null || _b === void 0 ? void 0 : _b.label,
                    };
                });
            }
            return formatWithDataSource(key, value, normalizedDataSource, cache);
        },
    ],
]);
var formatRenderItem = function (key, value, itemColloction, selectedCache) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var targetColloction = itemColloction[key];
    var _j = (targetColloction === null || targetColloction === void 0 ? void 0 : targetColloction.itemProps) || {}, label = _j.label, renderSelected = _j.renderSelected;
    if (renderSelected) {
        return renderSelected(key, value, label);
    }
    if ((_b = (_a = targetColloction === null || targetColloction === void 0 ? void 0 : targetColloction.itemPropsRef) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.renderSelected) {
        return (_d = (_c = targetColloction === null || targetColloction === void 0 ? void 0 : targetColloction.itemPropsRef) === null || _c === void 0 ? void 0 : _c.current) === null || _d === void 0 ? void 0 : _d.renderSelected(key, value, label);
    }
    var prefix = label ? "".concat(label, ": ") : '';
    var target = (((_e = targetColloction === null || targetColloction === void 0 ? void 0 : targetColloction.fusionChildRef) === null || _e === void 0 ? void 0 : _e.current) ||
        ((_f = targetColloction === null || targetColloction === void 0 ? void 0 : targetColloction.defaultChildRef) === null || _f === void 0 ? void 0 : _f.current));
    var formatFn = formatMap.get(getNormalizedDisplayName((((_g = target === null || target === void 0 ? void 0 : target._reactInternals) === null || _g === void 0 ? void 0 : _g.type) ||
        ((_h = target === null || target === void 0 ? void 0 : target._reactInternalFiber) === null || _h === void 0 ? void 0 : _h.type)))) || defaultFormat;
    return prefix + formatFn(key, value, target, selectedCache);
};
var TagItem = function (_a) {
    var item = _a.item, handleClose = _a.handleClose;
    var _b = React.useState(false), isOverflow = _b[0], setIsOverflow = _b[1];
    var tagRef = React.useRef(null);
    var isStringText = typeof item.text === 'string';
    React.useEffect(function () {
        if (!isStringText || !tagRef || !tagRef.current)
            return;
        var tagBody;
        try {
            tagBody = tagRef.current
                .getInstance()
                ._reactInternalFiber.child.stateNode.getInstance()
                .tagNode.querySelector('.cn-next-tag-body');
        }
        catch (e) { /* empty */ }
        if (!tagBody)
            return;
        setIsOverflow(tagBody.scrollWidth > tagBody.offsetWidth);
    }, [item]);
    if (!item.text)
        return null;
    if (isStringText && isOverflow) {
        return (React.createElement(CnTooltip, { v2: true, trigger: React.createElement(Tag.Closeable, { ref: tagRef, key: item.key, type: "primary", onClose: function () { return handleClose(item); } }, item.text) }, item.text));
    }
    return (React.createElement(Tag.Closeable, { ref: tagRef, key: item.key, type: "primary", onClose: function () { return handleClose(item); } }, item.text));
};
var FilterSelectedTags = forwardRef(function (props, ref) {
    var values = props.values, innerValues = props.innerValues, onRemove = props.onRemove, extend = props.extend;
    // 缓存上次计算的label，value不变时直接返回上次计算的有效label，当datasource变化时也能得到原先的label，以应对动态datasource的情况
    var selectedCacheRef = useRef(new Map());
    var _a = useState(0), update = _a[1];
    var filterContext = useContext(FilterContext);
    var filterItemColloction = (filterContext === null || filterContext === void 0 ? void 0 : filterContext.itemCollection) || {};
    useEffect(function () {
        filterContext.update = function () { return update(function (i) { return (i + 1) % 32; }); };
        return function () {
            filterContext.update = function () { return undefined; };
        };
    }, [filterContext]);
    filterContext.update = function () { return update(function (i) { return (i + 1) % 32; }); };
    Object.keys(__assign(__assign({}, values), innerValues)).forEach(function (key) {
        var targetValue = values === null || values === void 0 ? void 0 : values[key];
        var targetInnerValue = innerValues === null || innerValues === void 0 ? void 0 : innerValues[key];
        var value = [];
        if (Array.isArray(targetValue)) {
            value.push.apply(value, targetValue);
        }
        else {
            value.push(targetValue);
        }
        if (Array.isArray(targetInnerValue)) {
            value.push.apply(value, targetInnerValue);
        }
        else {
            value.push(targetInnerValue);
        }
        if (value.filter(function (val) { return !isEmpty(val); }).length === 0) {
            selectedCacheRef.current.delete(key);
        }
    });
    // cache innerValues text
    Object.entries(innerValues || {}).forEach(function (_a) {
        var key = _a[0], value = _a[1];
        if (isEmpty(value) ||
            (Array.isArray(value) && !value.filter(function (val) { return !isEmpty(val); }).length)) {
            return;
        }
        formatRenderItem(key, value, filterItemColloction, selectedCacheRef.current);
    });
    var renderItems = Object.entries(values || {})
        .map(function (_a) {
        var key = _a[0], value = _a[1];
        if (isEmpty(value) ||
            (Array.isArray(value) && !value.filter(function (val) { return !isEmpty(val); }).length)) {
            return null;
        }
        var text = formatRenderItem(key, value, filterItemColloction, selectedCacheRef.current);
        return {
            key: key,
            value: value,
            text: text,
        };
    })
        .filter(Boolean);
    var handleClose = useCallback(function (item) {
        sendLog({
            id: 'cn-ui.cn-filter.deleteSelectedTag',
            name: 'CnFilter标签删除点击',
        });
        onRemove && onRemove(item.key);
        return true;
    }, [onRemove]);
    return (React.createElement(Tag.Group, { ref: ref, className: "cn-ui-filter-selected-tags" },
        extend,
        renderItems.map(function (item, idx) { return (React.createElement(TagItem, { key: idx, item: item, handleClose: handleClose })); })));
});
export default FilterSelectedTags;
