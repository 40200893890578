import { __spreadArray } from "tslib";
import React, { useContext } from 'react';
import cx from 'classnames';
import { CnIcon } from '@cainiaofe/cn-ui-m';
import { ShellStateContext } from './context';
export var MenuItem = function (props) {
    var data = props.data, _a = props.level, level = _a === void 0 ? 1 : _a;
    var _b = useContext(ShellStateContext), openKeys = _b.openKeys, setOpenKeys = _b.setOpenKeys, selectedKey = _b.selectedKey, setSelectedKey = _b.setSelectedKey, onSelectMenuItem = _b.onSelectMenuItem, setSideVisible = _b.setSideVisible;
    var key = data.key, text = data.text, menuTitle = data.menuTitle, icon = data.icon, _c = data.children, children = _c === void 0 ? [] : _c;
    var opened = openKeys.includes(key);
    var hasChildren = Boolean(children.length);
    var menuIconNode = level === 2 ? (React.createElement(CnIcon, { className: "cn-ui-m-shell-submenu-content-icon", type: icon || 'case-fill' })) : null;
    var textNode = (React.createElement("div", { className: "cn-ui-m-shell-submenu-content-text" }, text || menuTitle));
    return (React.createElement("li", { key: key, className: cx('cn-ui-m-shell-submenu', {
            'cn-ui-m-shell-submenu-has-children': hasChildren,
        }) },
        hasChildren ? (React.createElement("div", { className: cx('cn-ui-m-shell-submenu-content', {
                'cn-ui-m-shell-submenu-open': opened,
            }), onClick: function () {
                if (level === 1)
                    return;
                if (opened) {
                    setOpenKeys(__spreadArray([], openKeys.filter(function (it) { return it !== key; }), true));
                }
                else {
                    setOpenKeys(__spreadArray(__spreadArray([], openKeys, true), [key], false));
                }
            } },
            menuIconNode,
            textNode,
            level === 1 ? null : (React.createElement(CnIcon, { className: "cn-ui-m-shell-submenu-content-toggle", size: "small", type: opened ? 'arrow-down' : 'arrow-right' })))) : (React.createElement("div", { onClick: function () {
                setSelectedKey(key);
                onSelectMenuItem === null || onSelectMenuItem === void 0 ? void 0 : onSelectMenuItem(key, data, function () { return setSideVisible(false); });
                setSideVisible(false);
            }, className: cx('cn-ui-m-shell-submenu-content', {
                'cn-ui-m-shell-submenu-open': key === selectedKey,
            }) },
            menuIconNode,
            textNode)),
        (children === null || children === void 0 ? void 0 : children.length) && (opened || level === 1) ? (React.createElement("ul", { className: cx('cn-ui-m-shell-submenu-children', "cn-ui-m-shell-submenu-children-level-".concat(level)) }, children.map(function (subItem) { return (React.createElement(MenuItem, { key: subItem.key || subItem.path, data: subItem, level: level + 1 })); }))) : null));
};
