import { __assign, __rest } from "tslib";
import './scss/tag-group.scss';
import './index.scss';
import * as React from 'react';
import cx from 'classnames';
import { Tag as NextTag } from "../../../_fusion/lib";
import { withNativeProps } from '@cainiaofe/cn-ui-common';
var NextTagGroup = NextTag.Group, NextTagCloseable = NextTag.Closeable, NextTagSelectable = NextTag.Selectable;
export var CnTag = React.forwardRef(function (props, ref) {
    var _a;
    var status = props.status, className = props.className, rest = __rest(props, ["status", "className"]);
    var classes = cx('cn-ui-tag', className, (_a = {
            'cn-ui-tag-status': !!status
        },
        _a["cn-ui-tag-status-".concat(status)] = !!status,
        _a));
    return withNativeProps(props, React.createElement(NextTag, __assign({ "data-name": "CnTag", ref: ref, className: classes }, rest)));
});
CnTag.displayName = 'CnTag';
/**
 * @deprecated
 * 建议使用 TagGroup
 */
CnTag.Group = NextTagGroup;
export var CnTagGroup = React.forwardRef(function (props, ref) {
    return React.createElement(NextTagGroup, __assign({ "data-name": "CnTagGroup", ref: ref }, props));
});
CnTagGroup.displayName = 'CnTagGroup';
/**
 * @deprecated
 * 建议使用 TagCloseable
 */
CnTag.Closeable = NextTagCloseable;
export var CnTagCloseable = React.forwardRef(function (props, ref) {
    return React.createElement(NextTagCloseable, __assign({ "data-name": "CnTagCloseable", ref: ref }, props));
});
CnTagCloseable.displayName = 'CnTagCloseable';
/**
 * @deprecated
 * 建议使用 TagSelectable
 */
CnTag.Selectable = NextTagSelectable;
export var CnTagSelectable = React.forwardRef(function (props, ref) {
    return React.createElement(NextTagSelectable, __assign({ "data-name": "CnTagSelectable", ref: ref }, props));
});
CnTagSelectable.displayName = 'CnTagSelectable';
