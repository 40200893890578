import { __assign } from "tslib";
import './tag-group.scss';
import * as React from 'react';
import { CnTagSelectable } from "../../cn-tag";
import classNames from 'classnames';
export var TagGroup = function (props) {
    var _a;
    var _b;
    var size = props.size, value = props.value, onChange = props.onChange, readOnly = props.readOnly, isPreview = props.isPreview;
    var dataSource = ((_b = props.dataSource) === null || _b === void 0 ? void 0 : _b.map(function (item) {
        if (typeof item === 'object') {
            return __assign(__assign({}, item), { disabled: props.disabled || item.disabled });
        }
        return { label: item, value: item, disabled: props.disabled };
    })) || [];
    if (readOnly || isPreview) {
        var data = dataSource.find(function (item) { return item.value === value; });
        if ((data === null || data === void 0 ? void 0 : data.label) === undefined || (data === null || data === void 0 ? void 0 : data.label) === null) {
            return React.createElement(React.Fragment, null, "- -");
        }
        return React.createElement(React.Fragment, null, props.renderPreview(data === null || data === void 0 ? void 0 : data.label, props.readOnlyProps));
    }
    return (React.createElement("div", { className: classNames(CN_UI_HASH_CLASS_NAME, 'cn-radio-tag-group', (_a = {},
            _a["cn-radio-group--".concat(size)] = size,
            _a)) }, dataSource.map(function (item) {
        return (React.createElement(CnTagSelectable, { checked: value === item.value, onChange: function (_, event) {
                onChange(item.value, event);
            }, type: "primary", key: item.value }, item.label));
    })));
};
