import { __assign, __rest, __spreadArray } from "tslib";
import * as React from 'react';
import $i18n from 'panda-i18n';
import { useControllableValue } from 'ahooks';
import { useCnRequest, useNextLocale } from "../cn-utils";
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import isArray from 'lodash/isArray';
import { FormTree } from "../cn-async-tree/cn-async-tree";
import { CnCheckbox } from "../cn-checkbox";
import NextTransfer from './view/transfer';
import { getAllCheckedKeys, filterChildKey, filterParentKey, } from '../cn-tree/view/util';
import { getCheckAllValues } from "../cn-tree-select/utils";
import classNames from 'classnames';
export var CnTransfer = React.forwardRef(function (props, ref) {
    var _a, _b;
    var className = props.className, requestConfig = props.requestConfig, isTree = props.isTree, treeCheckedStrategy = props.treeCheckedStrategy, otherProps = __rest(props, ["className", "requestConfig", "isTree", "treeCheckedStrategy"]);
    var locale = useNextLocale('Transfer');
    var _c = useControllableValue(props), selected = _c[0], setSelected = _c[1];
    var _d = React.useState([]), treeDataSource = _d[0], setTreeDataSource = _d[1];
    var _e = React.useState(1), treeChanged = _e[0], setTreeChanged = _e[1];
    var transferRef = React.useRef(null);
    var treeRef = React.useRef(null);
    var insertTransferProps = {};
    var coverProps = {};
    var isRemoteDataSource = React.useMemo(function () {
        return !!((requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.url) || (requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.service));
    }, [requestConfig]);
    if (!requestConfig.resultFormatter && !requestConfig.formatResult) {
        requestConfig.formatResult = function (res) {
            var _a;
            if (Array.isArray(res)) {
                return res;
            }
            else if (Array.isArray(res === null || res === void 0 ? void 0 : res.data)) {
                return res.data;
            }
            else if (Array.isArray((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.dataSource)) {
                return res.data.dataSource;
            }
            return [];
        };
    }
    var _f = useCnRequest(__assign({ ready: isRemoteDataSource }, requestConfig)), run = _f.run, data = _f.data, mutate = _f.mutate;
    React.useImperativeHandle(ref, function () {
        var _a;
        return __assign({ mutateDataSource: mutate, sendRequest: run }, ((_a = transferRef.current) !== null && _a !== void 0 ? _a : {}));
    }, [transferRef]);
    insertTransferProps.dataSource = data || [];
    // onSearch 自动包装
    // 仅在 showSearch 且远程请求情况启用
    var enableOnSearch = otherProps.showSearch && !otherProps.onSearch && isRemoteDataSource;
    if (enableOnSearch) {
        insertTransferProps.onSearch = function (inputValue) {
            var _a;
            run((_a = {},
                _a[requestConfig.searchKey || 'key'] = inputValue,
                _a));
        };
        insertTransferProps.filter = function () { return true; };
    }
    var makeDisabled = function (dataSource, _selected) {
        var _a, _b;
        if (dataSource === void 0) { dataSource = []; }
        if (_selected === void 0) { _selected = []; }
        if (!((_b = (_a = treeRef === null || treeRef === void 0 ? void 0 : treeRef.current) === null || _a === void 0 ? void 0 : _a.datasetRef) === null || _b === void 0 ? void 0 : _b.current))
            return dataSource;
        var _c = treeRef.current.datasetRef.current, _k2n = _c._k2n, _p2n = _c._p2n;
        var keys = treeRef.current.getKeysByValue(_selected);
        var allCheckedKeys = getAllCheckedKeys(keys, _k2n, _p2n);
        var allCheckedValues = treeRef.current.getValueByKeys(allCheckedKeys);
        var loop = function (ds) {
            return ds.map(function (i) {
                var newItem = __assign(__assign({}, i), { disabled: allCheckedValues.includes(i.value) });
                if (i.children) {
                    newItem.children = loop(i.children);
                }
                return newItem;
            });
        };
        var newDs = loop(dataSource);
        return loop(newDs);
    };
    var originTreeDataSource = (_a = otherProps.dataSource) !== null && _a !== void 0 ? _a : insertTransferProps.dataSource;
    React.useEffect(function () {
        if (!isTree)
            return;
        setTreeDataSource(originTreeDataSource);
        setTreeChanged(function (s) { return (s + 1) % 32; });
    }, [originTreeDataSource, selected]);
    React.useEffect(function () {
        if (!isTree)
            return;
        // 树渲染完之后才有正确的dataset
        setTreeDataSource(makeDisabled(originTreeDataSource, selected));
    }, [treeChanged]);
    var transferDataSource = React.useMemo(function () {
        var _a, _b;
        if (!isTree)
            return [];
        if (!((_b = (_a = treeRef === null || treeRef === void 0 ? void 0 : treeRef.current) === null || _a === void 0 ? void 0 : _a.datasetRef) === null || _b === void 0 ? void 0 : _b.current))
            return [];
        var rst = [];
        var flatten = function (list) {
            if (list === void 0) { list = []; }
            list.forEach(function (item) {
                rst.push(item);
                flatten(item.children);
            });
        };
        flatten(originTreeDataSource);
        var _k2n = treeRef.current.datasetRef.current._k2n;
        var allKeys = treeRef.current.getKeysByValue(rst.map(function (i) { return i.value; }));
        rst = rst.filter(function (i) {
            var newKeys;
            var newValues;
            switch (treeCheckedStrategy) {
                case 'parent':
                    // newKeys = filterChildKey(allKeys, _k2n, _p2n);
                    // newValues = treeRef.current.getValueByKeys(newKeys);
                    // return newValues.includes(i.value);
                    return true;
                case 'child':
                    newKeys = filterParentKey(allKeys, _k2n);
                    newValues = treeRef.current.getValueByKeys(newKeys);
                    return newValues.includes(i.value);
                default:
                    return true;
            }
        });
        return rst;
    }, [originTreeDataSource, treeDataSource]);
    // 获取处理后的全选值
    var getRealCheckAllValues = function () {
        var _a;
        if (!treeRef.current)
            return [];
        var checkAllValues = getCheckAllValues((_a = treeRef.current) === null || _a === void 0 ? void 0 : _a.getTreeChildren());
        var keys = treeRef.current.getKeysByValue(checkAllValues);
        var _b = treeRef.current.datasetRef.current, _k2n = _b._k2n, _p2n = _b._p2n;
        keys = getAllCheckedKeys(keys, _k2n, _p2n);
        keys = filterChildKey(keys, _k2n, _p2n);
        checkAllValues = treeRef.current.getValueByKeys(keys);
        return __spreadArray([], checkAllValues, true);
    };
    var handleClickTreeCheckAll = function (checked, onChange, position) {
        if (!treeRef.current) {
            return;
        }
        if (checked) {
            onChange(position, getRealCheckAllValues());
        }
        else {
            onChange(position, []);
        }
    };
    var renderTreeFooter = function (_a) {
        var position = _a.position, showCheckAll = _a.showCheckAll, onChange = _a.onChange, value = _a.value;
        // 仅仅在 checkStrategy === parent 时无法确定 transfer dataSource,因此需要单独处理全选功能
        if (!showCheckAll || treeCheckedStrategy !== 'parent')
            return null;
        if (position !== 'left')
            return null;
        var allChecked = value &&
            !!value.length &&
            isEqual(sortBy(value), sortBy(getRealCheckAllValues()));
        return (React.createElement(CnCheckbox, { checked: allChecked, onChange: function (checked) {
                handleClickTreeCheckAll(checked, onChange, position);
            }, label: $i18n.get({ id: 'SelectAll', dm: '全选', ns: 'CnTreeSelect' }) }));
    };
    var treeProps = {};
    if (isTree) {
        coverProps.dataSource = transferDataSource;
        coverProps.footerRender = renderTreeFooter;
        var showSearch = otherProps.showSearch;
        if (showSearch && (showSearch[0] || typeof showSearch === 'boolean')) {
            coverProps.showSearch = isArray(showSearch)
                ? [false, showSearch[1]]
                : [false, true];
            treeProps.showSearch = true;
        }
    }
    var renderContent = function (_a) {
        var position = _a.position, onChange = _a.onChange, value = _a.value;
        if (!isTree)
            return null;
        if (position !== 'left')
            return null;
        return (React.createElement("div", { className: "cn-ui-transfer-tree" },
            React.createElement(FormTree, __assign({ ref: treeRef, checkable: true, value: value, onChange: function (v) {
                    onChange(position, v);
                }, dataSource: treeDataSource, checkedStrategy: treeCheckedStrategy }, treeProps))));
    };
    return (React.createElement(NextTransfer, __assign({ className: classNames(className, 'cn-ui-transfer'), ref: transferRef, "data-name": "CnTransfer", locale: locale }, insertTransferProps, omit(otherProps, ['$i18n']), coverProps, { value: selected, onChange: setSelected }), (_b = otherProps.children) !== null && _b !== void 0 ? _b : (function (_props) { return renderContent(_props); })));
});
CnTransfer.displayName = 'CnTransfer';
CnTransfer.defaultProps = {
    requestConfig: {},
    treeCheckedStrategy: 'all',
};
