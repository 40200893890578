import * as React from 'react';
import { CnList } from './list';
import { basicsPlugin } from '../plugin/basics';
import { Plugin, HocBaseComponents, pluginManager, } from "../../cn-utils";
export var ListWrapper = React.forwardRef(function (props, ref) {
    var plugin = React.useMemo(function () {
        var _plugin = new Plugin('CnList', pluginManager);
        _plugin.setBasicsPlugin(Object.values(basicsPlugin));
        _plugin.setLocalPlugin(props === null || props === void 0 ? void 0 : props.usePlugin);
        _plugin.setGlobalPlugin(pluginManager
            .getPluginsByComponentName('CnList')
            .map(function (item) { return item.method; }));
        return _plugin;
    }, []);
    var plugins = plugin.getPlugin();
    return HocBaseComponents(CnList, { props: props, plugins: plugins, ref: ref });
});
ListWrapper.defaultProps = {
    itemLayout: 'horizontal',
};
