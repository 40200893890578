import * as React from 'react';
import { CnIcon } from "../../../cn-icon";
import classnames from 'classnames';
export function FullScreenIcon(props) {
    var isFullScreen = props.isFullScreen;
    return (React.createElement(CnIcon, { type: isFullScreen ? 'exit-fullscreen' : 'fullscreen', size: "medium", className: classnames('cn-table-icon-fullscreen', {
            'cn-table-icon-fullscreen-highlight': isFullScreen,
        }) }));
}
export function ColumnSetIcon(props) {
    var activate = props.activate;
    return (React.createElement(CnIcon, { type: "config", size: "medium", className: classnames('cn-table-icon-column-set', {
            'cn-table-icon-column-set-highlight': activate,
        }) }));
}
export function SizeIcon() {
    return (React.createElement("svg", { style: { verticalAlign: 'middle' }, viewBox: "0 0 1024 1024", version: "1.1", xmlns: "http://www.w3.org/2000/svg", "p-id": "15035", width: 16, height: 16 },
        React.createElement("path", { d: "M512 472l1.64-0.04a31.872 31.872 0 0 0 19.712-8.128l1.28-1.2 152-152a32 32 0 0 0-43.984-46.464l-1.28 1.2L544 362.752V96a32 32 0 0 0-63.96-1.6L480 96v266.752L382.624 265.376a32 32 0 0 0-46.456 43.976l1.2 1.28 152 152A31.896 31.896 0 0 0 512 472zM224 704l1.6-0.04a32 32 0 0 0 0-63.92L224 640H144l-1.2-0.04a16 16 0 0 1-14.752-14.704L128 624V400l0.04-1.2a16 16 0 0 1 14.704-14.752L144 384h80l1.6-0.04a32 32 0 0 0 0-63.92L224 320H144l-2.24 0.032c-42.4 1.168-76.56 35.328-77.728 77.728L64 400v224l0.032 2.24c1.168 42.4 35.328 76.56 77.728 77.728L144 704h80z m656 0l2.24-0.032c42.4-1.168 76.56-35.328 77.728-77.728L960 624V400l-0.032-2.24c-1.168-42.4-35.328-76.56-77.728-77.728L880 320h-80l-1.6 0.04a32 32 0 0 0 0 63.92l1.6 0.04h80l1.2 0.04a16 16 0 0 1 14.752 14.704L896 400v224l-0.04 1.2a16 16 0 0 1-14.704 14.752L880 640h-80l-1.6 0.04a32 32 0 0 0 0 63.92l1.6 0.04h80z m-368 256a32 32 0 0 0 31.96-30.4L544 928l-0.008-266.744 97.384 97.368a32 32 0 0 0 46.456-43.976l-1.2-1.28-152-152a31.88 31.88 0 0 0-20.992-9.328L512 552c-8.192 0-16.376 3.12-22.624 9.376l-152 152a32 32 0 0 0 43.976 46.456l1.28-1.2 97.36-97.368L480 928a32 32 0 0 0 32 32z", "p-id": "11561" })));
}
export function ZebraIcon(props) {
    var zebra = props.zebra;
    return (React.createElement(CnIcon, { type: "zebra", size: "medium", className: classnames('cn-table-icon-zebra', {
            'cn-table-icon-zebra-highlight': zebra,
        }) }));
}
