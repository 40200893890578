import { __assign, __rest } from "tslib";
import React, { forwardRef, cloneElement } from 'react';
import { Form } from "../fusion";
import { CnTooltip } from "../cn-tooltip";
import { CnIcon } from "../cn-icon";
import { CnTextEllipsis } from "../cn-ellipsis";
import classnames from 'classnames';
import { format } from '@alifd/validate/lib/util';
import pickBy from 'lodash/pickBy';
import find from 'lodash/find';
import isFunction from 'lodash/isFunction';
import { useNextLocale } from "../cn-utils";
import FilterContext from './filter-context';
import { getFieldInitName, getNormalizedDisplayName, getItemStyleInjectProps, } from './helper';
import { useItemCollection } from './filter-selected-tags';
import { getValueFormatterInjectProps } from './value-formatter';
import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';
var FormItem = Form.Item;
export var CnFilterItem = forwardRef(function (props, ref) {
    var children = props.children, renderSelected = props.renderSelected, className = props.className, labelAlign = props.labelAlign, label = props.label, tips = props.tips, tip = props.tip, tipsProps = props.tipsProps, rules = props.rules, errorMessageName = props.errorMessageName, useLabelForErrorMessage = props.useLabelForErrorMessage, removeEmptyLabel = props.removeEmptyLabel, coverItemId = props.coverItemId, rest = __rest(props, ["children", "renderSelected", "className", "labelAlign", "label", "tips", "tip", "tipsProps", "rules", "errorMessageName", "useLabelForErrorMessage", "removeEmptyLabel", "coverItemId"]);
    var messages = (useNextLocale('Form') || {}).Validate;
    var filterContext = React.useContext(FilterContext);
    var _a = useItemCollection({
        renderSelected: renderSelected,
        label: label,
    }), setCollection = _a.setCollection, getCollectionInjectPorps = _a.getCollectionInjectPorps;
    var required = false;
    var requiredMessage;
    var requiredRule = find([].concat(rules), function (v) { return v === null || v === void 0 ? void 0 : v.required; });
    if (requiredRule) {
        required = true;
        requiredMessage = requiredRule.message || requiredMessage;
    }
    // 对 [null, null] 这样的 value 值的必填做处理
    if (required || rest.required) {
        var validator_1 = rest.validator;
        rest.validator = function (r, v, cb) {
            if (Array.isArray(v) && isEmpty(compact(v))) {
                typeof cb === 'function' &&
                    cb(format(messages === null || messages === void 0 ? void 0 : messages.required, r.aliasName || r.field));
            }
            if (typeof validator_1 === 'function') {
                validator_1(r, v, cb);
            }
            typeof cb === 'function' && cb();
        };
    }
    var customErrorMessageName;
    if (useLabelForErrorMessage && typeof label === 'string') {
        customErrorMessageName = errorMessageName || label;
    }
    var existName = false;
    var renderChildren = React.Children.map(children, function (child, idx) {
        var childProps = (child === null || child === void 0 ? void 0 : child.props) || {};
        var style = __assign({ width: '100%' }, childProps === null || childProps === void 0 ? void 0 : childProps.style);
        var initName = getFieldInitName(props, childProps, idx);
        if (initName && !existName) {
            existName = true;
            var displayName = getNormalizedDisplayName(child === null || child === void 0 ? void 0 : child.type);
            setCollection(initName, displayName);
            var collectionInjectProps = getCollectionInjectPorps(displayName);
            var valueFromatterInjectProps = getValueFormatterInjectProps(displayName);
            var styleInjectProps = getItemStyleInjectProps(displayName, filterContext, childProps);
            return cloneElement(child, pickBy(__assign(__assign(__assign({ style: style, rules: rules }, collectionInjectProps), valueFromatterInjectProps), styleInjectProps)));
        }
        return cloneElement(child, {
            style: style,
        });
    });
    var renderLabel = function () {
        if (removeEmptyLabel && !label) {
            return null;
        }
        return (React.createElement(React.Fragment, null,
            React.createElement("span", { className: "label-text" },
                React.createElement(CnTextEllipsis, { line: 1, hasTooltip: true }, label)),
            (tips || tip) && (React.createElement(CnTooltip, __assign({ trigger: React.createElement(CnIcon, { outerClassName: "cn-ui-filter-item-label-help-icon", type: "help-color", size: 14 }), align: "t" }, tipsProps), tips || tip))));
    };
    var insertProps = {};
    if (isFunction(coverItemId)) {
        insertProps.id = coverItemId(rest.name);
    }
    return (React.createElement(FormItem, __assign({ ref: ref, className: classnames('cn-ui-filter-item', !labelAlign || labelAlign === 'top' ? 'label-top' : 'label-left', className), label: renderLabel(), labelAlign: labelAlign, required: required, requiredMessage: requiredMessage, useLabelForErrorMessage: useLabelForErrorMessage, errorMessageName: customErrorMessageName }, insertProps, rest), renderChildren));
});
export default CnFilterItem;
CnFilterItem.displayName = 'CnFilterItem';
