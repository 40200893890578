import { __assign } from "tslib";
import './view/cn-balloon.scss';
import './view/cn-balloon-tooltip.scss';
import * as React from 'react';
import { Balloon } from "../fusion";
import classNames from 'classnames';
import { CnTooltip } from "../cn-tooltip";
import { withNativeProps } from '@cainiaofe/cn-ui-common';
export function CnBalloon(props) {
    return withNativeProps(props, React.createElement(Balloon, __assign({}, props, { className: classNames('cn-balloon-style', props.className), "data-name": "CnBalloon", "data-testid": "CnBalloon" }), props.children));
}
CnBalloon.displayName = 'CnBalloon';
/**
 * @deprecated 请直接使用 CnTooltip
 */
CnBalloon.Tooltip = CnTooltip;
/**
 * @deprecated 请直接使用 CnBalloon
 */
export { Balloon };
