import { __assign } from "tslib";
import React from 'react';
import NextSplitButton from "../../../_fusion/lib/split-button";
export var CnSplitButton = React.forwardRef(function (props, ref) {
    return React.createElement(NextSplitButton, __assign({ "data-name": "CnSplitButton", ref: ref }, props));
});
CnSplitButton.displayName = 'CnSplitButton';
/**
 * @deprecated  请使用 CnSplitButton 替换
 */
export var SplitButton = NextSplitButton;
