import './global-style';
import $i18n from 'panda-i18n';
import locale from './locales';
try {
    // @ts-ignore 忽略 hash class name
    window.CN_UI_HASH_CLASS_NAME = CN_UI_HASH_CLASS_NAME;
}
catch (_a) {
    /* empty */
}
$i18n.addLocale(locale);
export { CnFormField, createFromIconfontCN, CnSkeleton, } from '@cainiaofe/cn-ui-m';
export * from './components/cn-affix';
export * from './components/cn-anchor';
export * from './components/cn-animate';
export * from './components/cn-async-jobs-pro';
export * from './components/cn-async-select';
export * from './components/cn-async-tree';
export * from './components/cn-badge';
export * from './components/cn-balloon';
export * from './components/cn-bank-input';
export * from './components/cn-base-table';
export * from './components/cn-batch-input';
export * from './components/cn-box';
export * from './components/cn-breadcrumb';
export * from './components/cn-button';
export * from './components/cn-card';
export * from './components/cn-cascader-select';
export * from './components/cn-checkbox';
export * from './components/cn-checkbox-group';
export * from './components/cn-collapse';
export * from './components/cn-compact-select';
export * from './components/cn-complex-checkbox';
export * from './components/cn-complex-radio';
export * from './components/cn-confirm-button';
export * from './components/cn-currency-amount-number-picker';
export * from './components/cn-currency-select';
export * from './components/cn-date-picker-pro';
export * from './components/cn-department-select';
export * from './components/cn-detail-info';
export * from './components/cn-dialog';
export * from './components/cn-download';
export * from './components/cn-drag-transfer';
export * from './components/cn-drawer';
export * from './components/cn-dropdown';
export * from './components/cn-dynamic-form';
export * from './components/cn-ellipsis';
export * from './components/cn-employee-select';
export * from './components/cn-entry-points';
export * from './components/cn-export';
export * from './components/cn-filter';
export * from './components/cn-filter-pro';
export * from './components/cn-fiscal-year-picker';
export * from './components/cn-form';
export * from './components/cn-form-grid';
export * from './components/cn-form-item';
export * from './components/cn-grid';
export * from './components/cn-i18n-select';
export * from './components/cn-icon';
export * from './components/cn-image-viewer';
export * from './components/cn-input';
export * from './components/cn-input-text-area';
export * from './components/cn-list';
export * from './components/cn-loading';
export * from './components/cn-menu';
export * from './components/cn-menu-button';
export * from './components/cn-message';
export * from './components/cn-number-picker';
export * from './components/cn-oss-image-upload';
export * from './components/cn-oss-import';
export * from './components/cn-oss-upload';
export * from './components/cn-overlay';
export * from './components/cn-page';
export * from './components/cn-page-footer';
export * from './components/cn-placeholder';
export * from './components/cn-progress';
export * from './components/cn-radio';
export * from './components/cn-range';
export * from './components/cn-rating';
export * from './components/cn-read-only';
export * from './components/cn-result';
export * from './components/cn-scroll-bar';
export * from './components/cn-search';
export * from './components/cn-selector';
export * from './components/cn-shell';
export * from './components/cn-split-button';
export * from './components/cn-status-tag';
export * from './components/cn-step';
export * from './components/cn-switch';
export * from './components/cn-tab';
export * from './components/cn-table';
export * from './components/cn-tag';
export * from './components/cn-tag-group';
export * from './components/cn-text-ellipsis';
export * from './components/cn-time-picker-pro';
export * from './components/cn-timeline';
export * from './components/cn-tooltip';
export * from './components/cn-transfer';
export * from './components/cn-tree';
export * from './components/cn-tree-select';
export * from './components/cn-typography';
export * from './components/cn-upload';
export * as cnUtils from '@/components/cn-utils';
export * from './components/cn-format';
export * from './components/cn-layout';
export * from './components/cn-pagination';
export * from './components/cn-slider';
export * from './components/cn-tag-closeable';
export * from './components/cn-tag-selectable';
export * from './components/cn-list-pro';
export * from './form';
export * from './deprecated-components';
try {
    // @ts-ignore 忽略版本变量申明
    window.CN_UI_ONECODE_VERSION = CN_UI_ONECODE_VERSION;
    // @ts-ignore 忽略版本变量日志
    // eslint-disable-next-line no-console
    console.log("cn-ui-onecode \u7248\u672C\u53F7\u4E3A\uFF1A".concat(window.CN_UI_ONECODE_VERSION));
}
catch (_b) {
    /* empty */
}
