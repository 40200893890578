import { __assign } from "tslib";
import React from 'react';
import { CnBadge as MCnBadge } from '@cainiaofe/cn-ui-m';
var CnBadge = function (props) {
    var dot = props.dot;
    var shape = 'normal';
    if (dot) {
        shape = 'dot';
    }
    return React.createElement(MCnBadge, __assign({}, props, { shape: shape }));
};
export { CnBadge };
export var Badge = CnBadge;
