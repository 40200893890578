import { __assign, __rest } from "tslib";
import React from 'react';
import { Shell } from './shell';
import { ShellContext } from './context';
import './index.scss';
export var CnShell = function (props) {
    var children = props.children, restProps = __rest(props, ["children"]);
    return (React.createElement(ShellContext, __assign({}, restProps, { shellChildren: children }),
        React.createElement(Shell, null)));
};
