import { __rest } from "tslib";
import { useMemo } from 'react';
/**
 * 通过菜单数据计算出页面标题
 */
export var useShellTitle = function (props) {
    var selectedKey = props.selectedKey;
    // 把菜单打平成 Map 结构
    var menuDataMap = useMemo(function () {
        try {
            var temp_1 = {};
            // menuData 是通过接口获取到的数据，该数据理论不会被污染
            var menuData = window.menuData;
            var calcMenuDataMap_1 = function (list) {
                for (var _i = 0, list_1 = list; _i < list_1.length; _i++) {
                    var item = list_1[_i];
                    var subMenus = item.subMenus, restItem = __rest(item, ["subMenus"]);
                    temp_1[item.id] = restItem;
                    calcMenuDataMap_1(subMenus);
                }
            };
            calcMenuDataMap_1(menuData);
            return temp_1;
        }
        catch (_a) {
            /* empty */
        }
        return {};
    }, []);
    // 通过菜单数据计算出面包屑数据源
    var title = useMemo(function () {
        var _a;
        try {
            return (_a = menuDataMap[selectedKey]) === null || _a === void 0 ? void 0 : _a.text;
        }
        catch (_b) {
            /* empty */
        }
        return undefined;
    }, [menuDataMap, selectedKey]);
    return title;
};
