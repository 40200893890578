import { __assign } from "tslib";
import React from 'react';
import { CnCascaderSelect as UICnCascaderSelect } from '@cainiaofe/cn-ui-m';
import hoistNonReactStatics from 'hoist-non-react-statics';
export var CnCascaderSelect = React.forwardRef(function (props) {
    if (props === null || props === void 0 ? void 0 : props.multiple) {
        return React.createElement(UICnCascaderSelect, __assign({ version: "v2" }, props));
    }
    return React.createElement(UICnCascaderSelect, __assign({}, props));
});
CnCascaderSelect.displayName = 'CnCascaderSelect';
hoistNonReactStatics(CnCascaderSelect, UICnCascaderSelect);
