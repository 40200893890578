import { __assign } from "tslib";
import $i18n from 'panda-i18n';
import React from 'react';
import { CnButton } from "../../cn-button";
import { CnIcon } from "../../cn-icon";
import { useUploadState } from '@cainiaofe/cn-ui-common';
export var UploadButton = function (props) {
    var _a = useUploadState(), rootProps = _a.props, limited = _a.limited;
    var disabled = rootProps.disabled, capture = rootProps.capture;
    var buttonProps = props.buttonProps, buttonText = props.buttonText;
    return (React.createElement(CnButton, __assign({}, buttonProps, { iconSize: "medium", disabled: capture || limited ? true : disabled, icons: { loading: React.createElement(CnIcon, { type: "upload", size: "medium" }) } }), buttonText || $i18n.get({ id: 'Upload', dm: '上传', ns: 'CnOssUpload' })));
};
