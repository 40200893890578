// import { ConfigProvider } from 'cn-next';
import { withI18n } from 'panda-i18n';
import locale from "../../../../../../../locales";
import { componentName } from './const';
import ListFilter from './view';
var Index = withI18n(ListFilter, {
    componentName: componentName,
    locale: locale,
});
Index.displayName = componentName;
export default Index;
