import React, { createContext, useMemo, useState } from 'react';
import { useUpdateEffect } from 'ahooks';
import { useShellTitle } from './hooks/use-shell-title';
export var ShellStateContext = createContext({});
export var ShellContext = function (props) {
    var _a = props.headerProps, headerProps = _a === void 0 ? {} : _a, children = props.children, className = props.className, style = props.style, _b = props.mobileProps, mobileProps = _b === void 0 ? {} : _b, _c = props.sideBarProps, sideBarProps = _c === void 0 ? {} : _c, shellChildren = props.shellChildren;
    var pageTitle = mobileProps.pageTitle, isHidden = mobileProps.isHidden, menuSelectedKey = mobileProps.menuSelectedKey, menuBottomSlot = mobileProps.menuBottomSlot, userRightSlot = mobileProps.userRightSlot;
    var selectedKeyProps = menuSelectedKey || sideBarProps.selectedMenuKey;
    var _d = headerProps.user, user = _d === void 0 ? {} : _d, searchSlot = headerProps.searchSlot, widgetSlot = headerProps.widgetSlot, userSlot = headerProps.userSlot;
    var _e = useState(false), sideVisible = _e[0], setSideVisible = _e[1];
    var _f = useState([]), openKeys = _f[0], setOpenKeys = _f[1];
    var _g = useState(selectedKeyProps), selectedKey = _g[0], setSelectedKey = _g[1];
    var shellTitle = useShellTitle({ selectedKey: selectedKey });
    var title = pageTitle || shellTitle;
    useUpdateEffect(function () {
        setSelectedKey(selectedKeyProps);
    }, [selectedKeyProps]);
    var menu = useMemo(function () {
        return mobileProps.menu || sideBarProps.menu || [];
    }, [mobileProps.menu, sideBarProps.menu]);
    var onSelectMenuItem = useMemo(function () {
        if (mobileProps.onSelectMenuItem)
            return mobileProps.onSelectMenuItem;
        if (sideBarProps.onSelectMenuItem) {
            return function (_, item) {
                sideBarProps.onSelectMenuItem(item);
            };
        }
        return undefined;
    }, [mobileProps.onSelectMenuItem, sideBarProps.onSelectMenuItem]);
    var output = useMemo(function () {
        return {
            title: title,
            shellChildren: shellChildren,
            openKeys: openKeys,
            setOpenKeys: setOpenKeys,
            sideVisible: sideVisible,
            setSideVisible: setSideVisible,
            selectedKey: selectedKey,
            setSelectedKey: setSelectedKey,
            className: className,
            style: style,
            isHidden: isHidden,
            menu: menu,
            user: user,
            onSelectMenuItem: onSelectMenuItem,
            menuSelectedKey: menuSelectedKey,
            menuBottomSlot: menuBottomSlot,
            userSlot: userSlot,
            userRightSlot: userRightSlot,
            searchSlot: searchSlot,
            widgetSlot: widgetSlot,
        };
    }, [
        title,
        shellChildren,
        openKeys,
        setOpenKeys,
        sideVisible,
        className,
        style,
        isHidden,
        menu,
        user,
        onSelectMenuItem,
        menuSelectedKey,
        menuBottomSlot,
        userSlot,
        userRightSlot,
        searchSlot,
        widgetSlot,
    ]);
    return (React.createElement(ShellStateContext.Provider, { value: output }, children));
};
