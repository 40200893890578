import { __assign } from "tslib";
import React from 'react';
import { CnDatePickerPro as MCnDatePickerPro, CnRangeDatePickerPro as MCnRangeDatePickerPro, } from '@cainiaofe/cn-ui-m';
import { defaultEndOfDay, } from '@cainiaofe/cn-ui/es/components/cn-date-picker-pro/constants';
export { CnMonthPickerPro, CnYearPickerPro, CnWeekPickerPro, CnQuarterPickerPro, } from '@cainiaofe/cn-ui-m';
export var CnDatePickerPro = React.forwardRef(function (props, ref) {
    return React.createElement(MCnDatePickerPro, __assign({}, props, { ref: ref, mode: "calendar" }));
});
CnDatePickerPro.displayName = 'CnDatePickerPro';
export var CnRangeDatePickerPro = React.forwardRef(function (props, ref) {
    // 修复PC端和H5端因为endOfDay不一致导致的日期不一致问题，PC端默认是[false, true]，H5端默认是未定义,等价于[false, false]
    return React.createElement(MCnRangeDatePickerPro, __assign({}, props, { endOfDay: (props === null || props === void 0 ? void 0 : props.endOfDay) || defaultEndOfDay, ref: ref }));
});
CnRangeDatePickerPro.displayName = 'CnRangeDatePickerPro';
export { CnDatePicker2, CnMonthPicker2, CnYearPicker2, CnWeekPicker2, CnQuarterPicker2, CnRangeDatePicker2, } from '@cainiaofe/cn-ui/es/components/cn-date-picker-pro';
