import React from 'react';
import { CnPopover } from '@cainiaofe/cn-ui-m';
import { CnTooltip } from '@/components/cn-tooltip';
export var CnBalloon = function (props) {
    var trigger = props.trigger, children = props.children;
    return (React.createElement(CnPopover, { content: children, trigger: "click" }, trigger));
};
CnBalloon.displayName = 'CnBalloon';
CnBalloon.Tooltip = CnTooltip;
export var Balloon = CnBalloon;
