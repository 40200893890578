import { __assign, __rest } from "tslib";
import './view/cn-loading.scss';
import React, { forwardRef } from 'react';
import { Loading } from "../fusion";
import classNames from 'classnames';
import { obj } from "../../../_fusion/lib/util";
import { nativePropsReg } from '@cainiaofe/cn-ui-common';
export var CnLoading = forwardRef(function (_a, ref) {
    // const fullWidth = { width: '100%' };
    var _b, _c;
    var children = _a.children, _d = _a.visible, visible = _d === void 0 ? true : _d, _e = _a.tip, tip = _e === void 0 ? '' : _e, tipAlign = _a.tipAlign, _f = _a.className, className = _f === void 0 ? '' : _f, style = _a.style, _g = _a.size, size = _g === void 0 ? 'medium' : _g, fullScreen = _a.fullScreen, _h = _a.isGradient, isGradient = _h === void 0 ? true : _h, _j = _a.inline, inline = _j === void 0 ? true : _j, others = __rest(_a, ["children", "visible", "tip", "tipAlign", "className", "style", "size", "fullScreen", "isGradient", "inline"]);
    var cls = classNames('cn-load', (_b = {},
        _b["cn-load-".concat(size)] = size,
        _b));
    var svgCls = classNames('cn-load-svg', (_c = {},
        _c["cn-load-svg-".concat(size)] = size,
        _c));
    var temporaryIndicator = isGradient ? (React.createElement("svg", { className: svgCls, viewBox: "0 0 36 36", version: "1.1", xmlns: "http://www.w3.org/2000/svg", "aria-hidden": "true", "data-icon": "spin" },
        React.createElement("defs", null,
            React.createElement("linearGradient", { x1: "0%", y1: "100%", x2: "100%", y2: "100%", id: "linearGradient-22" },
                React.createElement("stop", { stopColor: "currentColor", stopOpacity: "0", offset: "0%" }),
                React.createElement("stop", { stopColor: "currentColor", stopOpacity: "0.50", offset: "39.9430698%" }),
                React.createElement("stop", { stopColor: "currentColor", offset: "100%" }))),
        React.createElement("g", { stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
            React.createElement("rect", { fillOpacity: "0.01", fill: "#FFFFFF", x: "0", y: "0", width: "36", height: "36" }),
            React.createElement("path", { d: "M34,18 C34,9.163444 26.836556,2 18,2 C11.6597233,2 6.18078805,5.68784135 3.59122325,11.0354951", stroke: "url(#linearGradient-22)", strokeWidth: "4", strokeLinecap: "round" })))) : (React.createElement("div", { className: cls }));
    var pickedNativeProps = obj.pickAttrsWith(others, nativePropsReg);
    return (React.createElement(Loading, __assign({ "data-testid": "CnLoading", "data-name": "CnLoading", indicator: temporaryIndicator, visible: visible, tip: tip, tipAlign: tipAlign, className: classNames(className, 'cn-ui-loading'), style: style, fullScreen: fullScreen, ref: ref, inline: inline }, pickedNativeProps), children));
});
CnLoading.defaultProps = {
    visible: true,
    tip: '',
    className: '',
};
CnLoading.displayName = 'CnLoading';
