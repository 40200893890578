import { __assign, __spreadArray } from "tslib";
/* eslint-disable react/no-unused-prop-types */
import * as React from 'react';
import $i18n from 'panda-i18n';
import isPlainObject from 'lodash/isPlainObject';
import { CnInput } from "../../../../../../../cn-input";
import { CnIcon } from "../../../../../../../cn-icon";
import { CnCheckbox } from "../../../../../../../cn-checkbox";
import { useControlValue } from './hooks';
import { debounce, isDef, isFunction, isString } from './util';
import dfs from './dfs';
import getFilterList from './get-filter-list';
import './style/index.scss';
export default function ListFilter(props) {
    var list = props.list, labelKey = props.labelKey, valueKey = props.valueKey, className = props.className, style = props.style, searchStyle = props.searchStyle, searchTransform = props.searchTransform, column = props.column;
    var _a = React.useState(''), search = _a[0], setSearch = _a[1];
    var onChangeSearch = debounce(function (val) {
        setSearch(val);
    }, 500, false);
    var allValue = React.useMemo(function () { return getAllValue(list, valueKey); }, [list]);
    // 过滤后的列表
    var filterList = React.useMemo(function () {
        return getFilterList(list, isFunction(searchTransform) ? searchTransform(search) : search, labelKey);
    }, [list, search, labelKey]);
    var _b = useControlValue(props, {
        defaultValuePropName: 'defaultValue',
        valuePropName: 'value',
        changePropName: 'onChange',
        defaultValue: [],
    }), value = _b[0], setValue = _b[1];
    var filterValue = React.useMemo(function () {
        // 使用 useMemo 避免重复计算
        return (
        // 过滤列表
        (filterList === null || filterList === void 0 ? void 0 : filterList.filter(function (item) {
            // 判断当前项是否在已选中的值中
            return value.includes(item[valueKey]);
        })) || [] // 如果 filterList 不存在，返回空数组
        );
    }, [filterList, value, valueKey]); // 依赖项为 filterList、value 和 valueKey
    var _c = useControlValue(props, {
        defaultValuePropName: 'defaultOpenKeys',
        valuePropName: 'openKeys',
        changePropName: 'onChangeOpenKeys',
        defaultValue: [],
    }), openKeys = _c[0], setOpenKeys = _c[1];
    var valueMap = React.useMemo(function () {
        var ret = {};
        if (Array.isArray(value)) {
            value.forEach(function (next) {
                var _a;
                ret = __assign(__assign({}, ret), (_a = {}, _a[next] = true, _a));
            });
        }
        return ret;
    }, [value]);
    var openKeysMap = React.useMemo(function () {
        var ret = {};
        if (Array.isArray(openKeys)) {
            openKeys.forEach(function (next) {
                var _a;
                ret = __assign(__assign({}, ret), (_a = {}, _a[next] = true, _a));
            });
        }
        return ret;
    }, [openKeys]);
    var onChange = function (changeValue, checked) {
        var ret = Array.isArray(value) ? __spreadArray([], value, true) : [];
        if (checked && !valueMap[changeValue]) {
            ret.push(changeValue);
            setValue(ret);
        }
        else if (!checked && valueMap[changeValue]) {
            var valueSet = new Set(ret);
            valueSet.delete(changeValue);
            setValue(Array.from(valueSet));
        }
    };
    var onChangeOpenKeys = function (changeOpenKey, isOpen) {
        var ret = Array.isArray(openKeys) ? __spreadArray([], openKeys, true) : [];
        if (isOpen && !openKeysMap[changeOpenKey]) {
            ret.push(changeOpenKey);
            setOpenKeys(ret);
        }
        else if (!isOpen && openKeysMap[changeOpenKey]) {
            var valueSet = new Set(ret);
            valueSet.delete(changeOpenKey);
            setOpenKeys(Array.from(valueSet));
        }
    };
    var onCheckAll = function (checked) {
        setValue(checked ? __spreadArray([], allValue, true) : []);
    };
    var onCheckAllFilterValue = function (checked) {
        var filterValueList = filterList === null || filterList === void 0 ? void 0 : filterList.map(function (item) { return item[valueKey]; });
        if (checked) {
            setValue(__spreadArray(__spreadArray([], value, true), filterValueList, true));
        }
        else {
            setValue(allValue.filter(function (item) { return filterList.includes(item); }));
        }
    };
    function renderItem(item, level, index) {
        var _a, _b, _c;
        if (isFunction(column === null || column === void 0 ? void 0 : column.render)) {
            var key = (_c = (_b = (_a = column.code) !== null && _a !== void 0 ? _a : column.key) !== null && _b !== void 0 ? _b : labelKey) !== null && _c !== void 0 ? _c : '';
            try {
                item[key] = JSON.parse(item[key]);
                // eslint-disable-next-line no-empty
            }
            catch (error) { }
            return column.render(item[key], item, level === 1 ? index : -1);
        }
        if (isUndefOrNullString(item[labelKey !== null && labelKey !== void 0 ? labelKey : 'label'])) {
            return $i18n.get({ id: 'Empty', dm: '空', ns: 'CnListFilter' });
        }
        return item[labelKey !== null && labelKey !== void 0 ? labelKey : 'label'];
    }
    // 渲染列表
    function renderList(infoList, level) {
        var _a;
        if (level === void 0) { level = 1; }
        // 如果列表不是数组，返回 null
        if (!Array.isArray(infoList)) {
            return null;
        }
        // 定义结果数组
        var result = [];
        var _loop_1 = function (i) {
            // 获取当前项
            var item = infoList[i] || { children: [] };
            // 获取当前项的值
            var ItemValue = isPlainObject(item === null || item === void 0 ? void 0 : item[valueKey !== null && valueKey !== void 0 ? valueKey : 'value'])
                ? JSON.stringify(item === null || item === void 0 ? void 0 : item[valueKey !== null && valueKey !== void 0 ? valueKey : 'value'])
                : item === null || item === void 0 ? void 0 : item[valueKey !== null && valueKey !== void 0 ? valueKey : 'value'];
            // 获取当前项的子项
            var children = (item === null || item === void 0 ? void 0 : item.children) || [];
            // 定义展开/收起图标
            var CaretRightIconElement = children.length > 0 ? (React.createElement(CaretRightIcon
            // 如果当前项已展开，添加 cn-list-filter-opened 类名
            , { 
                // 如果当前项已展开，添加 cn-list-filter-opened 类名
                className: openKeysMap[ItemValue] && 'cn-list-filter-opened', 
                // 点击图标时，调用 onChangeOpenKeys 函数
                onClick: function () {
                    onChangeOpenKeys(ItemValue, !openKeysMap[ItemValue]);
                } })) : null;
            // 定义复选框
            var CheckboxElement = (React.createElement(CnCheckbox
            // 如果当前项已选中，设置 checked 为 true
            , { 
                // 如果当前项已选中，设置 checked 为 true
                checked: (_a = valueMap[ItemValue]) !== null && _a !== void 0 ? _a : false, 
                // 点击复选框时，调用 onChange 函数
                onChange: function (checked) {
                    onChange(ItemValue, checked);
                }, className: "cn-list-filter-checkbox" }));
            // 定义当前项的元素
            var ItemElement = renderItem(item, level, i);
            // 定义当前项的包裹元素
            var ItemWrapperElement = (React.createElement("div", { 
                // 如果当前项已选中，添加 cn-list-filter-selected 类名
                className: "cn-list-filter-item ".concat(valueMap[item === null || item === void 0 ? void 0 : item[valueKey !== null && valueKey !== void 0 ? valueKey : 'value']]
                    ? 'cn-list-filter-selected'
                    : ''), 
                // 设置 key 为当前项的值
                key: ItemValue },
                CaretRightIconElement,
                CheckboxElement,
                ItemElement));
            // 将当前项的包裹元素添加到结果数组中
            result.push(ItemWrapperElement);
            // 如果当前项已展开
            if (openKeysMap[item === null || item === void 0 ? void 0 : item[valueKey !== null && valueKey !== void 0 ? valueKey : 'value']]) {
                // 定义子项的包裹元素
                var ChildrenWrapperElement = (React.createElement("div", { 
                    // 设置子项的左边距
                    style: { marginLeft: level * 16 }, className: "cn-list-filter-list" }, renderList(children, level + 1)));
                // 将子项的包裹元素添加到结果数组中
                result.push(ChildrenWrapperElement);
            }
        };
        // 遍历列表
        for (var i = 0; i < infoList.length; i++) {
            _loop_1(i);
        }
        // 返回结果数组
        return result;
    }
    var checkAll = (function () {
        var _a, _b, _c, _d;
        if (!search) {
            return (React.createElement("div", { className: "cn-list-filter-item ".concat(allValue.length === (value === null || value === void 0 ? void 0 : value.length) ? 'cn-list-filter-selected' : '') },
                React.createElement(CnCheckbox, { checked: allValue.length === (value === null || value === void 0 ? void 0 : value.length), indeterminate: allValue.length > (value === null || value === void 0 ? void 0 : value.length) && (value === null || value === void 0 ? void 0 : value.length) > 0, className: "cn-list-filter-checkbox", onChange: onCheckAll }),
                $i18n.get({ id: 'SelectAll', dm: '全选(', ns: 'CnListFilter' }), (_a = value === null || value === void 0 ? void 0 : value.length) !== null && _a !== void 0 ? _a : 0,
                "/", (_b = allValue === null || allValue === void 0 ? void 0 : allValue.length) !== null && _b !== void 0 ? _b : 0,
                ")"));
        }
        else if (filterList === null || filterList === void 0 ? void 0 : filterList.length) {
            return (React.createElement("div", { className: "cn-list-filter-item ".concat(allValue.length === (filterList === null || filterList === void 0 ? void 0 : filterList.length)
                    ? 'cn-list-filter-selected'
                    : '') },
                React.createElement(CnCheckbox, { checked: filterValue.length === (filterList === null || filterList === void 0 ? void 0 : filterList.length), indeterminate: filterValue.length < (filterList === null || filterList === void 0 ? void 0 : filterList.length) && filterValue.length > 0, className: "cn-list-filter-checkbox", onChange: onCheckAllFilterValue }),
                $i18n.get({
                    id: 'SelectAllSearchItems',
                    dm: '全选搜索项目(',
                    ns: 'CnListFilter',
                }), (_c = filterValue === null || filterValue === void 0 ? void 0 : filterValue.length) !== null && _c !== void 0 ? _c : 0,
                "/", (_d = filterList === null || filterList === void 0 ? void 0 : filterList.length) !== null && _d !== void 0 ? _d : 0,
                ")"));
        }
        else {
            return null;
        }
    })();
    return (React.createElement("div", { style: style, className: "cn-list-filter".concat(className ? " ".concat(className !== null && className !== void 0 ? className : '') : '') },
        React.createElement(CnInput, { innerBefore: React.createElement(CnIcon, { type: "search", style: { margin: 4 } }), placeholder: $i18n.get({
                id: 'FilterCurrentPage',
                dm: '筛选当前页',
                ns: 'CnBaseTable',
            }), className: "cn-list-filter-search", onChange: onChangeSearch, style: searchStyle }),
        React.createElement("div", { className: "cn-list-filter-list cn-list-filter-wrapper" },
            checkAll,
            renderList(filterList, 1))));
}
function getAllValue(list, valueKey) {
    var allValue = [];
    dfs(list, function (item) {
        if ((valueKey !== null && valueKey !== void 0 ? valueKey : 'value') in item) {
            allValue.push(item === null || item === void 0 ? void 0 : item[valueKey !== null && valueKey !== void 0 ? valueKey : 'value']);
        }
    });
    return allValue;
}
function CaretRightIcon(props) {
    return (React.createElement("svg", __assign({ focusable: "false", preserveAspectRatio: "xMidYMid meet", fill: "currentColor", width: "16", height: "16", viewBox: "0 0 32 32" }, props),
        React.createElement("path", { d: "M12 8L22 16 12 24z" })));
}
/**
 * 判断是否为 undefined 或 null 或空字符串
 * @param {any} val - 待判断的值
 * @returns {boolean} - 是否为 undefined 或 null 或空字符串
 */
function isUndefOrNullString(val) {
    return !isDef(val) || (isString(val) && val.replace(/\s/g, '') === '');
}
