import { componentMap } from '@cainiaofe/cn-ui-m';
import { Placeholder } from '@/components/placeholder';
/** 待确认 */
export var CnDatePicker2 = componentMap.CnDatePickerPro;
/** 暂不支持 */
export var CnMonthPicker2 = Placeholder;
/** 暂不支持 */
export var CnQuarterPicker2 = Placeholder;
/** 暂不支持 */
export var CnRangeDatePicker2 = Placeholder;
/** 暂不支持 */
export var CnWeekPicker2 = Placeholder;
/** 暂不支持 */
export var CnYearPicker2 = Placeholder;
export var CnDatePickerPro = componentMap.CnDatePickerPro;
export var CnMonthPickerPro = componentMap.CnMonthPickerPro;
export var CnQuarterPickerPro = componentMap.CnQuarterPickerPro;
export var CnWeekPickerPro = componentMap.CnWeekPickerPro;
export var CnYearPickerPro = componentMap.CnYearPickerPro;
export { CnRangeDatePickerPro } from '@/components/cn-date-picker-pro';
