import { __assign } from "tslib";
/* eslint-disable react/no-unused-prop-types */
import * as React from 'react';
import classNames from 'classnames';
import useDrag from 'ahooks/lib/useDrag';
import useDrop from 'ahooks/lib/useDrop';
import { ResponsiveGrid } from "../../fusion";
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import isFunction from 'lodash/isFunction';
import { useControlValue } from './hook';
import { CnIcon } from "../../cn-icon";
import { withNativeProps } from '@cainiaofe/cn-ui-common';
import './style/index.scss';
var Cell = ResponsiveGrid.Cell;
var defaultDragZone = [
    {
        key: 'default',
        title: 'Default zone',
        zoneStyle: {
            backgroundColor: '#E8EDF9',
        },
    },
    {
        key: 'more',
        title: 'More zone',
        zoneStyle: {
            backgroundColor: '#F8F8F8',
        },
    },
];
var CnDragTransfer = React.forwardRef(function (props, ref) {
    var className = props.className, dragZone = props.dragZone, defaultDataSource = props.defaultDataSource, gridProps = props.gridProps;
    var columns = gridProps.columns;
    var _a = useControlValue(props, {
        valuePropName: 'dataSource',
        changePropName: 'onChange',
        defaultValue: defaultDataSource !== null && defaultDataSource !== void 0 ? defaultDataSource : {},
    }), dataSource = _a[0], setDataSource = _a[1];
    var _b = React.useState(''), setDragingZone = _b[1];
    var _c = React.useState(''), setDropingZone = _c[1];
    var changeSort = function (fromKey, fromIndex, toKey, toIndex) {
        var _a;
        var fromList = Array.isArray(dataSource[fromKey])
            ? dataSource[fromKey]
            : [];
        var toList = Array.isArray(dataSource[toKey]) ? dataSource[toKey] : [];
        var finalAppendIndex = parseInt(toIndex);
        if (fromKey === toKey) {
            finalAppendIndex =
                parseInt(fromIndex) > parseInt(toIndex)
                    ? parseInt(toIndex)
                    : parseInt(toIndex) - 1; // 因为先 去掉了一个，影响了整体长度，所以要去掉
        }
        var append = fromList.splice(parseInt(fromIndex), 1);
        !isNil(append[0]) && toList.splice(finalAppendIndex, 0, append[0]);
        setDataSource(__assign(__assign({}, dataSource), (_a = {}, _a[fromKey] = fromList, _a[toKey] = toList, _a)), {
            fromKey: fromKey,
            fromIndex: fromIndex,
            toKey: toKey,
            toIndex: toIndex,
        });
        setDragingZone('');
        setDropingZone('');
    };
    var changeZone = function (nowZoneIndex, nowItemIndex, changeTo) {
        var _a, _b, _c;
        var fromKey = (_a = dragZone[nowZoneIndex]) === null || _a === void 0 ? void 0 : _a.key;
        var toKey = (_b = dragZone[nowZoneIndex + changeTo]) === null || _b === void 0 ? void 0 : _b.key;
        var toIndex = changeTo > 0 ? 0 : (_c = dataSource[toKey]) === null || _c === void 0 ? void 0 : _c.length;
        changeSort(fromKey, "".concat(nowItemIndex), toKey, "".concat(toIndex));
    };
    return withNativeProps(props, React.createElement("div", { className: classNames(CN_UI_HASH_CLASS_NAME, className, 'cn-ui-drag-transfer'), ref: ref }, map(dragZone, function (zoneConfig, zoneIndex) {
        var dataList = Array.isArray(dataSource === null || dataSource === void 0 ? void 0 : dataSource[zoneConfig === null || zoneConfig === void 0 ? void 0 : zoneConfig.key])
            ? dataSource === null || dataSource === void 0 ? void 0 : dataSource[zoneConfig === null || zoneConfig === void 0 ? void 0 : zoneConfig.key]
            : [];
        return (React.createElement("div", { key: zoneConfig === null || zoneConfig === void 0 ? void 0 : zoneConfig.key },
            React.createElement("div", { style: zoneConfig === null || zoneConfig === void 0 ? void 0 : zoneConfig.titleStyle, className: "cn-ui-drag-transfer-title" }, isFunction(zoneConfig === null || zoneConfig === void 0 ? void 0 : zoneConfig.title)
                ? zoneConfig === null || zoneConfig === void 0 ? void 0 : zoneConfig.title()
                : zoneConfig === null || zoneConfig === void 0 ? void 0 : zoneConfig.title),
            React.createElement(DropItem, { gridProps: gridProps, style: zoneConfig === null || zoneConfig === void 0 ? void 0 : zoneConfig.zoneStyle, className: "cn-ui-drag-transfer-zone", onChange: (function (fromKey, fromIndex, toIndex) {
                    changeSort(fromKey, fromIndex, zoneConfig === null || zoneConfig === void 0 ? void 0 : zoneConfig.key, toIndex);
                }) }, dataList.length > 0
                ? dataList.map(function (item, index) {
                    var _a, _b;
                    var colSpan = Math.min(Number(item.span) || 1, Number(columns));
                    return (React.createElement(Cell, { colSpan: colSpan, key: (_a = item.value) !== null && _a !== void 0 ? _a : index },
                        React.createElement(DragItem, { key: (_b = item.value) !== null && _b !== void 0 ? _b : index, sign: index, label: item.label, disabled: item.disabled, tansferKey: zoneConfig === null || zoneConfig === void 0 ? void 0 : zoneConfig.key, className: item.className, content: dragZone.length > 1 && (React.createElement("div", { className: "cn-ui-drag-transfer-adjust" },
                                zoneIndex !== dragZone.length - 1 && (React.createElement(CnIcon, { type: "icon-arrow-down1", size: "small", onClick: function () {
                                        changeZone(zoneIndex, index, 1);
                                    } })),
                                zoneIndex !== 0 && (React.createElement(CnIcon, { type: "icon-arrow-up1", size: "small", onClick: function () {
                                        changeZone(zoneIndex, index, -1);
                                    } })))), onChange: (function (fromKey, fromIndex, toIndex) {
                                changeSort(fromKey, fromIndex, zoneConfig === null || zoneConfig === void 0 ? void 0 : zoneConfig.key, toIndex);
                            }), onDragStart: function () {
                                setDragingZone(zoneConfig === null || zoneConfig === void 0 ? void 0 : zoneConfig.key);
                            }, onDragEnd: function () {
                                setDragingZone('');
                            }, onDragOver: function () {
                                setDropingZone(zoneConfig === null || zoneConfig === void 0 ? void 0 : zoneConfig.key);
                            }, onDrop: function () {
                                setDropingZone('');
                            } })));
                })
                : null)));
    })));
});
CnDragTransfer.displayName = 'CnDragTransfer';
function DropItem(props) {
    var children = props.children, style = props.style, className = props.className, onChange = props.onChange, gridProps = props.gridProps;
    var dropRef = React.useRef(null);
    useDrop(dropRef, {
        onText: function (data) {
            var _a, _b;
            var fromKey = (_a = data === null || data === void 0 ? void 0 : data.split) === null || _a === void 0 ? void 0 : _a.call(data, '_')[0];
            var fromIndex = (_b = data === null || data === void 0 ? void 0 : data.split) === null || _b === void 0 ? void 0 : _b.call(data, '_')[1];
            onChange instanceof Function &&
                onChange(fromKey, fromIndex, "".concat(React.Children.toArray(children).length));
        },
    });
    return (React.createElement("div", { ref: dropRef, style: style, className: className },
        React.createElement(ResponsiveGrid, __assign({}, gridProps), children !== null && children !== void 0 ? children : '')));
}
function DragItem(props) {
    var _a;
    var label = props.label, sign = props.sign, onChange = props.onChange, tansferKey = props.tansferKey, onDragStart = props.onDragStart, onDragEnd = props.onDragEnd, onDragOver = props.onDragOver, onDrop = props.onDrop, content = props.content, disabled = props.disabled, className = props.className;
    var dragRef = React.useRef(null);
    var dropRef = React.useRef(null);
    var _b = React.useState(false), dragging = _b[0], setDragging = _b[1];
    var _c = React.useState(''), align = _c[0], setAlign = _c[1];
    useDrag("".concat(tansferKey, "_").concat(sign), dragRef, {
        onDragStart: function (event) {
            if (disabled)
                return;
            event.dataTransfer.setData('text', "".concat(tansferKey, "_").concat(sign));
            // requestAnimationFrame 能保证，拖拽出来的和原来的不一样
            requestAnimationFrame(function () { return setDragging(true); });
            onDragStart instanceof Function && onDragStart(event);
        },
        onDragEnd: function (event) {
            if (disabled)
                return;
            setDragging(false);
            onDragEnd instanceof Function && onDragEnd(event);
        },
    });
    useDrop(dropRef, {
        onText: function (data) {
            var _a, _b;
            var toIndex = sign - (align === 'left' ? 0 : -1);
            if ("".concat(data) !== "".concat(tansferKey, "_").concat(toIndex)) {
                var fromKey = (_a = data === null || data === void 0 ? void 0 : data.split) === null || _a === void 0 ? void 0 : _a.call(data, '_')[0];
                var fromIndex = (_b = data === null || data === void 0 ? void 0 : data.split) === null || _b === void 0 ? void 0 : _b.call(data, '_')[1];
                onChange instanceof Function &&
                    onChange(fromKey, fromIndex, "".concat(toIndex));
            }
            setAlign('');
        },
        onDragLeave: function () { return setAlign(''); },
        onDrop: function (event) {
            var _a;
            if (disabled)
                return;
            onDrop instanceof Function && onDrop(event);
            (_a = event === null || event === void 0 ? void 0 : event.stopPropagation) === null || _a === void 0 ? void 0 : _a.call(event);
        },
        onDragOver: function (event) {
            if (disabled)
                return;
            onDragOver instanceof Function && onDragOver(event);
            if (!dragging) {
                var offsetX = event.offsetX;
                var width = event.target.getBoundingClientRect().width;
                if (offsetX > 0 && offsetX < width / 2) {
                    // 靠左
                    setAlign('left');
                }
                else if (offsetX >= width / 2 && offsetX < width) {
                    // 靠右
                    setAlign('right');
                }
            }
        },
    });
    return (React.createElement("div", { className: classNames('cn-ui-drag-item', (_a = {},
            _a[className] = !!className,
            _a)), ref: dropRef },
        align === 'left' && (React.createElement("div", { className: "cn-ui-drag-line", style: { left: -1 } })),
        React.createElement("div", { ref: dragRef, className: classNames('cn-ui-drag-item-content', {
                disabled: disabled,
            }) },
            label,
            !disabled && (React.createElement("div", { className: "cn-ui-drag-item-content-icons" },
                content,
                React.createElement(CnIcon, { type: "icon-hamburger", className: "cn-ui-drag-item-content-icons-drag" }))),
            dragging && React.createElement("span", { className: "cn-ui-drag-item-null" })),
        align === 'right' && (React.createElement("div", { className: "cn-ui-drag-line", style: { right: -1 } }))));
}
CnDragTransfer.defaultProps = {
    className: '',
    dragZone: defaultDragZone,
    gridProps: {
        columns: 3,
    },
};
export default CnDragTransfer;
