import { __assign } from "tslib";
import * as React from 'react';
import { CnFilter as PCCnFilter, CnFilterItem, } from '@cainiaofe/cn-ui/es/components/cn-filter';
import { CnCard } from '../cn-card';
import './index.scss';
export var CnFilter = function (props) {
    return (React.createElement(CnCard, { className: "cn-onecode-filter" },
        React.createElement(PCCnFilter, __assign({}, props))));
};
CnFilter.displayName = 'CnFilter';
CnFilter.Item = CnFilterItem;
CnFilter.getCache = PCCnFilter.getCache;
CnFilter.useField = PCCnFilter.useField;
export { CnFilterItem };
