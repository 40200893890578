import { __assign } from "tslib";
import './view/cn-overlay.scss';
import * as React from 'react';
import { Overlay } from "../../../_fusion/lib";
import { withNativeProps } from '@cainiaofe/cn-ui-common';
export var CnOverlay = function (props) {
    return withNativeProps(props, React.createElement(Overlay, __assign({ "data-name": "CnOverlay" }, props)));
};
export var CnPopup = function (props) {
    return withNativeProps(props, React.createElement(Overlay.Popup, __assign({ "data-name": "CnPopup" }, props)));
};
CnOverlay.displayName = 'CnOverlay';
CnPopup.displayName = 'CnPopup';
/**
 * @deprecated  请使用 CnOverlay 替换
 */
export { Overlay } from "../../../_fusion/lib";
