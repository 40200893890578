// 导入本地化文件
import locale from "../../locales";
// 导入国际化高阶组件
import { withI18n } from 'panda-i18n';
import { CnBaseTable } from './cn-base-table';
import { registerPipeline } from './register';
// 导出视图组件和类型
export * from './view';
export * from './type';
// 使用国际化高阶组件包装视图组件
var Table = withI18n(CnBaseTable, {
    locale: locale,
});
// 设置组件显示名称
Table.displayName = 'CnBaseTable';
Table.registerPipeline = registerPipeline;
export { Table as CnBaseTable, };
// 导出默认组件
export default Table;
