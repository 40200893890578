import { __awaiter, __generator } from "tslib";
// 第三方依赖
import * as React from 'react';
import isEqual from 'lodash/isEqual';
import isFunction from 'lodash/isFunction';
import _get from 'lodash/get';
import pick from 'lodash/pick';
import isNil from 'lodash/isNil';
import cloneDeep from 'lodash/cloneDeep';
import isString from 'lodash/isString';
import { useAsyncEffect } from 'ahooks';
// 其他组件
import { safeCallFunction } from "../../../cn-utils";
import { useControlValue } from './use-control-value';
// 给columns添加copyTitle属性
var addCopyTitle = function (columns) {
    return columns.map(function (item) {
        if (item.copyTitle) {
            return item;
        }
        item.copyTitle = isString(item.title) ? item.title : item.name;
        return item;
    });
};
export function useControlColumnsValue(props) {
    var _this = this;
    var _a = React.useState([]), localColumns = _a[0], setLocalColumns = _a[1];
    var remoteColumnsRef = React.useRef([]);
    var localColumnsRef = React.useRef(props.columns);
    var preColumns = React.useRef();
    var storageKey = React.useMemo(function () {
        var _a;
        return (_a = safeCallFunction(props.storageKey)) !== null && _a !== void 0 ? _a : props.storageKey;
    }, [props.storageKey]);
    React.useEffect(function () {
        if (!Array.isArray(props.columns)) {
            return;
        }
        var columns = addCopyTitle(props.columns);
        // 如果是高性能模式 columns引用变化就认为是columns发生变化
        if (props.ColumnsPerformance) {
            setColumns(columns);
            // 如果不是性能模式则深比较columns是否有变化
        }
        else if (!isEqual(preColumns.current, columns)) {
            // setLocalColumns(props.columns);
            setColumns(columns);
            preColumns.current = columns;
        }
    }, [props.columns, props.ColumnsPerformance]);
    useAsyncEffect(function () { return __awaiter(_this, void 0, void 0, function () {
        var result, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    if (!isFunction(props.onGetStorage))
                        return [2 /*return*/];
                    return [4 /*yield*/, props.onGetStorage()];
                case 1:
                    result = _a.sent();
                    if (!Array.isArray(result.columns))
                        return [2 /*return*/];
                    remoteColumnsRef.current = result.columns;
                    columnChange(localColumnsRef.current);
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, []);
    var columnChange = function (columns, type) {
        var _a, _b, _c;
        var columnSet = (_b = (_a = _get(props, 'toolbar.settings', [])) === null || _a === void 0 ? void 0 : _a.find) === null || _b === void 0 ? void 0 : _b.call(_a, function (item) { return (item === null || item === void 0 ? void 0 : item.type) === 'columnSet'; });
        var fullColumns = mergeLocalColumns(columns, type, storageKey);
        // 此为临时修复，在 columns 上添加 copyTitle 的行为仅执行一次，也就是将 setColumn 的行为统一
        var addedFullColumns = addCopyTitle(fullColumns);
        setLocalColumns(addedFullColumns);
        localColumnsRef.current = addedFullColumns;
        safeCallFunction((_c = props === null || props === void 0 ? void 0 : props.toolbar) === null || _c === void 0 ? void 0 : _c.columnChange, addedFullColumns, type);
        safeCallFunction(columnSet === null || columnSet === void 0 ? void 0 : columnSet.onChange, addedFullColumns, type);
    };
    /**
     * 合并本地列和远端列
     * @param {ICnTableColumn[]} columns - 当前表格列
     * @param {string} type - 操作类型
     * @param {string} _storageKey - 存储键名
     * @returns {ICnTableColumn[]} 合并后的列
     */
    function mergeLocalColumns(columns, type, _storageKey) {
        if (columns === void 0) { columns = []; }
        /**
         * 获取本地存储的列
         * @returns {ICnTableColumn[]} 本地存储的列
         */
        function getLocalColumns() {
            if (isFunction(props.onGetStorage)) {
                return remoteColumnsRef.current;
            }
            else if (_storageKey && !isFunction(props.onGetStorage)) {
                var localColumnStr = localStorage.getItem("COLUMNS_".concat(_storageKey));
                return JSON.parse(localColumnStr);
            }
        }
        if (type === 'user') {
            if (_storageKey && !isFunction(props.onSetStorage)) {
                var settingColumns = columns.map(function (item) { return ({
                    dataIndex: item.dataIndex,
                    code: item.code,
                    lock: item.lock,
                    align: item.align,
                    hidden: item.hidden,
                    width: item.width,
                }); });
                localStorage.setItem("COLUMNS_".concat(_storageKey), JSON.stringify(settingColumns));
            }
            safeCallFunction(props.onSetStorage, { columns: columns });
            remoteColumnsRef.current = columns;
            return columns;
        }
        else {
            var _localColumns = getLocalColumns();
            if (!Array.isArray(_localColumns))
                return columns;
            // 用本地的顺序合并远端columns(本地存储顺序也是列设置)
            // 因为要用本地列和远端列进行匹配,所以这里浅拷贝远端列逐一匹配删除,如果远端列有剩余就是有新增列
            var copyColumns_1 = cloneDeep(columns);
            var mergeColumns = _localColumns.reduce(function (_mergeColumns, localItem) {
                var columnsIndex = copyColumns_1.findIndex(function (item) {
                    if (!isNil(item === null || item === void 0 ? void 0 : item.dataIndex)) {
                        return (localItem === null || localItem === void 0 ? void 0 : localItem.dataIndex) === item.dataIndex;
                    }
                    if (!isNil(item === null || item === void 0 ? void 0 : item.code)) {
                        return (localItem === null || localItem === void 0 ? void 0 : localItem.code) === item.code;
                    }
                    return false;
                });
                if (columnsIndex !== -1) {
                    var mergeColumnsItem = Object.assign(copyColumns_1[columnsIndex], pick(localItem, [
                        'dataIndex',
                        'code',
                        'lock',
                        'align',
                        'hidden',
                        'width',
                    ]));
                    _mergeColumns.push(mergeColumnsItem);
                    copyColumns_1.splice(columnsIndex, 1);
                }
                return _mergeColumns;
            }, []);
            // 如果远端还有剩余列则为新增列放到最后
            return mergeColumns.concat(copyColumns_1);
        }
    }
    var _b = useControlValue({
        columns: localColumns,
        onChange: columnChange,
    }, {
        defaultValuePropName: [],
        valuePropName: 'columns',
        changePropName: 'onChange',
    }), columns = _b[0], setColumns = _b[1];
    return [columns, setColumns];
}
