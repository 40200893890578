import $i18n from 'panda-i18n';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { CnDrawer, CnButton, CnIcon, CnDialogFullpage, } from '@cainiaofe/cn-ui-m';
import { ShellStateContext } from './context';
import { MenuItem } from './menu-item';
export var Side = function () {
    var _a = useContext(ShellStateContext), sideVisible = _a.sideVisible, setSideVisible = _a.setSideVisible, user = _a.user, menu = _a.menu, menuBottomSlot = _a.menuBottomSlot, userSlot = _a.userSlot, userRightSlot = _a.userRightSlot, widgetSlot = _a.widgetSlot;
    var _b = useState(false), userSlotModalVisible = _b[0], setUserSlotModalVisible = _b[1];
    var UserRightSlot = useMemo(function () {
        if (userRightSlot) {
            return userRightSlot;
        }
        return userSlot ? (React.createElement("div", { className: "cn-ui-m-shell-side-user-right-slot-content" },
            React.createElement(CnButton, { type: "primary", text: true, onClick: function () { return setUserSlotModalVisible(true); } },
                React.createElement(CnIcon, { type: "setting" })))) : null;
    }, [userRightSlot, userSlot]);
    var avatar = user.img;
    var onClose = function () {
        setSideVisible(false);
        setUserSlotModalVisible(false);
    };
    var onUserSlotModalClose = useCallback(function () {
        setUserSlotModalVisible(false);
    }, []);
    // 在用户设备网络差且头像大的场景 头像可能加载比较慢，当前优化策略是先用默认头像占位， 用户的头像加载完之后再去除默认头像，防止两个头像相互影响；
    var _c = useState(false), imgLoaded = _c[0], setImgLoaded = _c[1];
    return (React.createElement(CnDrawer, { placement: "left", width: 270, visible: sideVisible, closeMode: ['esc', 'mask'], onClose: onClose, className: "cn-ui-m-shell-side-wrapper", containerClassName: "cn-ui-m-shell-side", noCard: true },
        React.createElement("div", { className: "cn-ui-m-shell-side-content" },
            React.createElement("div", { className: "cn-ui-m-shell-side-user" },
                React.createElement("div", { className: "cn-ui-m-shell-side-user-avatar".concat(imgLoaded ? ' cn-ui-m-shell-side-user-avatar-no-bg' : ''), onClick: function () { return setUserSlotModalVisible(true); } }, avatar ? (React.createElement("img", { src: avatar, onLoad: function () {
                        setImgLoaded(true);
                    }, className: "cn-ui-m-shell-side-user-avatar-img" })) : null),
                userSlot ? (React.createElement(CnDialogFullpage, { visible: userSlotModalVisible, onClose: onUserSlotModalClose, noCard: true, title: $i18n.get({ id: 'UserSettings', dm: '用户设置' }), footer: false },
                    React.createElement("div", { className: "user-overlay" },
                        React.isValidElement(userSlot) && userSlot,
                        typeof userSlot === 'function'
                            ? userSlot(onUserSlotModalClose)
                            : null))) : null,
                user.displayname ? (React.createElement("div", { className: "cn-ui-m-shell-side-user-name" }, user.displayname)) : null,
                React.createElement("div", { className: "cn-ui-m-shell-side-user-right-slot" }, UserRightSlot)),
            widgetSlot ? (React.createElement("div", { className: "cn-ui-m-shell-side-widget-slot" }, widgetSlot)) : null,
            React.createElement("ul", { className: "cn-ui-m-shell-side-menu" }, menu.map(function (item) { return (React.createElement(MenuItem, { key: item.key || item.path, data: item, level: 1 })); }))),
        menuBottomSlot ? (React.createElement("div", { className: "cn-ui-m-shell-side-bottom-slot" }, menuBottomSlot(onClose))) : null));
};
