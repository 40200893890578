import { __spreadArray } from "tslib";
import './cn-checkbox-tag-group.scss';
import * as React from 'react';
import classNames from 'classnames';
import { CnTagSelectable } from "../../cn-tag";
import { useControlled } from '@cainiaofe/cn-ui-common';
export var CnCheckboxTagGroup = function (props) {
    var _a;
    var size = props.size, dataSource = props.dataSource;
    var _b = useControlled(props), _c = _b[0], value = _c === void 0 ? [] : _c, onChange = _b[1];
    return (React.createElement("div", { className: classNames(CN_UI_HASH_CLASS_NAME, 'cn-checkbox-tag-group', (_a = {}, _a["cn-checkbox-group--".concat(size)] = size, _a)) }, dataSource.map(function (item) {
        var checked = value === null || value === void 0 ? void 0 : value.includes(item.value);
        return (React.createElement(CnTagSelectable, { key: String(item.value), type: "primary", checked: checked, onChange: function (_, event) {
                if (checked) {
                    onChange(value.filter(function (_item) { return _item !== item.value; }), event);
                }
                else {
                    onChange(__spreadArray(__spreadArray([], value, true), [item.value], false), event);
                }
            } }, item.label));
    })));
};
