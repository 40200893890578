import enUS from './en-US.json';
import esES from './es-ES.json';
import frFR from './fr-FR.json';
import jaJP from './ja-JP.json';
import koKR from './ko-KR.json';
import plPL from './pl-PL.json';
import ptPT from './pt-PT.json';
import ruRU from './ru-RU.json';
import trTR from './tr-TR.json';
import zhCN from './zh-CN.json';
import zhHK from './zh-HK.json';

export default {
  'en-US': enUS,
  'es-ES': esES,
  'fr-FR': frFR,
  'ja-JP': jaJP,
  'ko-KR': koKR,
  'pl-PL': plPL,
  'pt-PT': ptPT,
  'ru-RU': ruRU,
  'tr-TR': trTR,
  'zh-CN': zhCN,
  'zh-HK': zhHK,
  'pt-BR': ptPT,
  'zh-TW': zhHK,
};
