import { __assign, __rest } from "tslib";
import './cn-complex-checkbox.scss';
import * as React from 'react';
import { Checkbox as NextCheckbox } from './checkbox';
import $i18n, { withI18n } from 'panda-i18n';
import { CnReadOnly } from "../cn-read-only";
import locale from "../../locales";
var renderPreview = function (value, readOnlyProps) {
    var showValue = value;
    if (typeof value === 'boolean') {
        showValue = value
            ? $i18n.get({ id: 'Yes', dm: '是', ns: 'CnCheckbox' })
            : $i18n.get({ id: '31255237809541120.CNTM', dm: '否', ns: 'CnCheckbox' });
    }
    return (React.createElement(CnReadOnly, __assign({ value: showValue, valueSeparator: ", ", type: "enum" }, readOnlyProps)));
};
export var CnComplexCheckbox = withI18n(React.forwardRef(function (props, ref) {
    var readOnly = props.readOnly, readOnlyProps = props.readOnlyProps, otherProps = __rest(props, ["readOnly", "readOnlyProps"]);
    return (React.createElement(NextCheckbox, __assign({ "data-name": "CnComplexCheckbox", ref: ref, isPreview: readOnly, renderPreview: function (values) { return renderPreview(values, readOnlyProps); } }, otherProps)));
}), {
    locale: locale,
    componentName: 'CnCheckbox',
    noMemo: true,
});
CnComplexCheckbox.displayName = 'CnComplexCheckbox';
CnComplexCheckbox.defaultProps = {
    readOnlyProps: {
        type: 'enum',
    },
};
