import './cn-array-sub-area-card.scss';
import $i18n from 'panda-i18n';
import * as React from 'react';
import { formilyReact } from '@/form/formily';
import { CnCard, CnDialogFullpage } from '@cainiaofe/cn-ui-m';
import { CnBox } from '@/components/cn-box';
import { CnButton } from '@/components/cn-button';
import { CnIcon } from '@/components/cn-icon';
import { mixinCnFormArrayBase, CnFormArrayBase, } from '@/form/cn-form-array-base';
import isFunction from 'lodash/isFunction';
import { isAdditionComponent, isOperationComponent } from '../utils';
var useField = formilyReact.useField, observer = formilyReact.observer, useFieldSchema = formilyReact.useFieldSchema, RecursionField = formilyReact.RecursionField;
var CnArraySubAreaCardView = observer(function (props) {
    var _a;
    var _b = props.allowRemove, allowRemove = _b === void 0 ? true : _b;
    var _c = React.useState(false), visible = _c[0], setVisible = _c[1];
    var schema = useFieldSchema();
    var field = useField();
    var readOnly = (_a = field === null || field === void 0 ? void 0 : field.form) === null || _a === void 0 ? void 0 : _a.readOnly;
    var title = field === null || field === void 0 ? void 0 : field.title;
    var dataSource = Array.isArray(field === null || field === void 0 ? void 0 : field.value) ? field.value : [];
    var isEmpty = !dataSource.length;
    if (readOnly && isEmpty) {
        return React.createElement(React.Fragment, null, "- -");
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(CnBox, { className: "cn-ui-m-array-sub-area-card-entry", direction: "row", justify: "space-between", onClick: function () { return setVisible(true); } },
            React.createElement("div", null, $i18n.get({ id: 'TERM.Details', dm: '详情' })),
            React.createElement(CnIcon, { type: "arrow-right" })),
        React.createElement(CnDialogFullpage, { title: title, visible: visible, footer: false, onClose: function () { return setVisible(false); } },
            React.createElement(CnFormArrayBase, null,
                React.createElement(CnBox, { spacing: 12 }, dataSource === null || dataSource === void 0 ? void 0 :
                    dataSource.map(function (item, index) {
                        var itemsSchema = Array.isArray(schema.items)
                            ? schema.items[index] || schema.items[0]
                            : schema.items;
                        var itemTitle = isFunction(props === null || props === void 0 ? void 0 : props.title)
                            ? props.title(item, index)
                            : "".concat(index + 1);
                        return (React.createElement("div", null,
                            React.createElement(CnFormArrayBase.Item, { key: index, index: index, record: function () { return item; } },
                                React.createElement(CnCard, { subTitle: itemTitle, subAction: React.createElement(CnBox, { direction: "row", spacing: 8, align: "center" },
                                        typeof props.titleSlot === 'function'
                                            ? props.titleSlot(item, index)
                                            : null,
                                        !readOnly && allowRemove ? (React.createElement(CnButton, { text: true, warning: true, onClick: function (e) {
                                                var _a;
                                                e.stopPropagation();
                                                (_a = field === null || field === void 0 ? void 0 : field.remove) === null || _a === void 0 ? void 0 : _a.call(field, index);
                                            } },
                                            React.createElement(CnIcon, { type: "delete" }),
                                            $i18n.get({ id: 'Delete', dm: '删除' }))) : null,
                                        React.createElement(RecursionField, { schema: itemsSchema, name: index, onlyRenderProperties: true, filterProperties: function (contentSchema) {
                                                // 只渲染操作相关组件
                                                return isOperationComponent(contentSchema);
                                            } })), style: props.style, className: props.className, onClick: function (event) {
                                        var _a;
                                        (_a = props.onItemClick) === null || _a === void 0 ? void 0 : _a.call(props, event, item, index);
                                    }, onClickCapture: function (event) {
                                        var _a;
                                        (_a = props.onItemClickCapture) === null || _a === void 0 ? void 0 : _a.call(props, event, item, index);
                                    } },
                                    React.createElement(RecursionField, { schema: itemsSchema, name: index, filterProperties: function (operationSchema) {
                                            return !isOperationComponent(operationSchema);
                                        } })))));
                    }),
                    React.createElement("div", null, schema.reduceProperties(function (addition, additionSchema, key) {
                        if (isAdditionComponent(additionSchema)) {
                            return React.createElement(RecursionField, { schema: additionSchema, name: key });
                        }
                        return addition;
                    }, null)))))));
});
export var CnArraySubAreaCard = mixinCnFormArrayBase(CnArraySubAreaCardView);
CnArraySubAreaCard.displayName = 'CnArraySubAreaCard';
