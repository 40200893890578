import { __assign, __rest, __spreadArray } from "tslib";
// @ts-nocheck
import * as React from 'react';
import Search from "../../../_fusion/lib/search";
import isEmpty from 'lodash/isEmpty';
import isPlainObject from 'lodash/isPlainObject';
import { useCnRequest } from '@cainiaofe/cn-ui-common';
import { Tree } from './tree';
export var FormTree = React.forwardRef(function (props, ref) {
    var _a, _b;
    var requestConfig = props.requestConfig, showSearch = props.showSearch, enableRemoteLazyLoad = props.enableRemoteLazyLoad, _c = props.searchProps, searchProps = _c === void 0 ? {} : _c, _d = props.filterLocal, filterLocal = _d === void 0 ? true : _d, others = __rest(props, ["requestConfig", "showSearch", "enableRemoteLazyLoad", "searchProps", "filterLocal"]);
    var baseTreeRef = React.useRef(null);
    var treeItemRef = React.useRef(null);
    var _e = React.useState([]), innerDataSource = _e[0], setInnerDataSource = _e[1];
    var _f = React.useState([]), matchedKeys = _f[0], setMatchedKeys = _f[1];
    // 从 [] 变成了 undefined，这是为了防止，直接受控了，导致defaultExpenKeys无法使用
    var _g = React.useState(), expandedKeys = _g[0], setExpandedKeys = _g[1];
    var treeProps = {};
    var getItemByPos = function (pos) {
        if (!pos)
            return false;
        return pos
            .split('-')
            .slice(1)
            .reduce(function (ret, num) { return ret.children[num]; }, { children: innerDataSource });
    };
    var isRemoteDataSource = React.useMemo(function () {
        return !!((requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.url) || (requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.service));
    }, [requestConfig]);
    var formatResult = function (res) {
        var _a;
        if (Array.isArray(res)) {
            return res;
        }
        else if (Array.isArray(res === null || res === void 0 ? void 0 : res.data)) {
            return res.data;
        }
        else if (Array.isArray((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.dataSource)) {
            return res.data.dataSource;
        }
        return [];
    };
    if (!requestConfig.formatResult) {
        requestConfig.formatResult = formatResult;
    }
    var _h = useCnRequest(__assign({ ready: isRemoteDataSource }, requestConfig)), runAsync = _h.runAsync, _j = _h.data, data = _j === void 0 ? [] : _j, run = _h.run, mutate = _h.mutate;
    function fetchData() {
        return runAsync().then(function (dataSource) {
            setInnerDataSource(requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.formatResult(dataSource));
        });
    }
    if (props === null || props === void 0 ? void 0 : props.instance) {
        if (isPlainObject((_b = (_a = props === null || props === void 0 ? void 0 : props.instance) === null || _a === void 0 ? void 0 : _a.remoteOperation) === null || _b === void 0 ? void 0 : _b.current)) {
            // @ts-ignore
            props.instance.remoteOperation.current.fetchData = fetchData;
        }
    }
    React.useEffect(function () {
        if ((data === null || data === void 0 ? void 0 : data.length) === 0 && (innerDataSource === null || innerDataSource === void 0 ? void 0 : innerDataSource.length) === 0) {
            return;
        }
        if (treeItemRef.current) {
            treeItemRef.current.children = data;
            setInnerDataSource(function (_data) { return __spreadArray([], _data, true); });
            treeItemRef.current = null;
        }
        else {
            setInnerDataSource(formatResult(data));
        }
    }, [data]);
    if (isRemoteDataSource) {
        treeProps.dataSource = innerDataSource;
    }
    var enableOnSearch = showSearch && !others.onSearch;
    // const localSearch = showSearch && others.filterLocal;
    if (enableOnSearch) {
        if (filterLocal) {
            searchProps.onChange = function (value) {
                value = value.trim();
                if (!value) {
                    setMatchedKeys([]);
                    setExpandedKeys([]);
                    return;
                }
                var localMatchedKeys = [];
                var loop = function (_data) {
                    return _data.forEach(function (item) {
                        if (item.label.indexOf(value) > -1) {
                            localMatchedKeys.push(item.key);
                        }
                        if (item.children && item.children.length) {
                            loop(item.children);
                        }
                    });
                };
                loop(!isRemoteDataSource && others.dataSource
                    ? others.dataSource
                    : innerDataSource);
                setMatchedKeys(localMatchedKeys);
                setExpandedKeys(__spreadArray([], localMatchedKeys, true));
                treeProps.autoExpandParent = true;
            };
            treeProps.filterTreeNode = function (node) {
                return (!isEmpty(matchedKeys) &&
                    matchedKeys.indexOf(node.props.eventKey) > -1);
            };
            if (expandedKeys) {
                // cnTree 的判断是 'expandedKeys' in props来判断是否受控，必须保证undefiend的时候处于非受控状态
                treeProps.expandedKeys = expandedKeys;
            }
            // 展开收起时触发
            treeProps.onExpand = function (keys, extra) {
                // 展开
                if (extra.expanded) {
                    setExpandedKeys(keys);
                    treeProps.autoExpandParent = false;
                }
                else {
                    // 检测 extra.node 里面的 children 是否有keys，如果有则删除
                    // 解决展开父层级再展开子层级，父层级无法收起的问题
                    var loop_1 = function (node) {
                        return node.props.children.forEach(function (item) {
                            if (keys.includes(item.key)) {
                                keys.splice(keys.indexOf(item.key), 1);
                            }
                            if (item.props.children) {
                                loop_1(item);
                            }
                        });
                    };
                    loop_1(extra.node);
                    setExpandedKeys(keys);
                    treeProps.autoExpandParent = false;
                }
            };
        }
        else {
            searchProps.onSearch = function (inputValue) {
                var _a;
                var _b;
                run((_a = {}, _a[(_b = requestConfig.searchKey) !== null && _b !== void 0 ? _b : 'searchKey'] = inputValue, _a));
            };
        }
    }
    if (enableRemoteLazyLoad) {
        treeProps.loadData = function (node) {
            var _a;
            var _b;
            var item = getItemByPos((_b = node === null || node === void 0 ? void 0 : node.props) === null || _b === void 0 ? void 0 : _b.pos);
            if (!item)
                return false;
            return runAsync((_a = {},
                _a[requestConfig.remoteLazyLoadKey || 'value'] = item.value,
                _a)).then(function () {
                treeItemRef.current = item;
            });
        };
    }
    React.useImperativeHandle(ref, function () {
        var _a;
        return __assign({ mutateDataSource: mutate, 
            // 拼写错误，需修正
            sandRequest: function () {
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                console.log('[CnAsyncTree] 拼写错误，需修正为 sendRequest，此 API 会在后续版本静默移除');
                run.apply(void 0, args);
            }, sendRequest: run, dataSource: treeProps.dataSource }, ((_a = baseTreeRef === null || baseTreeRef === void 0 ? void 0 : baseTreeRef.current) !== null && _a !== void 0 ? _a : {}));
    });
    return (React.createElement(React.Fragment, null,
        showSearch && (React.createElement(Search, __assign({ shape: "simple", size: "medium", style: { margin: '10px 0', width: '100%' } }, searchProps))),
        React.createElement(Tree, __assign({ ref: baseTreeRef }, others, treeProps))));
});
FormTree.displayName = 'FormTree';
FormTree.useRemote = function () {
    var ref = React.useRef({});
    var fetch = React.useCallback(function () {
        var _a, _b;
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        // @ts-ignore
        if (typeof ((_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.fetchData) === 'function') {
            // @ts-ignore
            return (_b = ref === null || ref === void 0 ? void 0 : ref.current) === null || _b === void 0 ? void 0 : _b.fetchData.apply(_b, args);
        }
    }, [ref.current]);
    var load = React.useCallback(function () {
        return fetch(false); // 不作数据刷新
    }, []);
    var getInnerData = React.useCallback(function () {
        var _a;
        // @ts-ignore
        return (_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.innerInfo;
    }, []);
    return {
        load: load,
        remoteOperation: ref,
        getInnerData: getInnerData,
    };
};
FormTree.createRemoteInstance = function () {
    var ref = {
        current: {},
    };
    var fetch = function () {
        var _a, _b;
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        // @ts-ignore
        if (typeof ((_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.fetchData) === 'function') {
            // @ts-ignore
            return (_b = ref === null || ref === void 0 ? void 0 : ref.current) === null || _b === void 0 ? void 0 : _b.fetchData.apply(_b, args);
        }
    };
    var refresh = function () {
        fetch(true);
    };
    var load = function () {
        return fetch(false);
    };
    return {
        refresh: refresh,
        load: load,
        remoteOperation: ref,
    };
};
FormTree.defaultProps = {
    requestConfig: {},
};
