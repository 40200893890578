import { __assign, __rest } from "tslib";
import * as React from 'react';
import { CnCard as MCnCard } from '@cainiaofe/cn-ui-m';
import cx from 'classnames';
import { withMobileProps } from '@/utils/with-mobile-props';
export { CnCardSubAreaCard, CnCardSubAreaCardProps, } from '@cainiaofe/cn-ui/es/components/cn-card';
export var CnCard = withMobileProps(function (props) {
    var shape = props.shape, otherProps = __rest(props, ["shape"]);
    // pc shape 值为 normal，
    return (React.createElement(MCnCard, __assign({ shape: shape, noTitleBorder: shape === 'simple', contentGap: true }, otherProps)));
});
export var CnCardSubCard = withMobileProps(function (props) {
    var children = props.children, className = props.className, action = props.action, otherProps = __rest(props, ["children", "className", "action"]);
    return (React.createElement(MCnCard, __assign({ className: cx(className, 'cn-ui-onecode-sub-card'), subAction: action }, otherProps), children));
});
