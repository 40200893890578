import { __assign, __rest } from "tslib";
import * as React from 'react';
import { formilyShared, formilyReact } from '@/form/formily';
import { CnTab, CnTabItem } from '@/components/cn-tab';
import { useTabs } from '../hooks';
import { createCnFormTab } from '../utils';
import { FormTabTitle } from './form-tab-title';
var observer = formilyReact.observer, RecursionField = formilyReact.RecursionField, useField = formilyReact.useField;
export var CnFormTab = observer(function (props) {
    var tabField = useField();
    var formTabProps = props.formTab, otherProps = __rest(props, ["formTab"]);
    var formTab = React.useMemo(function () {
        return formTabProps || createCnFormTab();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var tabs = useTabs();
    var activeKey = props.activeKey || formTab.activeKey;
    return (React.createElement(CnTab, __assign({}, otherProps, (formilyShared.isValid(activeKey) && { activeKey: activeKey }), { onChange: function (k) {
            var _a, _b;
            (_a = otherProps.onChange) === null || _a === void 0 ? void 0 : _a.call(otherProps, k);
            (_b = formTabProps === null || formTabProps === void 0 ? void 0 : formTabProps.setActiveKey) === null || _b === void 0 ? void 0 : _b.call(formTabProps, k);
        } }), tabs.map(function (tab) { return (React.createElement(CnTabItem, __assign({ key: tab.name }, tab.props, { title: React.createElement(FormTabTitle, { title: tab.props.title, field: tabField, key: tab.name }) }),
        React.createElement(RecursionField, { name: tab.name, schema: tab.schema }))); })));
});
// @ts-ignore 忽略类型检查
CnFormTab.createCnFormTab = createCnFormTab;
