import React from 'react';
import { CnFormArrayBase } from '@/form/cn-form-array-base';
export { CnArrayTable } from './view/array-table';
export var CnArrayTableColumn = function (props) {
    var children = props.children;
    if (!children)
        return null;
    return React.createElement(React.Fragment, null, props.children);
};
export var CnArrayTableAddition = CnFormArrayBase.Addition;
export var CnArrayTableIndex = CnFormArrayBase.Index;
export var CnArrayTableRemove = CnFormArrayBase.Remove;
export var CnArrayTableMoveUp = CnFormArrayBase.MoveUp;
export var CnArrayTableMoveDown = CnFormArrayBase.MoveDown;
export var CnArrayTableSortHandle = CnFormArrayBase.SortHandle;
