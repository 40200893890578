import { __assign, __makeTemplateObject } from "tslib";
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import ReactDOM from 'react-dom';
import * as React from 'react';
import styled from 'styled-components';
import { Balloon } from "../../../fusion";
import { isFunction } from '../utils';
var DropDownContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  background: transparent;\n"], ["\n  position: fixed;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  background: transparent;\n"])));
var DropDown = 'cn-table-drapdown';
var DropDownOperate = 'cn-table-drapdown-operate';
var getContainer = function (containerType) {
    if (containerType === void 0) { containerType = 'drapdown'; }
    var DropDownId = containerType === 'drapdown' ? DropDown : DropDownOperate;
    var container = document.getElementById(DropDownId);
    if (!container) {
        container = document.createElement('div');
        container.id = DropDownId;
        document.body.append(container);
    }
    return container;
};
var show = function (event, content, opts) {
    var _a, _b;
    if (!content) {
        return;
    }
    // lock = true;
    var container = getContainer(opts === null || opts === void 0 ? void 0 : opts.containerType);
    var _c = opts || {}, offset = _c.offset, props = _c.props, _d = _c.accordTarget, accordTarget = _d === void 0 ? true : _d, _e = _c.triangleHeight, triangleHeight = _e === void 0 ? 10 : _e, _f = _c.zIndex, zIndex = _f === void 0 ? 100 : _f, footer = _c.footer, _g = _c.hasMask, hasMask = _g === void 0 ? true : _g, transform = _c.transform, target = _c.target, _h = _c.closeOnClick, closeOnClick = _h === void 0 ? true : _h, onMaskClick = _c.onMaskClick;
    var clientX = event.clientX;
    var targetDom = target !== null && target !== void 0 ? target : event === null || event === void 0 ? void 0 : event.target;
    // @ts-ignore
    var _j = isFunction(targetDom === null || targetDom === void 0 ? void 0 : targetDom.getBoundingClientRect)
        ? targetDom === null || targetDom === void 0 ? void 0 : targetDom.getBoundingClientRect()
        : {}, height = _j.height, left = _j.left, top = _j.top;
    var posTop = 0;
    var posLeft = 0;
    var innerHeight = window.innerHeight;
    var isTopHalf = top < innerHeight / 2;
    var addVerOffset = (_a = offset === null || offset === void 0 ? void 0 : offset[0]) !== null && _a !== void 0 ? _a : 0;
    var addHozOffset = (_b = offset === null || offset === void 0 ? void 0 : offset[1]) !== null && _b !== void 0 ? _b : 0;
    posTop = isTopHalf ? top + height + triangleHeight : top - triangleHeight;
    posLeft = accordTarget ? left : clientX;
    // @ts-ignore
    var Container = (opts === null || opts === void 0 ? void 0 : opts.Container) || Balloon.Inner;
    var maskClick = function () {
        closeOnClick && hide(opts === null || opts === void 0 ? void 0 : opts.containerType);
        onMaskClick instanceof Function && onMaskClick();
    };
    ReactDOM.render(React.createElement(React.Fragment, null,
        React.createElement(Container, __assign({ onClick: function (containerEvent) {
                containerEvent.stopPropagation();
            }, closable: false, align: isTopHalf ? 'b' : 't' }, (props || {}), { style: __assign({ position: 'fixed', top: isTopHalf ? posTop + addVerOffset : posTop - addVerOffset, left: posLeft + addHozOffset, zIndex: zIndex, transform: isFunction(transform)
                    ? transform({
                        isTopHalf: isTopHalf,
                        accordTarget: accordTarget,
                    })
                    : "translate(".concat(accordTarget ? 0 : -50, "%, ").concat(isTopHalf ? 0 : '-100', "%)") }, ((props === null || props === void 0 ? void 0 : props.style) || {})) }),
            content,
            footer),
        hasMask && (React.createElement(DropDownContainer, { onClick: maskClick, style: { zIndex: zIndex - 1 } }))), container);
};
function hide(containerType) {
    // if (!lock) {
    //   return;
    // }
    // lock = false;
    var container = getContainer(containerType);
    ReactDOM.unmountComponentAtNode(container);
}
export var Dropdown = {
    show: show,
    hide: hide,
};
var templateObject_1;
