import { __awaiter, __generator } from "tslib";
import $i18n from 'panda-i18n';
import React from 'react';
import { CnEllipsis } from "../../cn-ellipsis";
import { CnBox } from "../../cn-box";
import { calcFileSize, canUseImageViewer, getFileName, useShowUploadDownloadIcon, useShowUploadRemoveIcon, useShowUploadPreviewIcon, useUploadState, } from '@cainiaofe/cn-ui-common';
import { download } from '@cainiaofe/cn-utils';
import { CnIcon } from "../../cn-icon";
import { CnLoading } from "../../cn-loading";
import { CnProgress } from "../../cn-progress";
import { CnTooltip } from "../../cn-tooltip";
import { CnImageViewer } from "../../cn-image-viewer";
import { StaticFileIcon } from './icons';
import { preview } from './preview';
export var UploadListItem = function (props) {
    var _a = useUploadState(), readOnly = _a.readOnly, onRemove = _a.onRemove, rootProps = _a.props;
    var file = props.file, webOfficeEnable = props.webOfficeEnable, webOfficeEditRequestConfig = props.webOfficeEditRequestConfig, webOfficePreviewRequestConfig = props.webOfficePreviewRequestConfig;
    var disabled = rootProps.disabled;
    var size = file.size, key = file.key, status = file.status, percent = file.percent;
    var isUploading = status && ['idle', 'uploading'].includes(status);
    var renderFileFooter = function () {
        if (percent >= 0) {
            return React.createElement(CnProgress, { percent: percent });
        }
        if (size)
            return calcFileSize(size);
        return null;
    };
    var showRemove = useShowUploadRemoveIcon({
        showRemove: !disabled && !readOnly && rootProps.showRemove,
        file: file,
    });
    var showDownload = useShowUploadDownloadIcon({
        showDownload: rootProps.showDownload,
        onDownload: rootProps.onDownload,
        file: file,
    });
    var onDownload = function () {
        if (rootProps.onDownload)
            rootProps.onDownload(file);
        else
            download(file.url, file.name);
    };
    var showPreview = useShowUploadPreviewIcon({
        showPreview: rootProps.showPreview,
        onPreview: rootProps.onPreview,
        file: file,
    });
    var defaultOnPreview = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (rootProps.onPreview)
                rootProps.onPreview(file);
            else if (canUseImageViewer(file))
                CnImageViewer.open({ src: file.url });
            else
                window.open(file.url);
            return [2 /*return*/];
        });
    }); };
    var onPreview = function () { return __awaiter(void 0, void 0, void 0, function () {
        var err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!showPreview)
                        return [2 /*return*/];
                    if (!webOfficeEnable) return [3 /*break*/, 5];
                    /** 内置支持图片预览，无需调用 web office 预览能力 */
                    if (canUseImageViewer(file))
                        CnImageViewer.open({ src: file.url });
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, preview({
                            requestConfig: readOnly
                                ? webOfficePreviewRequestConfig
                                : webOfficeEditRequestConfig,
                            key: key,
                        })];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    err_1 = _a.sent();
                    defaultOnPreview();
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
                case 5:
                    defaultOnPreview();
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement("div", { className: "cn-ui-oss-upload-list-item", onClick: onPreview },
        React.createElement("div", { className: "cn-ui-oss-upload-list-item-header" }, isUploading ? (React.createElement(CnLoading, { className: "cn-ui-oss-upload-list-loading", size: "small" })) : (React.createElement(StaticFileIcon, { file: file }))),
        React.createElement("div", { className: "cn-ui-oss-upload-list-item-info" },
            React.createElement(CnEllipsis, { className: "cn-ui-oss-upload-list-item-info-file-name", ellipsisPosition: "middle", endCharCount: 4 }, getFileName(file)),
            React.createElement("div", { className: "cn-ui-oss-upload-list-item-info-footer" }, renderFileFooter())),
        React.createElement(CnBox, { direction: "row", spacing: 4, align: "center", className: "cn-ui-oss-upload-list-item-actions" },
            showPreview ? (React.createElement("div", { onClick: function (event) {
                    event.stopPropagation();
                    onPreview();
                }, className: "cn-ui-oss-upload-list-item-actions-item" },
                React.createElement(CnTooltip, { align: "t", trigger: React.createElement(CnIcon, { type: "eye", size: 14 }) }, webOfficeEnable
                    ? $i18n.get({
                        id: 'TapToPrevieweditForUpTo_1697746488',
                        dm: '点击最长可预览/编辑30分钟！过期后如需继续操作，请返回页面重新点击该按钮！',
                        ns: 'CnOssUpload',
                    })
                    : $i18n.get({ id: 'Preview', dm: '预览', ns: 'CnOssUpload' })))) : null,
            showDownload ? (React.createElement("div", { onClick: function (event) {
                    event.stopPropagation();
                    onDownload();
                }, className: "cn-ui-oss-upload-list-item-actions-item" },
                React.createElement(CnTooltip, { align: "t", trigger: React.createElement(CnIcon, { type: "download", size: 14 }) }, $i18n.get({ id: 'Download', dm: '下载', ns: 'CnOssUpload' })))) : null,
            showRemove ? (React.createElement("div", { onClick: function (event) {
                    event.stopPropagation();
                    onRemove(file);
                }, className: "cn-ui-oss-upload-list-item-actions-item" },
                React.createElement(CnTooltip, { align: "t", trigger: React.createElement(CnIcon, { type: "close", size: 14 }) }, $i18n.get({ id: 'TERM.Delete', dm: '删除', ns: 'CnOssUpload' })))) : null)));
};
