import { __assign, __rest } from "tslib";
import * as React from 'react';
import $i18n from 'panda-i18n';
import { DropDown2 as DropDown } from "../../../cn-base-table/view/dep";
import { CnTooltip } from "../../../cn-tooltip";
import { getParent } from '../utils';
import { ColumnsSettingPopup, ColumnsSettingDialog, ColumnsSettingContextProvider, } from './columns-setting';
import { columnsSettingArmsReport } from '../arms';
import { ColumnSetIcon } from './icons';
import classNames from 'classnames';
import GlobalContext from '../context';
export default function RenderColumnsSet(props) {
    var columns = props.columns, setColumns = props.setColumns, zIndex = props.zIndex;
    var tableProps = React.useContext(GlobalContext).tableProps;
    var _a = React.useState(false), columnSettingDialogVisible = _a[0], setColumnSettingDialogVisible = _a[1];
    var onShow = function (event) {
        var _a;
        if (!(columns === null || columns === void 0 ? void 0 : columns.length))
            return;
        var toolbarDom = getParent(event.target, function (el) { return el.className === 'cn-table-toolbar-settings'; });
        var customProps = { style: { left: 0 } };
        var rect = (_a = toolbarDom === null || toolbarDom === void 0 ? void 0 : toolbarDom.getBoundingClientRect) === null || _a === void 0 ? void 0 : _a.call(toolbarDom);
        if (rect) {
            customProps.style.left = rect.left + rect.width;
        }
        if (columns.length > 15) {
            setColumnSettingDialogVisible(true);
        }
        else {
            DropDown.show(event, React.createElement(ColumnsSettingContextProvider, { hasRowDetail: !!tableProps.rowDetail, columns: columns, setColumns: setColumns },
                React.createElement(ColumnsSettingPopup, null)), {
                accordTarget: false,
                hasMask: true,
                Container: PopupContainer,
                closeOnClick: true,
                target: toolbarDom,
                zIndex: zIndex,
                props: __assign(__assign({}, customProps), { columns: columns, setColumns: setColumns }),
                transform: function (_a) {
                    var isTopHalf = _a.isTopHalf;
                    return "translate(-100%, ".concat(isTopHalf ? 0 : '-100', "%)");
                },
            });
        }
        columnsSettingArmsReport();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(CnTooltip, { popupStyle: { wordBreak: 'keep-all' }, trigger: React.createElement("div", { "data-hottag": "cn-ui.cn-table.column-settings", className: "cn-table-toolbar-settings-item", onClick: onShow },
                React.createElement(ColumnSetIcon, { activate: columnSettingDialogVisible, color: columnSettingDialogVisible ? '#2f6cf7' : 'unset' })) }, $i18n.get({ id: 'ColumnSettings', dm: '列设置', ns: 'CnTable' })),
        React.createElement(ColumnsSettingContextProvider, { isDialog: true, columns: columns, setColumns: setColumns, hasRowDetail: !!tableProps.rowDetail },
            React.createElement(ColumnsSettingDialog, { visible: columnSettingDialogVisible, setVisible: setColumnSettingDialogVisible }))));
}
function PopupContainer(props) {
    var children = props.children, className = props.className, rest = __rest(props, ["children", "className"]);
    return (React.createElement("div", __assign({ className: classNames(CN_UI_HASH_CLASS_NAME, 'cn-table-pop-container') }, rest), children));
}
