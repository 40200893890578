/**
 * PC labelCol 对应移动端 labelAlign，但功能场景没 PC 复杂，所以此处转换为辅端做了简化适配
 */
export var useLabelAlign = function (labelCol) {
    if (labelCol === 'auto') {
        return 'auto';
    }
    if (labelCol === 'top') {
        return labelCol;
    }
    if (labelCol === 'left') {
        return 'left';
    }
    // 如果有值代表希望通过左右布局实现
    if (labelCol) {
        return 'left';
    }
    return 'top';
};
