import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import useUpdateEffect from 'ahooks/lib/useUpdateEffect';
import { CnBox } from "../../../cn-box";
import { MiniUploadList } from '../upload-list/mini-file-list';
import { useOnRemove } from '../upload-core/hooks/use-on-remove';
import { UploadCore } from '../upload-core';
import { CnTooltip } from '../../../cn-tooltip';
import { UploadButton } from '../upload-button';
export var CnMiniUploadView = React.forwardRef(function (props, ref) {
    var style = props.style, className = props.className, onPreview = props.onPreview, onDownload = props.onDownload, _onRemove = props.onRemove, showRemove = props.showRemove, showPreview = props.showPreview, showDownload = props.showDownload, formatter = props.formatter, directory = props.directory, method = props.method, headers = props.headers, beforeUpload = props.beforeUpload, customRequest = props.customRequest, withCredentials = props.withCredentials, openFileDialogOnClick = props.openFileDialogOnClick, buttonText = props.buttonText, readOnly = props.readOnly, onError = props.onError, description = props.description, buttonProps = props.buttonProps, name = props.name, _a = props.multiple, multiple = _a === void 0 ? false : _a, action = props.action, data = props.data, accept = props.accept, _b = props.showUploadList, showUploadList = _b === void 0 ? true : _b, disabled = props.disabled, _c = props.limit, limit = _c === void 0 ? 999 : _c, originValue = props.value, defaultValue = props.defaultValue, onChange = props.onChange, popupProps = props.popupProps, webOfficeEnable = props.webOfficeEnable, webOfficeEditRequestConfig = props.webOfficeEditRequestConfig, webOfficePreviewRequestConfig = props.webOfficePreviewRequestConfig;
    var uploadRef = useRef();
    var onRemoveRef = useRef(_onRemove);
    var onChangeRef = useRef(onChange);
    var _d = useState(function () {
        if (originValue === null || originValue === void 0 ? void 0 : originValue.length)
            return originValue;
        if (defaultValue === null || defaultValue === void 0 ? void 0 : defaultValue.length)
            return defaultValue;
        return [];
    }), value = _d[0], setValue = _d[1];
    useEffect(function () {
        onChangeRef.current = onChange;
        onRemoveRef.current = _onRemove;
    }, [onChange, _onRemove]);
    useUpdateEffect(function () {
        setValue(originValue || []);
    }, [originValue]);
    var onRemove = useOnRemove({
        setValue: setValue,
        uploadRef: uploadRef,
        onChangeRef: onChangeRef,
        onRemoveRef: onRemoveRef,
    });
    var isDisabledUpload = disabled || value.length >= limit;
    return (React.createElement("div", { "data-name": "CnMiniUpload", style: style, className: classNames(className, 'cn-ui-upload', 'cn-ui-upload-mini', {
            'cn-ui-upload-readonly': readOnly,
            'cn-ui-upload-disabled': isDisabledUpload,
        }) },
        React.createElement(CnBox, { direction: "row", align: "center", className: "cn-ui-upload-core" }, readOnly ? null : (React.createElement(UploadCore, { ref: ref, formatter: formatter, directory: directory, method: method, headers: headers, beforeUpload: beforeUpload, customRequest: customRequest, withCredentials: withCredentials, openFileDialogOnClick: openFileDialogOnClick, onError: onError, name: name, multiple: multiple, action: action, data: data, accept: accept, disabled: isDisabledUpload, onChange: onChange, setValue: setValue }, description ? (React.createElement(CnTooltip, { trigger: React.createElement(UploadButton, { buttonProps: buttonProps, disabled: isDisabledUpload, buttonText: buttonText }) }, description)) : (React.createElement(UploadButton, { buttonProps: buttonProps, disabled: isDisabledUpload, buttonText: buttonText }))))),
        showUploadList ? (React.createElement(MiniUploadList, { popupProps: popupProps, readOnly: readOnly, value: value, limit: limit, onPreview: onPreview, onDownload: onDownload, onRemove: onRemove, showRemove: !disabled && showRemove, showPreview: showPreview, showDownload: showDownload, webOfficeEnable: webOfficeEnable, webOfficeEditRequestConfig: webOfficeEditRequestConfig, webOfficePreviewRequestConfig: webOfficePreviewRequestConfig })) : null));
});
CnMiniUploadView.displayName = 'CnMiniUploadView';
