import { __assign, __awaiter, __generator, __rest } from "tslib";
import $i18n from 'panda-i18n';
import React from 'react';
import { CnButton } from "../../cn-button";
import { CnBalloon } from "../../cn-balloon";
import CnDialog from "../../cn-dialog/view/index";
import { CnMessage } from "../../cn-message";
import { unpackRequest } from '@cainiaofe/cn-ui-common';
import omit from 'lodash/omit';
import cx from 'classnames';
import './index.scss';
var MESSAGE_TYPE = {
    alert: 'warning',
    confirm: 'help',
    's-alert': 'warning',
};
var handleRequest = function (requestConfig) { return __awaiter(void 0, void 0, void 0, function () {
    var service, config, rst, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                service = requestConfig.service, config = __rest(requestConfig, ["service"]);
                rst = {};
                _a.label = 1;
            case 1:
                _a.trys.push([1, 6, , 7]);
                if (!(typeof service === 'function')) return [3 /*break*/, 3];
                return [4 /*yield*/, service()];
            case 2:
                rst = _a.sent();
                return [3 /*break*/, 5];
            case 3:
                if (!(config === null || config === void 0 ? void 0 : config.url)) return [3 /*break*/, 5];
                return [4 /*yield*/, unpackRequest(config)];
            case 4:
                rst = _a.sent();
                _a.label = 5;
            case 5: return [2 /*return*/, Promise.resolve(rst)];
            case 6:
                e_1 = _a.sent();
                return [2 /*return*/, Promise.reject(e_1)];
            case 7: return [2 /*return*/];
        }
    });
}); };
var getDialogProps = function (props) {
    var mergedProps = __assign(__assign({}, View.defaultProps), props);
    var dialogType = mergedProps.dialogType, dialogTitle = mergedProps.dialogTitle, dialogContent = mergedProps.dialogContent, dialogOkProps = mergedProps.dialogOkProps, dialogCancelProps = mergedProps.dialogCancelProps, dialogProps = mergedProps.dialogProps, requestConfig = mergedProps.requestConfig, isConfirmSuccess = mergedProps.isConfirmSuccess, successMsg = mergedProps.successMsg, onConfirmSuccess = mergedProps.onConfirmSuccess, onConfirmError = mergedProps.onConfirmError;
    var errorMsg = mergedProps.errorMsg;
    var _a = dialogProps || {}, dialogWidth = _a.width, dialogClassName = _a.className, dialogOnOk = _a.onOk, dialogOnCancel = _a.onCancel, otherDialogProps = __rest(_a, ["width", "className", "onOk", "onCancel"]);
    var handleDialogOk = function (e, dialog, setLoading) { return __awaiter(void 0, void 0, void 0, function () {
        var success, rst, error_1, successFn, errorFn;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (typeof dialogOnOk === 'function') {
                        dialogOnOk(e, dialog);
                        return [2 /*return*/];
                    }
                    setLoading && setLoading(true);
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, handleRequest(requestConfig)];
                case 2:
                    rst = _b.sent();
                    success = true;
                    if (typeof isConfirmSuccess === 'function') {
                        success = isConfirmSuccess(rst);
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _b.sent();
                    rst = error_1;
                    success = false;
                    errorMsg = ((_a = error_1 === null || error_1 === void 0 ? void 0 : error_1.data) === null || _a === void 0 ? void 0 : _a.errorMsg) || errorMsg;
                    return [3 /*break*/, 4];
                case 4:
                    setLoading && setLoading(false);
                    if (success) {
                        if (successMsg) {
                            successFn = typeof successMsg === 'function'
                                ? successMsg
                                : function () { return successMsg; };
                            CnMessage.success(successFn());
                        }
                        if (typeof onConfirmSuccess === 'function') {
                            onConfirmSuccess(rst);
                        }
                    }
                    else {
                        if (errorMsg) {
                            errorFn = typeof errorMsg === 'function'
                                ? errorMsg
                                : function () { return errorMsg; };
                            if (!(rst === null || rst === void 0 ? void 0 : rst.hadShowErrorFeedback)) {
                                CnMessage.error(errorFn());
                            }
                        }
                        if (typeof onConfirmError === 'function') {
                            onConfirmError(rst);
                        }
                    }
                    dialog.hide();
                    return [2 /*return*/];
            }
        });
    }); };
    var handleDialogCancel = function (e, dialog) {
        if (typeof dialogOnCancel === 'function') {
            dialogOnCancel(e, dialog);
            return;
        }
        dialog.hide();
    };
    return {
        dialogWidth: dialogWidth,
        dialogClassName: dialogClassName,
        dialogOnOk: dialogOnOk,
        dialogType: dialogType,
        dialogTitle: dialogTitle,
        dialogContent: dialogContent,
        dialogOkProps: dialogOkProps,
        dialogCancelProps: dialogCancelProps,
        handleDialogOk: handleDialogOk,
        handleDialogCancel: handleDialogCancel,
        otherDialogProps: otherDialogProps,
    };
};
var confirm = function (props) {
    var _a = getDialogProps(props), dialogWidth = _a.dialogWidth, dialogClassName = _a.dialogClassName, dialogType = _a.dialogType, dialogTitle = _a.dialogTitle, dialogContent = _a.dialogContent, dialogOkProps = _a.dialogOkProps, dialogCancelProps = _a.dialogCancelProps, handleDialogOk = _a.handleDialogOk, handleDialogCancel = _a.handleDialogCancel, otherDialogProps = _a.otherDialogProps;
    var dialog = CnDialog.show(__assign({ v2: true, className: dialogClassName, type: dialogType === 'confirm' ? 'confirm' : 'alert', width: dialogWidth || 400, size: 'small', title: dialogTitle, content: dialogContent, okProps: __assign({ warning: dialogType === 's-alert' }, dialogOkProps), cancelProps: dialogCancelProps, onOk: (function (p) {
            handleDialogOk(p, dialog);
        }), onCancel: (function (p) {
            handleDialogCancel(p, dialog);
        }), footerActions: ['cancel', 'ok'], centered: true }, otherDialogProps));
    return dialog;
};
function View(props) {
    var onClick = props.onClick, btnContent = props.btnContent, children = props.children, isBalloon = props.isBalloon, balloonProps = props.balloonProps;
    var _a = React.useState(false), balloonVisible = _a[0], setBalloonVisible = _a[1];
    var _b = React.useState(false), dialogVisible = _b[0], setDialogVisible = _b[1];
    var _c = React.useState(false), loading = _c[0], setLoading = _c[1];
    var handleClick = function (e) {
        if (typeof onClick === 'function') {
            onClick(e);
            return;
        }
        setDialogVisible(true);
    };
    var buttonProps = omit(props, [
        'btnContent',
        'isBalloon',
        'balloonProps',
        'dialogType',
        'dialogTitle',
        'dialogContent',
        'dialogOkProps',
        'dialogCancelProps',
        'dialogProps',
        'requestConfig',
        'isConfirmSuccess',
        'successMsg',
        'errorMsg',
        'onConfirmSuccess',
        'onConfirmError',
        '$i18n',
    ]);
    var _d = getDialogProps(props), dialogWidth = _d.dialogWidth, dialogClassName = _d.dialogClassName, dialogType = _d.dialogType, dialogTitle = _d.dialogTitle, dialogContent = _d.dialogContent, dialogOkProps = _d.dialogOkProps, dialogCancelProps = _d.dialogCancelProps, handleDialogOk = _d.handleDialogOk, handleDialogCancel = _d.handleDialogCancel, otherDialogProps = _d.otherDialogProps;
    if (isBalloon) {
        return (React.createElement(CnBalloon, __assign({ v2: true, className: "cn-ui-confirm-button-balloon", triggerType: "click", trigger: React.createElement(CnButton, __assign({}, buttonProps), btnContent || children), closable: true, autoFocus: false, visible: balloonVisible, onVisibleChange: setBalloonVisible }, balloonProps),
            React.createElement(CnDialog.Inner, __assign({ v2: true, className: cx(dialogClassName, 'cn-ui-confirm-button-dialog'), footerActions: ['cancel', 'ok'], onOk: (function (p) {
                    handleDialogOk(p, {
                        hide: function () {
                            setBalloonVisible(false);
                        },
                    }, setLoading);
                }), onCancel: (function (p) {
                    handleDialogCancel(p, {
                        hide: function () {
                            setBalloonVisible(false);
                        },
                    });
                }), okProps: __assign(__assign({ loading: loading }, dialogOkProps), { warning: dialogType === 's-alert' }), cancelProps: dialogCancelProps, onClose: function () { return setBalloonVisible(false); }, width: dialogWidth }, otherDialogProps),
                React.createElement(CnMessage, { size: "large", shape: "addon", type: MESSAGE_TYPE[dialogType], title: dialogTitle }, dialogContent))));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(CnButton, __assign({}, buttonProps, { "data-name": "CnConfirmButton" }, omit(props, [
            'btnContent',
            'dialogType',
            'dialogTitle',
            'dialogContent',
            'dialogOkProps',
            'dialogCancelProps',
            'dialogProps',
            'requestConfig',
            'isConfirmSuccess',
            'successMsg',
            'errorMsg',
            'onConfirmSuccess',
            'onConfirmError',
            '$i18n',
        ]), { onClick: handleClick }), btnContent || children),
        React.createElement(CnDialog, __assign({}, __assign({ visible: dialogVisible, className: dialogClassName, type: dialogType === 'confirm' ? 'confirm' : 'alert', width: dialogWidth || 400, size: 'small', okProps: __assign({ warning: dialogType === 's-alert', loading: loading }, dialogOkProps), cancelProps: dialogCancelProps, onOk: (function (p) {
                handleDialogOk(p, {
                    hide: function () {
                        setDialogVisible(false);
                    },
                }, setLoading);
            }), onCancel: (function (p) {
                handleDialogCancel(p, {
                    hide: function () {
                        setDialogVisible(false);
                    },
                });
            }), onClose: function () { return setDialogVisible(false); }, footerActions: ['cancel', 'ok'], centered: true }, otherDialogProps)),
            React.createElement(CnMessage, { size: "large", shape: "addon", type: MESSAGE_TYPE[dialogType], title: dialogTitle }, dialogContent))));
}
View.confirm = function (config) {
    if (config.isBalloon)
        return;
    confirm(config);
};
View.alert = function (config) {
    if (config.isBalloon)
        return;
    confirm(__assign(__assign({}, config), { dialogType: 'alert' }));
};
View.salert = function (config) {
    if (config.isBalloon)
        return;
    confirm(__assign(__assign({}, config), { dialogType: 's-alert' }));
};
View.defaultProps = {
    btnContent: '',
    isBalloon: false,
    balloonProps: {},
    dialogType: 'confirm',
    dialogTitle: '',
    dialogContent: '',
    dialogOkProps: {},
    dialogCancelProps: {},
    dialogProps: {},
    requestConfig: {},
    isConfirmSuccess: null,
    successMsg: function () {
        return $i18n.get({
            id: 'OperationSuccessful',
            dm: '操作成功',
            ns: 'CnConfirmButton',
        });
    },
    errorMsg: function () {
        return $i18n.get({
            id: 'OperationFailed',
            dm: '操作失败',
            ns: 'CnConfirmButton',
        });
    },
    onConfirmSuccess: function () { },
    onConfirmError: function () { },
};
export default View;
