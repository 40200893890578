import $i18n from 'panda-i18n';
import React from 'react';
import classNames from 'classnames';
import { CnIcon } from "../../cn-icon";
import { useUploadState } from '@cainiaofe/cn-ui-common';
export var UploadDropZone = function (props) {
    var _a = useUploadState(), rootProps = _a.props, limited = _a.limited;
    var disabled = rootProps.disabled;
    var description = props.description;
    var _b = React.useState(false), isDragEnter = _b[0], setIsDragEnter = _b[1];
    var title = $i18n.get({
        id: 'ClickToUploadFilesOrDragFilesHer_1915613245',
        dm: '点击上传文件或拖拽文件到这里',
        ns: 'CnOssUpload',
    });
    if (isDragEnter) {
        title = $i18n.get({
            id: 'LetGoAndStartUploading',
            dm: '松手开始上传',
            ns: 'CnOssUpload',
        });
    }
    return (React.createElement("div", { className: classNames('cn-ui-oss-upload-drop-zone', {
            disabled: disabled || limited,
            'cn-ui-oss-upload-drop-zone-active': isDragEnter,
        }), onDragEnter: function () {
            if (limited || disabled)
                return;
            setIsDragEnter(true);
        }, onDragLeave: function () { return setIsDragEnter(false); }, onDragEnd: function () { return setIsDragEnter(false); }, onDrop: function () { return setIsDragEnter(false); } },
        React.createElement(CnIcon, { className: "cn-ui-oss-upload-drop-zone-icon", size: "large", type: "upload" }),
        React.createElement("div", { className: "cn-ui-oss-upload-drop-zone-title" }, title),
        React.createElement("div", { className: "cn-ui-oss-upload-drop-zone-description" }, description)));
};
