import { __assign } from "tslib";
import { cnQuickDialogForm as mCnQuickDialogForm, } from '@cainiaofe/cn-ui-m';
import { componentMap } from '../component-map';
export function cnQuickDialogForm(cnDialogProps, renderer, 
/**
 * 点击提交后的请求配置
 */
requestConfig) {
    return mCnQuickDialogForm(cnDialogProps, renderer, requestConfig).registerComponents(__assign({}, componentMap));
}
