import { __assign, __rest } from "tslib";
import React, { useMemo } from 'react';
import { CnCardItems, } from '@cainiaofe/cn-ui-m';
export var CardItems = React.forwardRef(function (props, ref) {
    var remote = props.remote, restProps = __rest(props, ["remote"]);
    var requestConfig = useMemo(function () {
        var _a;
        if (!remote)
            return undefined;
        var paramsTransform = remote.paramsTransform, responseTransform = remote.responseTransform;
        var result = __assign({ instance: remote.instance, manual: remote.manual, url: remote.url, formatParam: paramsTransform }, remote.requestOptions);
        /**
         * 这段代码是在处理一个名为 result 的对象，该对象可能代表一个 HTTP 请求。
         * 这段代码检查 result.method 是否为 'post'，如果是，就将 remote.params 赋值给 result.data，并删除 result.params。
         * 否则，将 remote.params 赋值给 result.params。
         *
         * 这段代码的目的是根据 HTTP 请求的方法（GET 或 POST）来决定如何处理 remote.params。
         * 在 HTTP POST 请求中，数据通常放在请求体（body）中，所以这段代码将 remote.params 赋值给 result.data。
         * 而在 HTTP GET 请求中，数据通常放在 URL 的查询字符串中，所以这段代码将 remote.params 赋值给 result.params。
         */
        if (remote.method && remote.method.toLowerCase() === 'get') {
            result.method = 'GET';
            result.params = remote.params;
        }
        else {
            result.method = 'POST';
            // 对齐 PC 逻辑，使用 params，但如果没有 params, 则使用 data
            result.data = (_a = remote.params) !== null && _a !== void 0 ? _a : remote.data;
            delete result.params;
        }
        if (responseTransform) {
            // @ts-ignore .
            result.formatResult = function (res) { return responseTransform(res, res); };
        }
        return result;
    }, [remote]);
    return (React.createElement(CnCardItems, __assign({ isOnecode: true, ref: ref, requestConfig: requestConfig }, restProps)));
});
CardItems.displayName = 'CnTable';
// @ts-ignore .
CardItems.useRemote = CnCardItems.useRemote;
// @ts-ignore .
CardItems.createRemoteInstance = CnCardItems.createRemoteInstance;
