import { __assign, __makeTemplateObject, __rest } from "tslib";
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import * as React from 'react';
import styled from 'styled-components';
import { CnIcon } from "../../../cn-icon";
import classnames from 'classnames';
export var InlineFlexCell = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: inline-flex;\n  align-items: center;\n"], ["\n  display: inline-flex;\n  align-items: center;\n"])));
export var ExpansionCell = styled(InlineFlexCell)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  &.leaf {\n    cursor: default;\n  }\n\n  .expansion-icon {\n    fill: #999;\n    flex: 0 0 16px;\n    transition: transform 200ms;\n\n    &.expanded {\n      transform-origin: center center;\n      transform: rotate(90deg);\n    }\n  }\n"], ["\n  &.leaf {\n    cursor: default;\n  }\n\n  .expansion-icon {\n    fill: #999;\n    flex: 0 0 16px;\n    transition: transform 200ms;\n\n    &.expanded {\n      transform-origin: center center;\n      transform: rotate(90deg);\n    }\n  }\n"])));
// declare var svg: Element;
export function CaretDownIcon(props) {
    return (React.createElement("svg", __assign({ focusable: "false", preserveAspectRatio: "xMidYMid meet", fill: "currentColor", width: "16", height: "16", viewBox: "0 0 32 32" }, props),
        React.createElement("path", { d: "M24 12L16 22 8 12z" })));
}
export function InfoIcon(props) {
    return (React.createElement("svg", __assign({ focusable: "false", preserveAspectRatio: "xMidYMid meet", fill: "currentColor", width: "16", height: "16", viewBox: "0 0 16 16" }, props),
        React.createElement("path", { d: "M8.5 11L8.5 6.5 6.5 6.5 6.5 7.5 7.5 7.5 7.5 11 6 11 6 12 10 12 10 11zM8 3.5c-.4 0-.8.3-.8.8S7.6 5 8 5c.4 0 .8-.3.8-.8S8.4 3.5 8 3.5z" }),
        React.createElement("path", { d: "M8,15c-3.9,0-7-3.1-7-7s3.1-7,7-7s7,3.1,7,7S11.9,15,8,15z M8,2C4.7,2,2,4.7,2,8s2.7,6,6,6s6-2.7,6-6S11.3,2,8,2z" })));
}
function CaretRightIcon(props) {
    return (React.createElement("svg", __assign({ focusable: "false", preserveAspectRatio: "xMidYMid meet", fill: "currentColor", width: "16", height: "16", viewBox: "0 0 32 32" }, props),
        React.createElement("path", { d: "M12 8L22 16 12 24z" })));
}
function LoadingIcon(props) {
    return (React.createElement("svg", __assign({ width: "16", height: "16", fill: "currentColor", viewBox: "0 0 1024 1024" }, props),
        React.createElement("path", { d: "M512 74.667c-17.067 0-32 14.933-32 32V256c0 17.067 14.933 32 32 32s32-14.933 32-32V106.667c0-17.067-14.933-32-32-32zm181.333 288c8.534 0 17.067-2.134 23.467-8.534L821.333 249.6c12.8-12.8 12.8-32 0-44.8-12.8-12.8-32-12.8-44.8 0L672 309.333c-12.8 12.8-12.8 32 0 44.8 4.267 6.4 12.8 8.534 21.333 8.534zm224 117.333H768c-17.067 0-32 14.933-32 32s14.933 32 32 32h149.333c17.067 0 32-14.933 32-32s-14.933-32-32-32zM714.667 669.867c-12.8-12.8-32-12.8-44.8 0s-12.8 32 0 44.8L774.4 819.2c6.4 6.4 14.933 8.533 23.467 8.533s17.066-2.133 23.466-8.533c12.8-12.8 12.8-32 0-44.8L714.667 669.867zM512 736c-17.067 0-32 14.933-32 32v149.333c0 17.067 14.933 32 32 32s32-14.933 32-32V768c0-17.067-14.933-32-32-32zm-202.667-66.133L204.8 774.4c-12.8 12.8-12.8 32 0 44.8 6.4 6.4 14.933 8.533 23.467 8.533s17.066-2.133 23.466-8.533l104.534-104.533c12.8-12.8 12.8-32 0-44.8s-36.267-12.8-46.934 0zM288 512c0-17.067-14.933-32-32-32H106.667c-17.067 0-32 14.933-32 32s14.933 32 32 32H256c17.067 0 32-14.933 32-32zm-40.533-309.333c-12.8-12.8-32-12.8-44.8 0-12.8 12.8-12.8 32 0 44.8L307.2 352c6.4 6.4 14.933 8.533 23.467 8.533s17.066-2.133 23.466-8.533c12.8-12.8 12.8-32 0-44.8L247.467 202.667z" })));
}
export function ArrowIcon(props) {
    var color = props.color, _a = props.size, size = _a === void 0 ? 32 : _a;
    return (React.createElement("svg", { viewBox: "0 0 1024 1024", version: "1.1", xmlns: "http://www.w3.org/2000/svg", "p-id": "15035", width: size, height: size },
        React.createElement("path", { style: { fill: color }, d: "M312.888889 995.555556c-17.066667 0-28.444444-5.688889-39.822222-17.066667-22.755556-22.755556-17.066667-56.888889 5.688889-79.644445l364.088888-329.955555c11.377778-11.377778 17.066667-22.755556 17.066667-34.133333 0-11.377778-5.688889-22.755556-17.066667-34.133334L273.066667 187.733333c-22.755556-22.755556-28.444444-56.888889-5.688889-79.644444 22.755556-22.755556 56.888889-28.444444 79.644444-5.688889l364.088889 312.888889c34.133333 28.444444 56.888889 73.955556 56.888889 119.466667s-17.066667 85.333333-51.2 119.466666l-364.088889 329.955556c-11.377778 5.688889-28.444444 11.377778-39.822222 11.377778z", "p-id": "2164" })));
}
export function BArrowIcon(props) {
    var color = props.color, _a = props.size, size = _a === void 0 ? 32 : _a, style = props.style;
    return (React.createElement("svg", { viewBox: "0 0 1024 1024", version: "1.1", xmlns: "http://www.w3.org/2000/svg", "p-id": "15035", width: size, height: size, style: style },
        React.createElement("path", { style: { fill: color }, d: "M483.072 714.496l30.165333 30.208 415.957334-415.829333a42.837333 42.837333 0 0 0 0-60.288 42.538667 42.538667 0 0 0-60.330667-0.042667l-355.541333 355.413333-355.242667-355.413333a42.496 42.496 0 0 0-60.288 0 42.837333 42.837333 0 0 0-0.085333 60.330667l383.701333 383.872 1.706667 1.749333z", fill: "#3D3D3D", "p-id": "2172" })));
}
export function FilterIcon(props) {
    var _a = props.size, size = _a === void 0 ? 14 : _a, style = props.style, className = props.className, rest = __rest(props, ["size", "style", "className"]);
    return (React.createElement(CnIcon, __assign({ type: "filter-fill", width: size, height: size, style: style, size: 'small', className: classnames('cnc-table-icon-filter', className) }, rest)));
}
export var icons = {
    Loading: LoadingIcon,
    CaretDown: CaretDownIcon,
    CaretRight: CaretRightIcon,
    Info: InfoIcon,
    ArrowIcon: ArrowIcon,
    BArrowIcon: BArrowIcon,
    FilterIcon: FilterIcon,
};
var templateObject_1, templateObject_2;
