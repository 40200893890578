import { __assign } from "tslib";
import * as React from 'react';
import { Radio } from "../../../../_fusion/lib";
import { TagGroup } from './tag-group';
import { withNativeProps } from '@cainiaofe/cn-ui-common';
export var RadioGroupBase = React.forwardRef(function (props, ref) {
    if (props.shape === 'tag') {
        return React.createElement(TagGroup, __assign({}, props));
    }
    return withNativeProps(props, React.createElement(Radio.Group, __assign({}, props, { ref: ref })));
});
RadioGroupBase.displayName = 'RadioGroupBase';
