import { __assign } from "tslib";
import React from 'react';
import cx from 'classnames';
import { Overlay } from "../../../../../_fusion/lib";
import { UploadListItem } from '../upload-list-item';
import { StaticFileIcon } from '../upload-list-item/icons';
import { CnTag } from "../../../cn-tag";
import './index.scss';
export function MiniUploadList(props) {
    var popupProps = props.popupProps, _a = props.value, value = _a === void 0 ? [] : _a, readOnly = props.readOnly, _b = props.showRemove, showRemove = _b === void 0 ? true : _b, onRemove = props.onRemove, showDownload = props.showDownload, onDownload = props.onDownload, showPreview = props.showPreview, onPreview = props.onPreview, webOfficeEnable = props.webOfficeEnable, webOfficeEditRequestConfig = props.webOfficeEditRequestConfig, webOfficePreviewRequestConfig = props.webOfficePreviewRequestConfig;
    if (readOnly && !value.length)
        return null;
    return (React.createElement(Overlay.Popup, __assign({ trigger: React.createElement("div", { className: "cn-ui-upload-list-table-trigger" },
            value.slice(0, 3).map(function (item) { return (React.createElement(StaticFileIcon, { key: item.uid || item.name || item.url, file: item, className: "table-inline-icon" })); }),
            value.length > 3 ? (React.createElement(CnTag, { key: "item-tag", size: "small", type: "primary", className: "table-inline-more" },
                value.length - 3,
                " +")) : null), className: "cn-ui-upload-list-table-popup" }, popupProps),
        React.createElement("div", { className: cx('cn-ui-upload-list', 'cn-ui-upload-list-mini') }, value.map(function (item) { return (React.createElement(UploadListItem, { key: item.uid || item.name || item.url, file: item, showDownload: showDownload, onDownload: onDownload, showPreview: showPreview, onPreview: onPreview, showRemove: !readOnly && showRemove, onRemove: onRemove, readOnly: readOnly, webOfficeEnable: webOfficeEnable, webOfficeEditRequestConfig: webOfficeEditRequestConfig, webOfficePreviewRequestConfig: webOfficePreviewRequestConfig })); }))));
}
