import { __assign, __extends, __rest } from "tslib";
import * as React from 'react';
import NextDatePicker from "../../../_fusion/lib/date-picker2/picker";
import { func } from "../../../_fusion/lib/util";
import { fmtValue, isValueChanged } from "../../../_fusion/lib/date-picker2/util";
import ConfigProvider from "../../../_fusion/lib/config-provider";
import { DATE_PICKER_MODE } from "../../../_fusion/lib/date-picker2/constant";
import { getDateFormatConf, getDayjsLang } from '../cn-utils/date';
var NewPicker2 = /** @class */ (function (_super) {
    __extends(NewPicker2, _super);
    function NewPicker2() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onOk = function () {
            // cn-ui 2023.02.28 日期组件format为DD/MM/YYYY HH:mm:ss，组件不可用问题修复
            // 例如选中 08/02/2023 00:00:00，checkValue会变成 02/08/2023 00:00:00
            // const { inputValue } = this.state;
            // const checkedValue = this.checkValue(inputValue);
            var curValue = _this.state.curValue;
            var checkedValue = _this.checkValue(curValue);
            func.invoke(_this.props, 'onOk', _this.getOutputArgs(checkedValue));
            _this.setState({ value: checkedValue });
            _this.handleChange(checkedValue, 'CLICK_OK');
        };
        _this.handleChange = function (v, eventType) {
            var format = _this.props.format;
            var _a = _this.state, isRange = _a.isRange, showOk = _a.showOk, value = _a.value, preValue = _a.preValue;
            var forceEvents = [
                'KEYDOWN_ENTER',
                'CLICK_PRESET',
                'CLICK_OK',
                'INPUT_CLEAR',
                'VISIBLE_CHANGE',
            ];
            var isTemporary = showOk && !forceEvents.includes(eventType);
            // 面板收起时候，将值设置为确认值
            v =
                eventType === 'VISIBLE_CHANGE' ? value : _this.checkValue(v, !isTemporary);
            _this.setState({
                curValue: v,
                inputValue: fmtValue(v, format),
            });
            if (!isTemporary) {
                _this.setState({
                    value: v,
                }, function () {
                    var _a, _b, _c, _d;
                    // 判断当前选择结束，收起面板：
                    // 1. 非 Range 选择
                    // 2. 非 选择预设时间、面板收起、清空输入 操作
                    // 3. 不需要切换输入框
                    var shouldHidePanel = !isRange ||
                        ['CLICK_PRESET', 'VISIBLE_CHANGE', 'INPUT_CLEAR'].includes(eventType) ||
                        !_this.shouldSwitchInput(v);
                    var isSecondValueAlter = Array.isArray(v) &&
                        v[0] &&
                        v[1] &&
                        ((_a = v[0]) === null || _a === void 0 ? void 0 : _a.toString()) === ((_b = preValue[0]) === null || _b === void 0 ? void 0 : _b.toString()) &&
                        ((_c = v[1]) === null || _c === void 0 ? void 0 : _c.toString()) !== ((_d = preValue[1]) === null || _d === void 0 ? void 0 : _d.toString());
                    if (shouldHidePanel || isSecondValueAlter) {
                        _this.onVisibleChange(false);
                        if (isValueChanged(v, preValue)) {
                            _this.onChange();
                        }
                    }
                });
            }
        };
        return _this;
    }
    return NewPicker2;
}(NextDatePicker));
export { NewPicker2 };
var DATE = DATE_PICKER_MODE.DATE, WEEK = DATE_PICKER_MODE.WEEK, MONTH = DATE_PICKER_MODE.MONTH, QUARTER = DATE_PICKER_MODE.QUARTER, YEAR = DATE_PICKER_MODE.YEAR;
/* istanbul ignore next */
var transform = function (props, deprecated) {
    var _a;
    var footerRender = props.footerRender, onVisibleMonthChange = props.onVisibleMonthChange, defaultVisibleMonth = props.defaultVisibleMonth, ranges = props.ranges, adapterLocale = props.adapterLocale, newProps = __rest(props, ["footerRender", "onVisibleMonthChange", "defaultVisibleMonth", "ranges", "adapterLocale"]);
    var mode = props.mode || DATE;
    var formatConf = getDateFormatConf(adapterLocale);
    var MODE2FORMAT = (_a = {},
        _a[DATE] = formatConf.D,
        _a[WEEK] = formatConf.W,
        _a[MONTH] = formatConf.M,
        _a[QUARTER] = formatConf.Q,
        _a[YEAR] = formatConf.Y,
        _a);
    if ('footerRender' in props) {
        deprecated('footerRender', 'extraFooterRender', 'DatePicker');
        newProps.extraFooterRender = footerRender;
    }
    if (onVisibleMonthChange) {
        deprecated('onVisibleMonthChange', 'onPanelChange', 'DatePicker');
        newProps.onPanelChange = onVisibleMonthChange;
    }
    if (defaultVisibleMonth) {
        deprecated('defaultVisibleMonth', 'defaultPanelValue', 'DatePicker');
        newProps.defaultPanelValue = onVisibleMonthChange;
    }
    if ('ranges' in props) {
        deprecated('ranges', 'preset: PT.oneOfType([PT.array, PT.object])', 'DatePicker');
        newProps.preset = ranges;
    }
    if ([WEEK, MONTH, QUARTER, YEAR].includes(mode)) {
        delete newProps.showTime;
    }
    else if (typeof props.showTime === 'object') {
        deprecated('showTime: object', 'showTime && timePanelProps', 'DatePicker');
        newProps.timePanelProps = props.showTime;
        newProps.showTime = true;
    }
    if (!newProps.format) {
        newProps.format =
            MODE2FORMAT[mode] + (newProps.showTime ? ' HH:mm:ss' : '');
    }
    newProps.dayjsLang = getDayjsLang();
    return newProps;
};
var ConfigPicker = ConfigProvider.config(NewPicker2, {
    componentName: 'DatePicker2',
    transform: transform,
});
var generatePicker = function (mode) {
    return React.forwardRef(function (props, ref) { return (React.createElement(ConfigPicker, __assign({ ref: ref }, props, { mode: mode }))); });
};
var DatePicker2 = generatePicker();
DatePicker2.displayName = 'DatePicker2';
DatePicker2.MonthPicker = generatePicker(MONTH);
DatePicker2.MonthPicker.displayName = 'MonthPicker2';
DatePicker2.YearPicker = generatePicker(YEAR);
DatePicker2.YearPicker.displayName = 'YearPicker2';
DatePicker2.WeekPicker = generatePicker(WEEK);
DatePicker2.WeekPicker.displayName = 'WeekPicker2';
DatePicker2.QuarterPicker = generatePicker(QUARTER);
DatePicker2.QuarterPicker.displayName = 'QuarterPicker2';
DatePicker2.RangePicker = React.forwardRef(function (props, ref) { return (React.createElement(ConfigPicker, __assign({ ref: ref }, props, { type: "range" }))); });
DatePicker2.RangePicker.displayName = 'RangePicker2';
export default DatePicker2;
