import { __assign } from "tslib";
import './view/cn-search.scss';
import React from 'react';
import NextSearch from "../../../_fusion/lib/search";
import { useNextLocale } from '../cn-utils';
import { withNativeProps } from '@cainiaofe/cn-ui-common';
export var CnSearch = React.forwardRef(function (props, ref) {
    var searchLocale = useNextLocale('Search');
    return withNativeProps(props, React.createElement(NextSearch, __assign({ "data-name": "CnSearch", ref: ref }, props, { locale: searchLocale })));
});
CnSearch.displayName = 'CnSearch';
/**
 * @deprecated  请使用 CnSearch 替换
 */
export var Search = NextSearch;
